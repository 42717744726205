import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment, { now } from 'moment';
import * as actionCreators from '../../store/actions';

import Responsive from '../utils/Responsive';
import GeneralUtils from '../utils/GeneralUtils';
import Constants from '../Constants';
import EventList from './EventList';
import EventFilters from './EventFilters';
import GetSelectionVenueMarkets from '../utils/GetSelectionVenueMarkets';

const MOBILE_LIST_MAX_LENGTH = 10;
const INPLAY_UPDATE_INTERVAL_TIME = 15 * 1000;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.selectRace = this.selectRace.bind(this);
  }

  componentDidMount() {
    this.refreshSidebar();

    this.inPlayUpdateTimer = setInterval(() => this.updateInPlayValues(), INPLAY_UPDATE_INTERVAL_TIME);
  }

  componentDidUpdate(prevProps) {

    // there might be a better way of comparing the two objects
    if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
      this.changeTimeout();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * @description check if session is timed out. return true if is greater than 8 hours
   * @param {string} date
   * @returns {bool}
   */
  checkSessionTime = (date) => {
    const { lastActionTime } = this.props;
    const timeBetweenClick = date - lastActionTime;
    const momentTime = moment.duration(timeBetweenClick);
    const isTimedOut = momentTime.milliseconds() > process.env.REACT_APP_SESSION_TIMEOUT;

    return isTimedOut;
  };

  /**
   * @description find the race from the api by dispatching an action. the selectedRace will also be added to the local state
   * @param {object} race
   */
  selectRace = (race) => {
    const { market: marketProps, setCurrentSelection } = this.props;

    // add to google dataLayer
    const gaObj = { label: 'Event Selected', value: race.marketId }
    GeneralUtils.addToDataLayer(gaObj);

    const selection = marketProps.find(
      market =>
        market.marketId === race.marketId && market.eventId === race.eventId
    );
    // console.log(marketProps);
    // console.log(race);
    // console.log(selection);
    const autoSelectNewRaceFlag = true;

    const x = GetSelectionVenueMarkets(selection, marketProps);

    setCurrentSelection(selection, GetSelectionVenueMarkets(selection, marketProps), autoSelectNewRaceFlag);
  };

  /**
   * @description toggle the timeout depending on status displayed. if open, start the timeout
   */
  changeTimeout = () => {
    const firstRace = this.getFilteredEventList().shift();

    if (firstRace) {
      this.selectRace(firstRace);
    }

    if (this.props.filters.selectedStatus.id === 'CLOSED') {
      clearTimeout(this.interval);
    } else {
      clearTimeout(this.interval);
      this.refreshSidebar();
    }
  };

  /**
   * @description refresh the currently open markets
   * @async
   */
  refreshSidebar() {

    this.interval = setInterval(() => {
      const { currentSelection, fetchIntervalOpenMarkets, tryLogout } = this.props;

      // ensure there is a current selection before fetching the interval open markets
      if (Object.keys(currentSelection).length > 0) {
        fetchIntervalOpenMarkets();
      }
      const timeNow = now();

      if (this.checkSessionTime(timeNow)) {
        tryLogout();
        window.location.reload();
        clearInterval(this.interval);
      }
    }, process.env.REACT_APP_SIDE_BAR_REFRESH_RATE);
  };

  /**
   * @description find if the market status is set to open or other.
   * @param {object} market
   * @returns {object} market
   */
  filterOpenOrClosed = market => {
    const { filters } = this.props;
    const status = filters.selectedStatus.id;

    if (status === 'OPEN') {
      return market.status === 'OPEN' || market.status === 'SUSPENDED' || market.status === 'IN-PLAY';
    }

    return market.status === 'CLOSED';
  };

  /**
   * @description filter by region. if there are facets only return based on state/region
   * @param {object} market
   * @returns {object} market
   */
  filterRegion = market => {
    const { filters } = this.props;
    const { ausStates, selectedCountry, region } = filters;
    const country = selectedCountry.id

    // check for the country to be AU, if it's AU, we can selecte multiple states
    if ((country === 'AU' || country === 'NZ') && Array.isArray(ausStates)) {
      return this.filterAustralia(market);
    }
    if (region && region.length > 0) {
      return region.find(x => x.id === market.countryCode) !== undefined ? market : null;
    }
    return market;
  };

  /**
   * @description filter the markets to ensure that the list only shows the markets for that
   * specific day selected and does not overlap with other days
   */
  filterDay = market => {
    const { filters } = this.props;
    const dateFormat = 'YYYY-MM-DD';
    const today = moment();
    const tomorrow = moment().add(1, 'day');
    const plus2Days = moment().add(2, 'day');
    const marketLocalTime = moment(moment.utc(market.marketTime))
      .local()
      .format(dateFormat);

    switch (filters.selectedDay.id) {
      case 'today':
        return moment(marketLocalTime).isSameOrBefore(today, 'day')
          ? market
          : null;
      case 'tomorrow':
        return moment(marketLocalTime).isSameOrAfter(tomorrow, 'day')
          ? market
          : null;
      case '+2days':
        return moment(marketLocalTime).isSameOrAfter(plus2Days, 'day')
          ? market
          : null;
      default:
        return null;
    }
  };

  /**
   * @description check the current ausStates array and filter based on the market
   * @param {object} market
   * @return {market | null}
   */
  filterAustralia = market => {
    const { filters } = this.props;
    const { ausStates } = filters;

    if (ausStates.length > 0) {
      const marketFiltered = ausStates.filter(state => state.id === market.region);
      return marketFiltered.length > 0 ? market : null;
    }

    return market;
  };

  updateInPlayValues = () => {
    const { marketId } = this.props.currentSelection;

    const timeThreshold = moment.utc().add(10, 'seconds');

    //find all races that are open and are about to jump
    const list = this.getFilteredEventList()
      .filter(m => m.status !== 'CLOSED' || m.status !== 'SUSPENDED')
      .filter(m => m.marketId !== marketId)
      .filter(m => moment.utc(m.marketTime) < timeThreshold);

    if (list.length !== 0)
      this.props.fetchUpdatedInplayStatuses(list.map(i => i.marketId));
  }

  getFilteredEventList() {
    const { market: propsMarket, filters } = this.props;
    const isMobile = window.innerWidth <= Constants.responsiveMobileWidthBreakpoint;

    let sortedMarkets = propsMarket;

    if (filters.selectedStatus.id === "CLOSED") {
      sortedMarkets = propsMarket.slice();
      sortedMarkets.sort(GeneralUtils.closedRaceCompare)
    }

    let filteredMarkets = sortedMarkets
      .filter(this.filterOpenOrClosed)
      .filter(this.filterRegion)
      .filter(this.filterDay);

    if (isMobile) {
      filteredMarkets = filteredMarkets.slice(0, MOBILE_LIST_MAX_LENGTH);
    }

    return filteredMarkets;
  }

  render() {
    const { market } = this.props;

    return (
      <div id="sidebar-container" className="sidebar-container">

        <Responsive desktop>
          <EventFilters changeTimeout={this.changeTimeout}>
            <EventList
              market={market}
              selectRaceCallBack={this.selectRace}
              getFilteredEventList={() => this.getFilteredEventList()}
            />
          </EventFilters>
        </Responsive>

        <Responsive mobile>
          <EventFilters mobile changeTimeout={this.changeTimeout}>
            <EventList
              market={market}
              selectRaceCallBack={this.selectRace}
              getFilteredEventList={() => this.getFilteredEventList()}
            />
          </EventFilters>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  filter: state.eventFilters,
  market: state.market.markets,
  filters: state.eventFilters,
  currentSelection: state.market.currentSelection,
  lastActionTime: state.auth.lastActionTime
})

const mapDispatchToProps = dispatch => ({
  setCurrentSelection: (market, markets, autoSelectNewRaceFlag) => dispatch(actionCreators.setCurrentSelection(market, markets, autoSelectNewRaceFlag)),
  fetchIntervalOpenMarkets: () => dispatch(actionCreators.fetchIntervalOpenMarkets()),
  fetchUpdatedInplayStatuses: marketIds => dispatch(actionCreators.fetchUpdatedInPlayStatuses(marketIds)),
  tryLogout: () => dispatch(actionCreators.tryLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);

Sidebar.propTypes = {
  market: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchIntervalOpenMarkets: PropTypes.func.isRequired,
  tryLogout: PropTypes.func.isRequired,
  setCurrentSelection: PropTypes.func.isRequired,
  currentSelection: PropTypes.shape({/* market */ }).isRequired,
  filter: PropTypes.shape({/* filter */ }).isRequired,
  lastActionTime: PropTypes.number.isRequired,
};
