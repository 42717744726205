import React from "react";

import { connect } from "react-redux";
import PropTypes from 'prop-types';

import Cookies from "js-cookie";
import Spinner from 'reactjs-simple-spinner';

import * as actionCreators from "../store/actions";
import Constants from "./Constants";
import { isDev, getLogin, isAccountAuNz } from "./utils/Auth";

import AuthDialog from "./utils/AuthDialog";
import FakeLogin from "./FakeLogin";
import LoginForm from "./LoginForm";
import Footer from "./footer";
import UnsupportedBrowserInfo from "./UnsupportedBrowserInfo";
import logo from "../assets/images/background.png";
import GeneralUtils from './utils/GeneralUtils';
import Analytics from "./utils/Analytics";

import { getUserRequest } from '../request';


const {
  NODE_ENV,
  REACT_APP_DEV_USERNAME,
  REACT_APP_DEV_PASSWORD,
  REACT_APP_LOGIN_URL,
  REACT_APP_KYC_STRING,
} = process.env;

class Auth extends React.Component {
  // default apiData and login
  state = {
    ssoid: Cookies.get("ssoid"),
    loginHeader: Constants.defaultLoginHeader
  };

  componentDidMount() {
    const { ssoid, loginHeader } = this.state;
    const { fetchTodaysMarkets } = this.props;
    const isDevEnvironment = isDev(NODE_ENV);

    if (isDevEnvironment) {
      const loginBody = {
        username: REACT_APP_DEV_USERNAME,
        password: REACT_APP_DEV_PASSWORD
      };

      getLogin(REACT_APP_LOGIN_URL, loginBody, loginHeader).then(
        () => {
          // Ensure the state has the correct ssoid value
          this.setState({
            ssoid: Cookies.get("ssoid")
          });

          // Things that rely
          this.fetchDetails();
          fetchTodaysMarkets();
        }
      );
    } else if (ssoid) {
      this.fetchDetails();
      fetchTodaysMarkets();
    }
  }

  /**
   * @description fetch the user details and then store the details in the store
   * - will also check if the customer is Au/Nz
   * - will check if there is an error sent back while fetching user details.
   * - if there is an error. show the login form
   * @async
   * @returns {object} result
   */
  async fetchDetails() {
    const { ssoid } = this.state;
    const { setAccountDetails } = this.props;
    const request = getUserRequest(ssoid);

    const kycVerifyStr = REACT_APP_KYC_STRING || "KYC";

    // fetch the user details and use await so that the country code can be retrieved from the store
    const result = await request
      .then(response => {
        if(response.data.error) {
          // Ensure the state has the correct ssoid value
          this.setState({
            ssoid: undefined
          });
        }
        Analytics.trackUser(response.data);
        setAccountDetails(response.data);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log("some error occured", error));
    // wait till the promise resolves

    // check for a login error. if there is a login error. show the login form
    // As the auth prop is set asynchronously in the above promise it
    // cannot be retrieved until after it has complete.
    const { auth } = this.props;
    if (!auth.error) {
      const { countryCode, kycStatus } = auth;
      this.setState({
        isAusNz: isAccountAuNz(countryCode),
        isCustomerVerified: kycStatus === kycVerifyStr
      });
    }
    return result;
  }

  render() {
    const { ssoid, isAusNz, isCustomerVerified } = this.state;
    const { children } = this.props;
    const divStyle = {
      backgroundImage: `url(${logo})`
    };

    if (GeneralUtils.isInternetExplorer()) {
      return (
        <div className="app-container-auth">
          <AuthDialog backgroundImage={logo} size="auth-dialog-small">
            <UnsupportedBrowserInfo />
          </AuthDialog>
        </div>
      );
    }

    if(ssoid && isAusNz === true && isCustomerVerified === true) {
      const appContainerStyle = {
        position: 'static',
        height: 'auto'
      };
      return (
        <div className="app-container-auth" style={appContainerStyle}>
          {children}
        </div>
      );
    }

    if(ssoid && (isAusNz === false || isCustomerVerified === false)) {
      return (
        <div className="app-container-auth no-authenticated" style={divStyle}>
          <AuthDialog backgroundImage={logo} size="auth-dialog-small">
            <FakeLogin />
          </AuthDialog>
        </div>
      );
    }

    if(ssoid) {
      return (
        <div className="app-container-auth" style={divStyle}>
          <AuthDialog backgroundImage={logo} size="auth-dialog-loading" additionalClass="auth-dialog-additional-loading">
            <Spinner
              lineFgColor="#1e1e1e"
              lineBgColor="#ffb80f"
              size={30}
              lineSize={5}
            />
          </AuthDialog>
        </div>
      )
    }

    return (
      <div className="app-container-auth no-authenticated" style={divStyle}>
        <AuthDialog backgroundImage={logo} size="auth-dialog-small">
          <LoginForm />
        </AuthDialog>
        <Footer />
      </div>
    );
  }
}

Auth.propTypes = {
  fetchTodaysMarkets: PropTypes.func
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  setAccountDetails: auth => dispatch(actionCreators.setAccountDetails(auth)),
  fetchTodaysMarkets: () =>
    dispatch(actionCreators.fetchTodaysOpenMarketsStart())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
