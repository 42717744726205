import React from 'react';
import PhoneRotate from '../../assets/images/PhoneRotate';

const RotateMobileScreen = props => {
  return (
    <div className="modal-rotate-phone">
      <h3>Rotate Device</h3>
      <img
        id="phone-image"
        alt="phone"
        src={PhoneRotate} />
      <p>Expand the market view by <br />rotating your device</p>
    </div>
  )
}

export default RotateMobileScreen;
