import React from 'react';

export const PositionalModal = props => {
  const { open,
    children,
    target,
    cancel,
  } = props;

  const current = target.current;
  const x = current && current.offsetLeft;
  const y = current && current.offsetTop;

  const width = current && current.offsetWidth;
  const height = current && current.offsetHeight;

  const triangleHeight = 8;

  const style = {
    top: y + height + triangleHeight,
    left: x - (width / 2),
  }

  const notchStyle = {
    top: -1 * triangleHeight,
    left: width - triangleHeight,
  }

  /**
   * Ensure the body does not fire the backgrounds cancel function
   * @param {*} e
   */
  const handleBodyClick = e => {
    e.stopPropagation();
  }

  return (
    <div
      className={`positional-modal-background ${!open ? 'modal-closed' : ''}`}
      onClick={cancel}
    >
      <div className="positional-modal" style={style} onClick={handleBodyClick}>
        <div className="modal-triangle-notch" style={notchStyle} />
        <div className={`modal`}>
          <div className="modal-body">
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PositionalModal;
