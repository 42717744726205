import React from 'react';
import { includes } from 'lodash';

const MARKET_EVENT_TYPE_ID_HORSE = Number(process.env.REACT_APP_EVENT_TYPE_ID_HORSE);
const MARKET_EVENT_TYPE_ID_GREYHOUND = Number(process.env.REACT_APP_EVENT_TYPE_ID_GREYHOUND);
const GREYHOUND_COUNTRY_EXCEPTION_ARRAY = process.env.REACT_APP_GREYHOUND_COUNTRY_EXCEPTION_ARRAY;

const FALLBACK_IMAGE_PATH = './assets/images/silks/fallback.png';

class InfoCellSilkImage extends React.Component {

  constructor(props) {
    super(props);

    const { eventType, marketCountry, silkImg, index } = props;

    this.state = {
      silkUrl: this.getSilkImageUrl(eventType, marketCountry, silkImg, index),
      silkNotFound: false
    }
  }

  /*
   * this component only runs logic on mount. There is no need to render unless there
   * has been an error (note: the table is reconstructed when a race changes)
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.silkNotFound !== nextState.silkNotFound)
      return true;

    return false;
  }

  getSilkImageUrl = (eventType, marketCountry, silkImg, index) => {
    if (
      (eventType === MARKET_EVENT_TYPE_ID_HORSE && marketCountry === 'US') ||
      marketCountry === 'IN'
    ) {
      return FALLBACK_IMAGE_PATH;
    }
    if (eventType === MARKET_EVENT_TYPE_ID_HORSE && silkImg) {
      return `${process.env.REACT_APP_MARKET_RUNNER_SILK_URL}/${silkImg}`;
    }

    if (
      eventType === MARKET_EVENT_TYPE_ID_GREYHOUND &&
      includes(GREYHOUND_COUNTRY_EXCEPTION_ARRAY, marketCountry)
    ) {
      return `./assets/images/silks/silks_au/${index}.png`;
    }
    if (
      eventType === MARKET_EVENT_TYPE_ID_GREYHOUND &&
      !includes(GREYHOUND_COUNTRY_EXCEPTION_ARRAY, marketCountry)
    ) {
      return `./assets/images/silks/silks_other/${index}.png`;
    }

    return FALLBACK_IMAGE_PATH;
  }

  /*
   * sets the img to use the default fallback instead of the silk image
   * the img tag calls this when the src url returns a 404
   */
  onError = () => {
    // to stop potential spamming of setState on errors
    if (this.state.silkNotFound) return;

    this.setState({ silkNotFound: true });
  }

  render() {
    const { silkNotFound, silkUrl } = this.state;

    const url = silkNotFound ? FALLBACK_IMAGE_PATH : silkUrl;

    return (
      <img
        src={url}
        onError={this.onError}
        alt="runner"
        className="runner-icon-image"
      />
    )
  }
}

export default InfoCellSilkImage;
