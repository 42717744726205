import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import * as actionCreators from '../../../store/actions';

import RunnerSelector from './RunnerSelector';
import Countdown from '../../utils/Countdown';

class EventContainer extends React.PureComponent {

  formatTotalMatched  = totalMatched => {
    const rounded = Math.round(totalMatched || 0);
    return numeral(rounded).format('$0,0');
  }

  render() {
    let { currentSelection, setCurrentSelection, currentVenueRaces: currentVenueRaces = [] } = this.props;
    const {
      raceNumber,
      venue,
      countryCode,
      marketTime,
      marketName,
      status,
      marketId,
      inPlay,
    } = currentSelection;
    let { totalMatched } = currentSelection;
    totalMatched = this.formatTotalMatched(totalMatched);
    let enableDeepLink = '';

    if (status === 'CLOSED') {
      enableDeepLink = 'rt-exchangeDeepLink-disabled';
      totalMatched = '-';
    }

    return (
      <div className={`event-info-container`} >
        {raceNumber && (
          <div className="event-info-content">
            <div className="row">

              <RunnerSelector
                currentVenueRaces={currentVenueRaces}
                raceNumber={raceNumber}
                marketId={marketId}
                setCurrentSelection={setCurrentSelection}
              />

              <div className={`event-title heading ${enableDeepLink}`}>
                <a
                  onClick={this.props.addToDataLayerCallBack}
                  href={`https://www.betfair.com.au/dl/${marketId}`}
                  target="_blank"
                  className="rt-exchangeDeepLink"
                >
                  {venue} ({countryCode})
                </a>
              </div>
              <div className="right time-to-jump">
                <div className="value">
                  <Countdown
                    eventTime={marketTime}
                    eventStatus={status}
                    eventInPlay={inPlay}
                  />
                </div>
              </div>
            </div>
            <div className={`row ${enableDeepLink}`}>
              <div className="event-stats">
                <a
                  onClick={this.props.addToDataLayerCallBack}
                  href={`https://www.betfair.com.au/dl/${marketId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rt-exchangeDeepLink"
                >
                  {marketName.replace(`R${raceNumber}`,'')}
                </a>
              </div>
              <div className="matched right">
                Matched:
                <span>{totalMatched}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSelection: state.market.currentSelection,
  markets: state.market.markets,
  currentSelectionInPlay: state.market.currentSelectionInPlay,
  currentVenueRaces: state.market.currentVenueRaces
});

const mapDispatchToProps = dispatch => ({
  setCurrentSelection: (market, markets, autoSelectNewRaceFlag) => dispatch(actionCreators.setCurrentSelection(market, markets, autoSelectNewRaceFlag))
});

export default connect(mapStateToProps,mapDispatchToProps)(EventContainer);
