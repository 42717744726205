import { updateObject, filterOpenMarkets, filterClosedMarkets } from '../utility';
import * as actionTypes from '../actions/actionTypes';
import GetSelectionVenueMarkets from '../../components/utils/GetSelectionVenueMarkets';

const initialState = {
  currentSelection: {
    id: null,
    marketId: 'default',
    marketName: null,
    totalMatched: null,
    openDate: null,
    marketStatus: null,
  },
  markets: [
    {
      eventTypeId: null,
      eventId: null,
      marketId: 'default',
      countryCode: null,
      timezone: null,
      marketTime: null,
      status: null,
      venue: null,
      marketName: null,
      raceNumber: null,
      region: null,
    },
  ],
  eroMarketData: {
    currencyCode: "AUD",
    eventTypes: [],
    indicative: false
  },
  isFetchingNewMarkets: false,

  /*
   * This tells redux to automatically select the most recent market when fetching a new list of markets
   * e.g. when the current race switches to closed, select the next open market
  */
  autoSelectNewRaceFlag: true,
};

/**
 * @description add the markets/races to the store. also add the current selection which has to be a market with status 'OPEN'
 * @param {object} state
 * @param {object} action
 */
const setOpenMarkets = (state, action) => {
  if(action.market){

    let updatedMarkets = action.market.map(newMarket => {
      let original = state.markets.find(existingMarket => {
        return newMarket.marketId === existingMarket.marketId;
      });
      if(original) {
        let originalValues = {
          inplay: original.inplay,
          status: original.status,
        }
        return {...newMarket, ...originalValues};
      }
      return {...newMarket};
    });

    let market;
    let currentSelectionIndex = 0;

    if(action.filter.selectedStatus.id === 'OPEN'){
      market = Array.isArray(updatedMarkets)
    ? updatedMarkets.filter(filterOpenMarkets)
      : updatedMarkets;
    } else {
      market = Array.isArray(updatedMarkets)
    ? updatedMarkets.filter(filterClosedMarkets)
      : updatedMarkets;

      currentSelectionIndex = market.length - 1; //the closed markets are in reverse order
    }

    return ({
      ...state,
      markets: updatedMarkets,
      currentSelection: {
        ...market[currentSelectionIndex],
      },
      currentVenueRaces: GetSelectionVenueMarkets(market[currentSelectionIndex], updatedMarkets),
      autoSelectNewRaceFlag: true, // reset this flag
    });
  }
};

/**
 * @description add the markets/races to the store. also add the current selection which has to be a market with status 'OPEN'
 * @param {object} state
 * @param {object} action
 */
const setIntervalOpenMarkets = (state, action) => {
  let updatedMarket = action.fetchedMarkets.map(newMarket => {
    let original = state.markets.find(existingMarket => {
      return newMarket.marketId === existingMarket.marketId;
    });

    // if the new market from the eventslist call is CLOSED, just use that
    if(original && newMarket.status !== "CLOSED") {
      let originalValues = {
        inplay: original.inplay,
        status: original.status,
      }
      return {...newMarket, ...originalValues};
    }

    return {...newMarket};
  });


  // if the auto switch flag isn't set, just update the market list
  if (!state.autoSelectNewRaceFlag) {
    return {
      ...state,
      markets: updatedMarket,
    };
  }

  // else, continue with the selection updating logic

  const openMarkets = updatedMarket.filter(filterOpenMarkets);
  let currentSelection = state.currentSelection;
  const selectedMarketIsOpen = openMarkets.find(i => i.marketId === currentSelection.marketId);

  if (!selectedMarketIsOpen) {
    currentSelection = { ...openMarkets[0] }
  }

  return {
    ...state,
    markets: updatedMarket,
    currentSelection,
  };
};

/**
 * @description set the status of currently selected market
 * @param {object} state
 * @param {object} action
 */
const setCurrentSelectionMarketInfo = (state, action) => {
  const { marketId, eventInPlay, marketStatus, totalMatched } = action.headerInfo;
  const isCurrentSelection = state.currentSelection.marketId === action.headerInfo.marketId;


  //update the market in the array with the new values
  let markets = state.markets.slice();
  const indexOfMarket = markets.findIndex(i => i.marketId === marketId);

  markets[indexOfMarket] = {
    ...markets[indexOfMarket],
    inplay: eventInPlay,
    inPlay: eventInPlay, //there are two in play variables.......
    status: marketStatus,
    totalMatched: totalMatched,
  };

  let currentSelection = state.currentSelection;
  
  //update current selection if needed
  if (isCurrentSelection) {
    currentSelection = {...markets[indexOfMarket]};
  }

  return ({
    ...state,
    currentSelection,
    markets,
  })
};

/**
 * @description reducer to set the currrent selction market in the store
 * @param {object} state
 * @param {object} action
 */
const setCurrentSelection = (state, action) =>
  // console.log('currentSelection: ', action.currentSelection);
  updateObject(state, {
    ...state,
    currentSelection: action.currentSelection,
  });

const setCurrentVenueRaces = (state, action) => updateObject(state, { currentVenueRaces: action.currentVenueRaces});

const setUpdatedInPlayValues = (state, action) => {
  let marketList = state.markets.slice();

  action.markets.forEach(m => {
    let marketToUpdate = marketList.find(i => i.marketId === m.marketId);

    marketToUpdate.status = m.state.status;
    marketToUpdate.inplay = m.state.inplay;

    // IN-PLAY isn't a status we receive from the backend, but there's existing frontend
    // logic which uses it, so we set it here too
    if (m.state.inplay && m.state.status === "OPEN") {
      marketToUpdate.status = "IN-PLAY";
    }
  });

  return {
    ...state,
    markets: marketList
  };
}

/**
 * @description set flag indicating if we are fetching a new list of markets
 * NOT from the interval
 */
const setIsFetchingNewMarkets = (state, action) => {
  return ({
    ...state,
    isFetchingNewMarkets: action.isFetchingNewMarkets,
  });
}

/**
 * @description set flag indicating if redux should automatically select new races
 */
const setAutoSelectNewRaceFlag = (state, action) => {
  return ({
    ...state,
    autoSelectNewRaceFlag: action.autoSelectNewRaceFlag,
  });
}

/**
 * @description reduer to set horse racing countries in the store
 * @param {object} state
 * @param {object} action
 */
const setHorseRaceCountries = (state, action) =>
  updateObject(state, {
    ...state,
    horseCountries: action.filteredCountries,
  });

/**
 * @description reduer to set greyhound racing countries in the store
 * @param {object} state
 * @param {object} action
 */
const setGreyhoundCountries = (state, action) =>
  updateObject(state, {
    ...state,
    greyhoundCountries: action.filteredCountries,
  });

const setEROMarket = (state, action) => {
  return {
    ...state,
    eroMarketData: action.eroResponse,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPEN_MARKETS:
      return setOpenMarkets(state, action);
    case actionTypes.SET_FETCHING_NEW_MARKETS:
      return setIsFetchingNewMarkets(state, action);
    case actionTypes.SET_MARKET_HEADER:
      return setCurrentSelectionMarketInfo(state, action);
    case actionTypes.SET_CURRENT_SELECTION:
      return setCurrentSelection(state, action);
    case actionTypes.SET_CURRENT_VENUE_RACES:
      return setCurrentVenueRaces(state, action);
    case actionTypes.SET_INTERVAL_OPEN_MARKETS:
      return setIntervalOpenMarkets(state, action);
    case actionTypes.SET_HORSE_RACE_COUNTRIES:
      return setHorseRaceCountries(state, action);
    case actionTypes.SET_GREYHOUND_COUNTRIES:
      return setGreyhoundCountries(state, action);
    case actionTypes.SET_ERO_MARKET:
      return setEROMarket(state, action);
    case actionTypes.SET_AUTO_SELECT_NEW_RACE_FLAG:
      return setAutoSelectNewRaceFlag(state, action);
    case actionTypes.FETCH_UPDATED_INPLAY_STATUSES:
      return setUpdatedInPlayValues(state, action);
    default:
      return state;
  }
};

export default reducer;
