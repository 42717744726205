import React from 'react';
import { Modal, ModalSection, ModalHeading } from '../../utils/Modal';
import PositionalModal from '../../utils/PositionalModal';
import Responsive from '../../utils/Responsive';

import RadioButton from '../../utils/controls/RadioButton';

import { countries, statuses, allAusStates, international } from '../../../constants/EventFilterConstants';

const FilterItem = props => {
  const { header, type, active, onChange, style } = props;

  let clicker = null;

  if (type) {
    // clicker = (<input type={type} checked={active} onChange={onChange} />)
    clicker = (<RadioButton active={active} onClick={onChange} type={type}/>)
  }

  return (
    <div className="modal-list-item" style={style}>
      { header }
      { clicker }
    </div>
  );
}

class FiltersModalDesktop extends React.Component {

  state = {
    country:  { id: '' },
    status: { id: '' },
  }

  componentDidUpdate() {
    const { filter } = this.props;
    const { selectedStatus, selectedCountry } = filter;

    const shouldUpdateValues =
      this.state.status.id !== selectedStatus.id ||
      this.state.country.id !== selectedCountry.id;

    if (shouldUpdateValues) {
      this.setState({
        country: selectedCountry,
        status: selectedStatus,
      });
    }
  }

  onStatusChange = status => {
    const { onSubmit } = this.props;
    const { country } = this.state;

    onSubmit({ status, country });
  }

  onCountryChange = country => {
    const { onSubmit } = this.props;

    onSubmit({ country });
  }

  onAusStateChecked = state => {
    this.props.onAusStateChecked(state);
  }

  onCountryChecked = country => {
    this.props.onCountryChecked(country);
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { country, status } = this.state;

    onSubmit({ country, status });
  }

  renderCheckboxes = () => {
     // const { horseCountries, greyhoundCountries, filter } = this.props;
     const { filter } = this.props;

    const {
      region,
      ausStates: selectedAusStates
    } = filter;

    // const showCountries = [ ...new Set(horseCountries.concat(greyhoundCountries)) ];

    const isShowingAus = filter.selectedCountry.id === 'AU';
    const isShowingNZ = filter.selectedCountry.id === 'NZ';

    // we don't filter by any states/countries under the new zealand filter
    if (isShowingNZ)
      return null;

    return (
      <div className="modal-checkbox-container">
        <ModalSection heading="State">
          <div className="modal-section-list checkbox-grid">
            {
              isShowingAus ? (
                allAusStates.map(i => {
                  const active = selectedAusStates.find(x => x.id === i.id) !== undefined

                  return (
                    <FilterItem
                      key={`filter_state_${i.id}`}
                      header={i.label}
                      type="checkbox"
                      active={active}
                      onChange={() => this.onAusStateChecked(i)}
                      style={{width: '50%'}}
                    />
                  );
                })
              )
              :
              (
               international.map(country => {
                  const active = region.find(x => x.id === country.id) !== undefined

                  return (
                    <FilterItem
                      key={`filter_state_${country.id}`}
                      header={country.label}
                      type="checkbox"
                      active={active}
                      onChange={() => this.onCountryChecked(country)}
                      style={{width: '50%'}}
                    />
                  );
                })
              )
            }
          </div>
        </ModalSection>
      </div>
    )
  }

  render() {
    const { cancel, open, targetButton } = this.props;
    const { status, country } = this.state;

    return (
      <React.Fragment>
        <Responsive mobile>
          <Modal slideDirection="up" open={open} onCancel={cancel} onSubmit={this.onSubmit}>
            <ModalHeading>FILTERS</ModalHeading>
            <ModalSection heading="Race Status">
              <div className="modal-section-list">
                {
                  statuses.map(i => (
                    <FilterItem
                      key={`filter_status_${i.id}`}
                      header={i.label}
                      type="radio"
                      active={status.id === i.id}
                      onChange={() => this.onStatusChange(i)} />
                  ))
                }
              </div>
            </ModalSection>

            <ModalSection heading="Region">
              <div className="modal-section-list">
                {
                  countries.map(i => (
                    <FilterItem
                      key={`filter_country_${i.id}`}
                      header={i.labelLong}
                      type="radio"
                      active={country.id === i.id}
                      onChange={() => this.onCountryChange(i)} />
                  ))
                }
              </div>
            </ModalSection>

            { this.renderCheckboxes() }

          </Modal>
        </Responsive>

        <Responsive desktop>
          <PositionalModal
            open={open}
            cancel={cancel}
            onSubmit={this.onSubmit}
            target={targetButton}
            horizontalLayout
          >
            <div>
              <ModalSection heading="Race Status">
                <div className="modal-section-list">
                  {
                    statuses.map(i => (
                      <FilterItem
                        key={`filter_status_${i.id}`}
                        header={i.label}
                        type="radio"
                        active={status.id === i.id}
                        onChange={() => this.onStatusChange(i)} />
                    ))
                  }
                </div>
              </ModalSection>

              <ModalSection heading="Region">
                <div className="modal-section-list">
                  {
                    countries.map(i => (
                      <FilterItem
                        key={`filter_country_${i.id}`}
                        header={i.labelLong}
                        type="radio"
                        active={country.id === i.id}
                        onChange={() => this.onCountryChange(i)} />
                    ))
                  }
                </div>
              </ModalSection>
            </div>

            { this.renderCheckboxes() }

          </PositionalModal>
        </Responsive>
      </React.Fragment>
    );
  }
}

export default FiltersModalDesktop;
