const getSortFunctionFromType = (sortType, sortDesc, sortReferenceData) => {

  if (!sortType || sortDesc === undefined) return () => null;

  if (sortType === 'back' || sortType === 'lay') {
    const dataProp = sortType === 'back' ? 'availableToBack' : 'availableToLay';

    return ({ exchange: a }, { exchange: b }) => {
      const aData = a[dataProp];
      const bData = b[dataProp];

      if (!bData && aData) return -1;
      if (!aData && bData) return 1;
      if (!aData && !bData) return 0;

      if (sortDesc) {
        return bData[0].price - aData[0].price;
      }

      return aData[0].price - bData[0].price;
    }
  }

  if (sortType === 'impliedChanceToWin') {
    return ({ exchange: a }, { exchange: b }) => {
      const aData = a[sortType];
      const bData = b[sortType];

      if (!bData && aData) return -1;
      if (!aData && bData) return 1;
      if (!aData && !bData) return 0;

      if (sortDesc) {
        return bData - aData;
      }

      return aData - bData;
    }
  }

  if (sortType === 'tote') {
    return ({ augmentData: aData }, { augmentData: bData }) => {

      if (!bData.B_TOTE && aData.B_TOTE) return -1;
      if (!aData.B_TOTE && bData.B_TOTE) return 1;
      if (!aData.B_TOTE && !bData.B_TOTE) return 0;

      if (sortDesc) {
        return bData.B_TOTE - aData.B_TOTE;
      }

      return aData.B_TOTE - bData.B_TOTE;
    }
  }

  if (sortType === 'fixed') {
    return ({ augmentData: aData }, { augmentData: bData }) => {

      if (!bData.GB_Fixed_Corp && aData.GB_Fixed_Corp) return -1;
      if (!aData.GB_Fixed_Corp && bData.GB_Fixed_Corp) return 1;
      if (!aData.GB_Fixed_Corp && !bData.GB_Fixed_Corp) return 0;

      if (sortDesc) {
        return bData.GB_Fixed_Corp - aData.GB_Fixed_Corp;
      }

      return aData.GB_Fixed_Corp - bData.GB_Fixed_Corp;
    }
  }

  if (sortType === 'runner') {
    return ({ description: a, exchange: aExchange }, { description: b, exchange: bExchange }) => {
      const aData = Number(a.metadata.CLOTH_NUMBER);
      const bData = Number(b.metadata.CLOTH_NUMBER);

      const aIsScratched = aExchange.impliedChanceToWin === undefined;
      const bIsScratched = bExchange.impliedChanceToWin === undefined;

      if (bIsScratched && !aIsScratched) return -1;
      if (aIsScratched && !bIsScratched) return 1;
      if (aIsScratched && bIsScratched) return 0;

      if (sortDesc) {
        return bData - aData;
      }

      return aData - bData;
    }
  }

  if (sortType === 'totalMatched' || sortType === 'lastPriceTraded') {

    return ({ state: a }, { state: b }) => {

      const aData = a[sortType];
      const bData = b[sortType];

      if (!bData && aData) return -1;
      if (!aData && bData) return 1;
      if (!aData && !bData) return 0;

      if (sortDesc) {
        return bData - aData;
      }

      return aData - bData;
    }
  }

  if (sortType === 'available'
    || sortType === 'wap'
    || sortType === 'bpm'
    || sortType === 'vpm') {

    return (a, b) => {
      const aRunner = a.description.metadata.runnerId;
      const bRunner = b.description.metadata.runnerId;

      const aRunnerRemoved = a.state.status === "REMOVED";
      const bRunnerRemoved = b.state.status === "REMOVED";

      if (bRunnerRemoved) return -1;
      if (aRunnerRemoved) return 1;

      const aData = (sortReferenceData[aRunner] || {})[sortType];
      const bData = (sortReferenceData[bRunner] || {})[sortType];

      if (!bData && aData) return -1;
      if (!aData && bData) return 1;
      if (!aData && !bData) return 0;

      if (sortDesc) {
        return bData - aData;
      }

      return aData - bData;
    }
  }

}

export default getSortFunctionFromType;
