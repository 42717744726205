import React from "react";

function PhoneRotate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="500"
      version="1.1"
      viewBox="0 0 66.146 132.292"
    >
      <g transform="translate(0 -164.708)">
        <g fillOpacity="1" transform="translate(-20.054 156.304) scale(.49992)">
          <rect
            width="121.271"
            height="253.432"
            x="45.716"
            y="22.959"
            fill="#262626"
            strokeWidth="0.174"
            opacity="1"
            ry="14.654"
          ></rect>
          <path
            fill="#fff"
            strokeWidth="0.692"
            d="M58.662 19.166a15.344 15.344 0 00-15.378 15.378v229.158c0 8.52 6.859 15.378 15.378 15.378h95.217c8.52 0 15.378-6.858 15.378-15.378V34.544c0-8.52-6.858-15.378-15.378-15.378zm1.302 5.963h15.441v.81a8.215 8.215 0 008.234 8.233h45.045a8.215 8.215 0 008.234-8.234v-.81h15.659a11.14 11.14 0 0111.165 11.166v225.658a11.14 11.14 0 01-11.165 11.165H59.964A11.14 11.14 0 0148.8 261.952V36.294a11.14 11.14 0 0111.165-11.165z"
            opacity="1"
          ></path>
          <rect
            width="38.198"
            height="2.48"
            x="87.172"
            y="267.898"
            fill="#dbdbdb"
            strokeWidth="0.695"
            opacity="1"
            ry="1.24"
          ></rect>
          <rect
            width="15.397"
            height="1.757"
            x="94.86"
            y="27.33"
            fill="#dbdbdb"
            strokeWidth="0.371"
            opacity="1"
            ry="0.878"
          ></rect>
          <rect
            width="1.936"
            height="1.957"
            x="114.021"
            y="27.198"
            fill="#dbdbdb"
            strokeWidth="0.139"
            opacity="1"
            ry="0.979"
          ></rect>
          <path
            fill="#fff"
            strokeWidth="0.688"
            d="M102.975 110.367a39.814 39.814 0 00-36.569 39.651 39.814 39.814 0 00.11 2.187l13.112-12.858-4.354-4.44a34.48 34.48 0 0127.701-19.163z"
            opacity="1"
          ></path>
          <path
            fill="#fff"
            strokeWidth="0.688"
            d="M106.245 186.817a39.814 39.814 0 0036.569-39.65 39.814 39.814 0 00-.11-2.187l-13.112 12.858 4.355 4.44a34.48 34.48 0 01-27.702 19.162z"
            opacity="1"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default PhoneRotate;
