import React from 'react';
import PropTypes from 'prop-types';
import Responsive from '../../../utils/Responsive';

const defaultColor = '#909090';
const selectedColor = 'white';

const DownArrow = ({ fill, up }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={fill || defaultColor} transform={`translate(4, 2) ${up ? 'rotate(180)' : ''}`} width="15" height="15" viewBox="0 0 26.46 26.46">
    <path d="M13.23 19.3L25.44 7.1H1.02z" />
  </svg>
);

class RunnerSortIcon extends React.PureComponent {
  render() {
    const { isSelected, desc, style, display } = this.props;

    if (!display) return null;

    const downIcon = <DownArrow fill={selectedColor} />
    const upIcon = <DownArrow fill={selectedColor} up />
    const defaultIcon = <DownArrow />

    let current = defaultIcon;

    if (isSelected && desc) {
      current = downIcon;
    }
    else if (isSelected) {
      current = upIcon;
    }

    return (
      <Responsive desktop>
        <span className="runner-table-sort-icon" style={style}>{current}</span>
      </Responsive>);
  }
}

RunnerSortIcon.defaultProps = {
  display: true,
  isSelected: false,
  desc: false,
  style: undefined,
}

RunnerSortIcon.propTypes = {
  display: PropTypes.bool,
  isSelected: PropTypes.bool,
  desc: PropTypes.bool,
  style: PropTypes.shape({}),
}

export default RunnerSortIcon;
