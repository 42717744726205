import React from 'react';
import { connect } from 'react-redux';

import EventContainer from './eventContainer/EventContainer';
import MarketContainer from './MarketContainer';
import TradeContainer from './chart/TradeContainer';
import NoRaces from './NoRaces';
import GeneralUtils from '../utils/GeneralUtils';
import Responsive from '../utils/Responsive';
import { Modal } from '../utils/Modal';
import RotateMobileScreen from './RotateMobileScreen';

import TradeContainerSelector from '../../store/selectors/TradeContainerSelector';
import Analytics from '../utils/Analytics';

class MainPanel extends React.Component {

  state = {
    isShowingRotateModal: false,
  }

  componentDidMount() {
    this.checkShowMobileRotateModal();
  }

  checkShowMobileRotateModal() {
    //check session storage
    const rotateModalViewedFlag = window.localStorage && window.localStorage.getItem("hasViewedMobileRotateModal");
    const isMobile = GeneralUtils.isMobile();

    //return if already stored value
    if (!isMobile) return;
    if (rotateModalViewedFlag === "true" || rotateModalViewedFlag === true) return;

    //show modal
    this.setState({ isShowingRotateModal: true });

    //update session storage
    window.localStorage.setItem("hasViewedMobileRotateModal", true);
  }

  /**
   * @description click to the ga dataLayer object
   */
  addToDataLayer = () => {
    const gaObj = {
      label: 'Market Header',
      value: this.props.marketId
    };
    GeneralUtils.addToDataLayer(gaObj);

    Analytics.track("EVENT_CONTAINER_MARKET_HEADER", { marketId: this.props.marketId });
  };

  render() {
    const { isShowingRotateModal } = this.state;

    if (this.props.marketId) {
      return (
        <div className="main-container">

          <Modal fullscreen
            open={isShowingRotateModal}
            onCancel={() => this.setState({ isShowingRotateModal: false })}>
            <RotateMobileScreen />
          </Modal>

          <div className="main-inner-container">
            <EventContainer addToDataLayerCallBack={this.addToDataLayer} />
            <MarketContainer marketId={this.props.marketId} />
          </div>

          <div className="main-inner-container">
            <Responsive desktop>
            <TradeContainer
              jumpTime={this.props.jumpTime}
              jumpZone={this.props.jumpZone}
              marketId={this.props.marketId}
              marketStatus={this.props.marketStatus}
              marketInPlay={this.props.marketInPlay}
            />
          </Responsive>
          </div>
        </div>
      );
    }

    return ( <NoRaces /> );
  };
}

const mapStateToProps = state => ({
  ...TradeContainerSelector(state),
  marketSize: state.market.markets.length,
});

export default connect(
  mapStateToProps,
  null
)(MainPanel);
