import React from 'react';
import moment from 'moment';
import Spinner from 'reactjs-simple-spinner';
import MediaQuery from 'react-responsive';
import { round } from 'lodash';
import numeral from 'numeral';
import HumanizeDuration from 'humanize-duration';
import PropTypes from 'prop-types';

import { VictoryAxis, VictoryTooltip, VictoryCandlestick, VictoryLabel, VictoryArea, VictoryContainer, VictoryTheme } from 'victory';
import momentDurationFormat from 'moment-duration-format';

import axios from 'axios';
import Cookies from 'js-cookie';
import { getChartdataRequest } from '../../../request';
import Constants from '../../Constants';
import ErrorHandler from '../../utils/ErrorHandler';

const CHART_REFRESH_RATE = process.env.REACT_APP_CHARTING_REFRESH_RATE;
const POSITIVE_CANDLE = '#d7514ff5';
const NEGATIVE_CANDLE = '#84c164f5';
const VOLUME_FILL = '#ffb80f33';
const VOLUME_STROKE = '#ffb80f';

const labelComponent = (
  <VictoryTooltip
    height={80}
    width={130}
    labelComponent={<VictoryLabel dy={30} />}
    style={{
      padding: 0,
      fontSize: 12,
      fontFamily: 'd-din',
      overflow: 'hidden',
    }}
  />
);

/**
 * Undoes the data manipulation used to plot no market movement;
 * the information in the tooltip is the same as originally fetched from the lambda.
 * @param d
 * @return {string}
 */
const labelDataFormatter = d => `Open: ${round(d.open, 2)}\n
          Close: ${round(d.close, 2)}\n
          High: ${round(d.high, 2)}\n
          Low: ${round(d.low, 2)}\n
          Time: ${moment(d.x, 'x')
    .local()
    .format('DD/MM/YYYY HH:mm:ss')}\n
          Volume: $${round(d.volume, 2)}`;

export default class CandlesContainer extends React.Component {
  /**
   * Format the tick point on the chart
   * @param tick
   * @returns {*}
   */
  static formatXLabel(tick) {
    if (tick) {
      return moment(tick, 'x')
        .local()
        .format('DD/MM/YYYY[\n]HH:mm');
    }
    return 0;
  }

  /**
   * Format the candlestick data points.
   * Victory won't plot points if the fields are the same, e.g. open eq close eq high eq low
   * so we modify the data after fetching it. This function further formats this special case data
   * to ensure it is visible and the user can interact with the data point (ie tooltip actions)
   * @param data
   * @return {number}
   */
  static conditionalCandleFormatter(data) {
    const o = round(data.open, 2);
    const c = round(data.close, 2);
    const h = round(data.high, 2);
    const l = round(data.low, 2);
    const openCloseDiff = round(data.open - data.close, 3);

    // long/short legged
    if (o === c && o !== h && o !== l) {
      return 3;
    }

    // dragonfly dojo - open eq high; NB: open is modified after fetch
    if (openCloseDiff === 0.001 && c === h && h > l) {
      return 3;
    }

    // gravestone dojo - open eq high; NB: open is modified after fetch open eq closed eq low neq high
    if (openCloseDiff === 0.001 && o === c && o === l && o !== h) {
      return 3;
    }

    // all datapoints are the same (use rounded values)
    if (o === c && c === h && h === l) {
      return 3;
    }

    return 0;
  }

  constructor(props) {
    super(props);

    const { chartWidth } = this.props;

    this.state = {
      data: null,
      chartWidth: chartWidth || window.innerWidth,
    };

    momentDurationFormat(moment);

    this.isLoading = false;
    this.isError = false;
  }

  componentDidMount() {
    const { selectedRunnerId } = this.props;

    window.addEventListener('resize', this.updateDimensions);

    // set interval for the default runner
    if (selectedRunnerId) {
      this.interval = this.setIntervalImmediate();
    }
  }

  componentDidUpdate(prevProps) {
    this.isError = false;
    const { selectedRunnerId, timeSliceWindow } = this.props;

    if (
      selectedRunnerId &&
      (prevProps.selectedRunnerId !== selectedRunnerId || prevProps.timeSliceWindow !== timeSliceWindow)
    ) {
      // clear initial interval
      if (prevProps.selectedRunnerId) {
        clearInterval(this.interval);
      }

      this.interval = this.setIntervalImmediate();
    }
  }

  componentWillUnmount() {
    const { marketStatus } = this.props;

    if (marketStatus !== 'CLOSED') {
      clearInterval(this.interval);
    }

    window.removeEventListener('resize', this.updateDimensions);
  }

  setIntervalImmediate() {
    const { marketStatus } = this.props;

    this.isLoading = true;
    this.isError = false;

    const updatePlotPoints = () => {
      const { selectedRunnerId, marketId, timeSliceWindow, matchedValue, turnInPlay } = this.props;
      this.getPlotPoints(selectedRunnerId, marketId, timeSliceWindow, matchedValue, turnInPlay);
    }

    updatePlotPoints();

    if (marketStatus !== 'CLOSED') {
      return setInterval(updatePlotPoints, CHART_REFRESH_RATE);
    }

    return null;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * Make explicit call to obtain recent historical data.
   * Note, does not make a request if the matched value is 0.
   * We manipulate the data slightly (sub cents values) to force Victory
   * to render candlesticks/doji correctly
   * @param runnerId - selected runner
   * @param marketId - selected market
   * @param timeSliceWindow - time from jump
   * @param matchedValue - matched value of runner
   * @param turnInPlay - flag
   */
  getPlotPoints(runnerId, marketId, timeSliceWindow, matchedValue, turnInPlay) {
    this.isError = false;
    const { jumpTime } = this.props;
    // don't fetch market info for runner
    if (matchedValue === 0) {
      this.isLoading = false;
      this.setState({ data: [] });
      return;
    }

    const jumpTimeValue = moment.utc(jumpTime).local().valueOf();
    const request = getChartdataRequest(jumpTimeValue, timeSliceWindow, turnInPlay, marketId, runnerId);

    // axios({
    //   method: 'get',
    //   url,
    //   headers: {
    //     'X-Authentication': Cookies.get('ssoid'),
    //     "X-Application": process.env.REACT_APP_APP_KEY,
    //   },
    //   timeout: 10000,
    // })
    request
      .then(response => {
        this.isLoading = false;
        this.isError = false;

        const { data } = response;
        const newData = data.map(dataPoint => {
          const newDataPoint = { ...dataPoint };

          // dragonfly doji - low is different
          if (
            dataPoint.close === dataPoint.high &&
            dataPoint.close === dataPoint.open &&
            parseFloat(dataPoint.close) > parseFloat(dataPoint.low)
          ) {
            newDataPoint.open = parseFloat(dataPoint.open) + 0.001;
          }

          // gravestone doji - high is different
          if (
            dataPoint.close === dataPoint.open &&
            dataPoint.close === dataPoint.low &&
            parseFloat(dataPoint.close) < parseFloat(dataPoint.high)
          ) {
            newDataPoint.open = parseFloat(dataPoint.open) + 0.001;
          }

          // long legged / short legged doji
          if (
            dataPoint.open === dataPoint.close &&
            dataPoint.close !== dataPoint.high &&
            dataPoint.close !== dataPoint.low
          ) {
            newDataPoint.open = parseFloat(dataPoint.open) - 0.001;
            newDataPoint.close = parseFloat(dataPoint.close) + 0.001;
          }

          if (
            dataPoint.close === dataPoint.low &&
            dataPoint.close === dataPoint.open &&
            dataPoint.close === dataPoint.high
          ) {
            // manipulate the data slightly so a plot point will be visible
            newDataPoint.high = `${parseFloat(dataPoint.high) + 0.001}`;
            newDataPoint.low = `${parseFloat(dataPoint.low) - 0.001}`;
            newDataPoint.close = `${parseFloat(dataPoint.close) + 0.002}`;
            newDataPoint.open = `${parseFloat(dataPoint.open) - 0.002}`;
          }

          // -ve volume is invalid
          if (parseFloat(dataPoint.volume) < 0) newDataPoint.volume = 0;

          return newDataPoint;
        });

        // define high and low data points for the various fields
        const dataHigh = Math.max(...newData.map(item => item.high));
        const dataLow = Math.min(...newData.map(item => item.low));
        const dateHigh = Math.max(...newData.map(item => item.datetime));
        const dateLow = Math.min(...newData.map(item => item.datetime));
        const volumeHigh = Math.max(...newData.map(item => item.volume));
        const volumeLow = Math.min(...newData.map(item => item.volume));

        this.setState({
          data: newData,
          dataHigh,
          dataLow,
          dateHigh,
          dateLow,
          volumeHigh,
          volumeLow,
        });
      })
      .catch(error => {
        this.isLoading = false;
        this.isError = true;
        console.log('an error occurred fetching charting data', error);
      });
  }

  componentDidCatch(error, info) {
    this.isLoading = false;
    console.log(error, info);
  }

  /**
   * Process lambda data for use with candlestick chart
   * @returns {*}
   */
  processData() {
    const { data } = this.state;

    if (!data) return null;

    return data.map(dataPoint => ({
      x: dataPoint.datetime,
      close: parseFloat(dataPoint.close),
      open: parseFloat(dataPoint.open),
      high: parseFloat(dataPoint.high),
      low: parseFloat(dataPoint.low),
      y: parseFloat(dataPoint.volume), // for bar chart
      volume: parseFloat(dataPoint.volume),
    }));
  }

  /**
   * Store window width in React state
   * @param event
   */
  updateDimensions = event => {
    const { chartWidth } = this.state;
    const newWidth = event.target.innerWidth;

    // only updates when it's a significant change
    // saves setState getting spammed
    if (Math.abs(chartWidth - newWidth) > 30) {
      this.setState({ chartWidth: newWidth });
    }
  }

  /**
   * Populate the 'tooltip' replacement when viewing on mobile
   * @param data
   */
  processToolTip(data) {
    const { tooltipCallBack } = this.props;
    tooltipCallBack(data);
  }

  /**
   * Renders a Victory Candlestick and Bar chart inside an SVG wrapper
   * @return {*}
   */
  renderComboChart() {
    const { dataLow, dataHigh, volumeLow, volumeHigh, dateLow, dateHigh, chartWidth: stateChartWidth } = this.state;
    const { chartWidth: propsChartWidth, timeSliceWindow } = this.props;

    const candleStickLowerAdjustment = dataLow - dataLow * 0.05;
    const volumeUpperAdjustment = volumeHigh * 1.01; // to prevent volume chart overflowing
    const domainPadding = 7;
    const width =
      propsChartWidth || (stateChartWidth > Constants.responsiveMobileWidthBreakpoint
        ? stateChartWidth - 430
        : stateChartWidth - 5);

    // Height needs to be calculated as 330 is too large on mobile
    const height = Math.min(330, width / 1.5);

    // Label offset needs to be calculated as the height is dynamic
    const labelOffset = -height / 3 - height / 10;

    // Set the theme up to have the calculated width and height
    const theme = Object.keys(VictoryTheme.material).reduce((p, c) => {
      const newObj = {
        ...p,
      };
      newObj[c] = {
        ...VictoryTheme.material[c],
        width: width,
        height: height,
      };
      return newObj;
    }, {});


    return (
      <VictoryContainer
        width={width}
        height={height}
        theme={theme}
      >
        {/* x-axis */}
        <VictoryAxis
          standalone={false}
          theme={theme}
          style={{
            ticks: {
              size: 7,
              stroke: 'black',
              strokeWidth: 1,
            },
            tickLabels: {
              fontFamily: 'd-din',
              fontSize: 14,
              padding: 5,
              textAlign: 'right',
            },
          }}
          fixLabelOverlap
          label={`Time - Candlestick interval ${HumanizeDuration(timeSliceWindow)}`}
          axisLabelComponent={<VictoryLabel dy={40} style={{ fontFamily: 'd-din-bold' }} />}
          // diff from jump time
          tickFormat={t => CandlesContainer.formatXLabel(t)}
          scale="linear"
          domain={[dateLow, dateHigh]}
        />
        {/* y-axis bar */}
        <VictoryAxis
          orientation="right"
          theme={theme}
          dependentAxis
          label="Volume"
          axisLabelComponent={<VictoryLabel angle={0} dy={labelOffset} dx={0} style={{ fontFamily: 'd-din-bold' }} />}
          tickFormat={t => `$${numeral(parseFloat(t)).format('0.0a')}`}
          standalone={false}
          style={{
            grid: { stroke: '#979797', strokeWidth: 0.5 },
            tickLabels: { fontFamily: 'd-din', fontSize: 14 },
          }}
          domain={{ y: [volumeLow, volumeUpperAdjustment] }}
        />

        <MediaQuery minWidth={Constants.responsiveDesktopWidthBreakpoint}>
          <VictoryArea
            maxDomain={{ y: volumeUpperAdjustment }}
            theme={theme}
            interpolation="monotoneX"
            barWidth={50}
            style={{
              data: { width: 10, strokeWidth: 2, fill: VOLUME_FILL, stroke: VOLUME_STROKE },
            }}
            data={this.processData()}
            standalone={false}
            labels={d => labelDataFormatter(d)}
            labelComponent={labelComponent}
          />
        </MediaQuery>
        <MediaQuery maxWidth={Constants.responsiveMobileWidthBreakpoint}>
          <VictoryArea
            theme={theme}
            maxDomain={{ y: volumeUpperAdjustment }}
            interpolation="monotoneX"
            style={{
              data: { width: 10, strokeWidth: 2, fill: VOLUME_FILL, stroke: VOLUME_STROKE },
            }}
            data={this.processData()}
            standalone={false}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: (event, data) => {
                    this.processToolTip(data.data[data.index]);
                  },
                },
              },
            ]}
          />
        </MediaQuery>

        {/* y-axis candlestick */}

        <VictoryAxis
          dependentAxis
          theme={theme}
          orientation="left"
          standalone={false}
          label="Price"
          axisLabelComponent={<VictoryLabel angle={0} dy={labelOffset} style={{ fontFamily: 'd-din-bold' }} />}
          tickFormat={t => `$${parseFloat(t).toFixed(2)}`}
          domain={[candleStickLowerAdjustment, dataHigh]}
          style={{
            tickLabels: { fontFamily: 'd-din', fontSize: 14 },
          }}
        />

        <MediaQuery minWidth={Constants.responsiveDesktopWidthBreakpoint}>
          <VictoryCandlestick
            theme={theme}
            candleWidth={15}
            domainPadding={{ x: domainPadding }}
            domain={{ y: [candleStickLowerAdjustment, dataHigh] }}
            style={{ data: { strokeWidth: d => CandlesContainer.conditionalCandleFormatter(d) } }}
            candleColors={{ positive: POSITIVE_CANDLE, negative: NEGATIVE_CANDLE }}
            data={this.processData()}
            standalone={false}
            wickStrokeWidth={3}
            labels={d => labelDataFormatter(d)}
            labelComponent={labelComponent}
          />
        </MediaQuery>

        <MediaQuery maxWidth={Constants.responsiveMobileWidthBreakpoint}>
          <VictoryCandlestick
            theme={theme}
            candleWidth={10}
            domainPadding={{ x: domainPadding }}
            domain={{ y: [candleStickLowerAdjustment, dataHigh] }}
            style={{ data: { strokeWidth: d => CandlesContainer.conditionalCandleFormatter(d) } }}
            candleColors={{ positive: POSITIVE_CANDLE, negative: NEGATIVE_CANDLE }}
            data={this.processData()}
            standalone={false}
            wickStrokeWidth={1}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: (event, data) => {
                    this.processToolTip(data.data[data.index]);
                  },
                },
              },
            ]}
          />
        </MediaQuery>
        {/* candlestick */}
      </VictoryContainer>
    );
  }

  render() {
    const { data } = this.state;

    return (
      <div className="graph-vis">
        {this.isLoading && <Spinner lineFgColor="#ffb80f" lineBgColor="#1e1e1e" size={30} lineSize={5} />}

        {this.isError && <ErrorHandler />}

        {data && data.length > 0
          ? this.renderComboChart()
          : (
            <div className="graph-no-data">No data available for this runner</div>
          )}
      </div>
    );
  }
}

CandlesContainer.propTypes = {
  chartWidth: PropTypes.number,
  selectedRunnerId: PropTypes.number,
  marketStatus: PropTypes.string,
  marketId: PropTypes.string.isRequired,
  timeSliceWindow: PropTypes.string.isRequired,
  matchedValue: PropTypes.number,
  turnInPlay: PropTypes.bool.isRequired,
  jumpTime: PropTypes.string,
  tooltipCallBack: PropTypes.func.isRequired
};

CandlesContainer.defaultProps = {
  selectedRunnerId: null,
  jumpTime: null,
  chartWidth: null,
  marketStatus: null,
  matchedValue: undefined
};
