import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, orderBy, round, extend, map, assign, find } from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';

import RunnerTableRowUtil from './runnerUtils/RunnerTableRowUtil';

import RunnerTableSingleRow from './components/RunnerTableSingleRow';
import RunnerTableScratchedRow from './components/RunnerTableScratchedRow';

import { getResultsRequest } from '../../../request';

const {
  REACT_APP_APP_KEY
} = process.env;

class RunnerTableContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      runnerMappedDescription: []
    };

    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentDidMount() {
    this.mounted = true;
    this.setMarketBackAndLayPercentage();

  }

  componentDidUpdate() {
    const marketStatus = this.props.marketStatus;
    if (marketStatus === 'OPEN' ) {
      this.setMarketBackAndLayPercentage();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  SingleRowComponent = props => {
    return  <RunnerTableSingleRow {...props} />
  }

  SingleScratchedRowComponent = props => {
    return (
      <RunnerTableScratchedRow {...props} />
    );
  }

  setMarketBackAndLayPercentage = () => {
    const { setBackNLayMartketPercentage, exchange, state } = this.props;

    RunnerTableRowUtil.setMarketBackAndLayPercentage(
      exchange,
      state,
      setBackNLayMartketPercentage
    );
  };

  getClosedMarketRunnerData = marketId => {
    const runnerDescription = this.props.runnerDescription;
    const runnerIds = this.props.runnerIds;

    const runnerDescriptionMapped = map(runnerDescription, (value, index) => {
      const runnerId = runnerIds[index];
      return extend({}, value, { runnerId: runnerId });
    });

    const request = getResultsRequest(marketId);

    request.then(res => {
      const mappedResult = map(res.data, value => {
        return {
          metadata: value,
          runnerId: value.runnerId,
        };
      });
      const runnerDescNewMapped = map(
        runnerDescriptionMapped,
        eroRunnerDescriptionObj => {
          return assign(
            eroRunnerDescriptionObj,
            find(mappedResult, {
              runnerId: eroRunnerDescriptionObj.runnerId,
            }),
          );
        },
      );
      if (this.mounted) {
        this.setState({
          runnerMappedDescription: runnerDescNewMapped,
        });
      }
    });
  };

  getRunnerRowData = (index, runnerExchange, runnerState, runnerId, calculatedList) => {
    const { mobile, marketStatus, miniGraphArray, sp, totalMatched, augment } = this.props;

    let miniGraphData;
    if (miniGraphArray !== undefined) {
      miniGraphData = miniGraphArray[runnerId];
    }

    const runnerSP = RunnerTableRowUtil.getRunnerSP(marketStatus, sp[index]);

    const runnerTotalMatched = RunnerTableRowUtil.runnerDataFormatter(
      runnerState,
      'totalMatched',
      '$',
      0,
    );
    const runnerTotalMatchedPercentage = RunnerTableRowUtil.getRunnerMatchedPercentage(
      totalMatched,
      runnerState.totalMatched,
    );

    const wap = RunnerTableRowUtil.runnerDataFormatter(calculatedList, 'wap', '$', 2);

    const lastPriceTraded = RunnerTableRowUtil.runnerDataFormatter(
      runnerState,
      'lastPriceTraded',
      '$',
      2,
    );

    const vpm = RunnerTableRowUtil.runnerDataFormatter(
      calculatedList,
      'vpm',
      mobile ? '' : '$',
      0);

    const bpm = RunnerTableRowUtil.runnerDataFormatter(calculatedList, 'bpm', '', 2);

    let { availableToBack, availableToLay } = runnerExchange;
    availableToBack = orderBy(availableToBack, ['price'], ['desc']);
    availableToLay = orderBy(availableToLay, ['price'], ['asc']);

    const toteValue = RunnerTableRowUtil.runnerDataFormatter(augment && augment[index], 'B_TOTE', '$', 2);
    const fixedValue = RunnerTableRowUtil.runnerDataFormatter(augment && augment[index], 'GB_Fixed_Corp', '$', 2);

    return {
      miniGraphData,
      runnerSP,
      runnerTotalMatchedPercentage,
      runnerTotalMatched,
      wap,
      lastPriceTraded,
      vpm,
      bpm,
      availableToLay,
      availableToBack,
      toteValue,
      fixedValue,
    }
  }

  render() {
    const {
      mobile,
      eventType,
      marketCountry,
      marketId,
      runnerIds,
      runnerDescription,
      exchange,
      state,
      calculated,
      shouldHide,
      marketStatus,
      augment,
      viewPriority,
      isMobileAndRotated,
      onGraphClick
    } = this.props;

    const hasTote = augment && augment.some(v => v && v.B_TOTE);
    const hasFixed = augment && augment.some(v => v && v.GB_Fixed_Corp);

    if (marketStatus !== 'CLOSED') {
      return runnerDescription.map((runnerDesc, index) => {
        const runnerExchange = exchange[index];
        const runnerState = state[index];
        const runnerId = runnerIds[index];
        const calculatedList = calculated[runnerId];

        if (runnerState.status === 'ACTIVE') {
          const runnerRowData = this.getRunnerRowData(index, runnerExchange, runnerState, runnerId, calculatedList);

          const available = RunnerTableRowUtil.runnerDataFormatter(
            calculatedList,
            'available',
            '$',
            0,
          );

          let backSizeSum = RunnerTableRowUtil.getSumOfLayOrBack(runnerRowData.availableToBack);
          let laySizeSum = RunnerTableRowUtil.getSumOfLayOrBack(runnerRowData.availableToLay);

          let wom = Math.floor(
            (backSizeSum / (backSizeSum + laySizeSum)) * 100,
          );
          let womColour = wom && wom < 50 ? 'rt-blue rt-text-bold' : 'rt-pink rt-text-bold';

          const impliedChanceToWinValue = exchange[index].impliedChanceToWin;
          const impliedChanceToWin = impliedChanceToWinValue ?
            `${impliedChanceToWinValue}%`
            : (mobile ? '' : '-');

          return (
            <RunnerTableSingleRow
              key={`runnertable_row_${runnerId}`}
              { ...{
                runnerId,
                marketId,
                runnerDesc,
                womColour,
                wom,
                available,
                eventType,
                marketCountry,
                index,
                shouldHide,
                hasTote,
                hasFixed,
                impliedChanceToWin,
                viewPriority,
                isMobileAndRotated
              }}
              { ...runnerRowData }
              onGraphClick={i => onGraphClick(i)}
          /> );
        }

        if (
          runnerState.status === 'REMOVED' &&
          runnerState.removalDate != null
        ) {

          return (
            <this.SingleScratchedRowComponent
              key={`runnertable_scratched_row_${runnerId}`}
              { ...{
                runnerId,
                marketId,
                runnerDesc,
                hasTote,
                hasFixed,
                eventType,
                marketCountry,
                index,
                runnerState,
                viewPriority,
              }}
            />
          );
        }

        return null;
      });
    }

    if (marketStatus === 'CLOSED') {
      if (this.mounted || mobile) {
        this.getClosedMarketRunnerData(marketId);
      }

      const runnerMappedDescription =
        this.state.runnerMappedDescription.length > 0
          ? this.state.runnerMappedDescription
          : runnerDescription;

      return runnerMappedDescription.map((runnerDesc, index) => {
        const runnerExchange = exchange[index];
        const runnerState = state[index];
        const runnerId = runnerIds[index];
        const calculatedList = calculated[runnerId];

        if (
          runnerState.status !== 'REMOVED' ||
          (mobile && (runnerState.status === 'LOSER' || runnerState.status === 'WINNER'))
        ) {

          const runnerRowData = this.getRunnerRowData(index, runnerExchange, runnerState, runnerId, calculatedList);

          let resultPosition = '';

          if (state[index].status !== 'WINNER') {
            resultPosition = RunnerTableRowUtil.convertResultPosition(
              Number(runnerDesc.metadata.placed),
            );
          } else {
            resultPosition = RunnerTableRowUtil.convertResultPosition(1);
          }

          const impliedChanceToWin = runnerRowData.availableToBack[0]
            ? `${round((1 / runnerRowData.availableToBack[0].price) * 100, 1)}%`
            : '-';

            let placeBackgroundClass;

          if (resultPosition === 99) {
            placeBackgroundClass = mobile ? 'rt-winner-poly-mobi none-opa' : 'hidden';
          } else {
            placeBackgroundClass = mobile ? 'rt-winner-poly-mobi' : 'rt-winner-poly';
          }

          return (
            <RunnerTableSingleRow closed
              key={`runnertable_row_closed_${runnerId}`}
              { ...{
                runnerId,
                marketId,
                runnerDesc,
                placeBackgroundClass,
                resultPosition,
                eventType,
                marketCountry,
                index,
                shouldHide,
                hasTote,
                hasFixed,
                impliedChanceToWin,
                viewPriority,
                isMobileAndRotated,
              }}
              { ...runnerRowData }
            /> );

        }
        if (
          runnerState.status === 'REMOVED' &&
          runnerState.removalDate != null
        ) {

          return (
            <this.SingleScratchedRowComponent closed
              key={`runnertable_scratched_row_closed_${runnerId}`}
              { ...{
                runnerId,
                marketId,
                runnerDesc,
                hasTote,
                hasFixed,
                eventType,
                marketCountry,
                index,
                runnerState,
                viewPriority,
              }}
            />
          );
        }

        return null;
      });
    }
  }
}

export default RunnerTableContent;

RunnerTableContent.propTypes = {
  shouldHide: PropTypes.bool.isRequired,
  exchange: PropTypes.arrayOf(PropTypes.object).isRequired,
  runnerDescription: PropTypes.arrayOf(PropTypes.object).isRequired,
  state: PropTypes.arrayOf(PropTypes.object).isRequired,
};
