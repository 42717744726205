import "core-js/es6/map";
import "core-js/es6/set";
import React, { Component } from "react";
import Auth from "./components/Auth";
import Header from "./components/Header";
import Sidebar from "./components/sidebar/Sidebar";
import MainPanel from "./components/main/MainPanel";
import { connect } from "react-redux";
import * as actionCreators from "./store/actions";
import Analytics from './components/utils/Analytics';

import "./css/App.css";
import Footer from "./components/footer";
class App extends Component {

  componentDidMount() {
    Analytics.init();
  }

  lastActionTime = () => {
    this.props.setLastActionTime();
  };

  render() {
    return (
      <div className="app-container" onClick={this.lastActionTime}>
        <Header />
        <Auth>
          <div className="app-content">
            <Sidebar />
            <MainPanel />
          </div>
        </Auth>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setLastActionTime: () => dispatch(actionCreators.setLastActionTime())
});

export default connect(
  null,
  mapDispatchToProps
)(App);
