import moment from 'moment-timezone';
import * as actionTypes from '../actions/actionTypes';

import { countries, statuses, allAusStates } from '../../constants/EventFilterConstants';

const GMTTZ = process.env.REACT_APP_TIMEZONE_GMT
  ? process.env.REACT_APP_TIMEZONE_GMT
  : 'EUROPE/LONDON';
const tzFormat = process.env.REACT_APP_TIMEZONE_DATE_FORMAT
  ? process.env.REACT_APP_TIMEZONE_DATE_FORMAT
  : 'YYYY-MM-DD HH:MM:ss';

const eventDays = [
  { id: "today", label: "today", dateString: moment() },
  { id: "tomorrow", label: "tomorrow", dateString: moment().add(1, "d") },
  {
    id: "+2days",
    label: moment()
      .add(2, "d")
      .format("dddd"),
    dateString: moment().add(2, "d")
  }
];

const initialState = {
  startDate: moment(new Date())
    .subtract(1, 'days')
    .startOf('day')
    .tz(GMTTZ)
    .format(tzFormat),
  endDate: moment(new Date())
    .endOf('day')
    .tz(GMTTZ)
    .format(tzFormat),
  eventTypes: [
    { active: true, eventId: '7', raceCode: 'R', id: 'horses' },
    { active: true, eventId: '4339', raceCode: '', id: 'greyhounds' },
    { active: true, eventId: '7', raceCode: 'H', id: "harness" }
  ],
  eventDays,
  selectedDay: eventDays[0],
  countries,
  selectedCountry: countries[0],
  statuses,
  selectedStatus: statuses[0],
  region: [],
  allAusStates,
  ausStates: [],
};

/**
 * @description takes in event filter toggles and updates the appropriate fields
 * @param {object} state
 * @param {object} action.filter
 */
const updateEventFilters = (state, { filter }) => {
  const { day, status, country, eventType, ausState, region } = filter;
  let updatedState = {...state};

  if (day) {
    const { startDate, endDate } = updateStartEndTimes(state, day);

    updatedState.selectedDay = day;
    updatedState.startDate = startDate;
    updatedState.endDate = endDate;
  }

  if (status) updatedState.selectedStatus = { ...status };
  if (country) updatedState.selectedCountry = { ...country };
  if (region) updatedState.region = updateRegion(state, region);

  if (eventType) updatedState.eventTypes = updateEventTypes(state, filter.eventType);
  if (ausState) updatedState.ausStates = updateAusStates(state, ausState);

  return updatedState;
}

// find event type and set active = !active
// return eventTypes array
const updateEventTypes = (state, filter) => {
  let updatedList = state.eventTypes.slice();

  let activeCount = updatedList.reduce((total, x) => { return x.active ? total + 1 : total; }, 0);
  let item = updatedList.find(i => (i.typeId === filter.typeId && i.raceCode === filter.raceCode));

  if (item) {

    // if its the only active item in the list, do nothing
    if (activeCount === 1 && item.active) {
      return updatedList;
    }

    item.active = !item.active;
  }

  return updatedList;
}

// if Region is found, remove it from array
// otherwise push item to array
// return updated array
const updateRegion = (state, item) => {
  const itemToRemoveIndex = state.region.findIndex(i => i.id === item.id);

  if (itemToRemoveIndex > -1) {
    return [
      ...state.region.slice(0, itemToRemoveIndex),
      ...state.region.slice(itemToRemoveIndex + 1)
    ];
  }

  return [
    ...state.region,
    item
  ];
}

// if State is found, remove it from array
// otherwise push item to array
// return updated array
const updateAusStates = (state, item) => {
  const itemToRemoveIndex = state.ausStates.findIndex(i => i.id === item.id);

  if (itemToRemoveIndex > -1) {
    return [
      ...state.ausStates.slice(0, itemToRemoveIndex),
      ...state.ausStates.slice(itemToRemoveIndex + 1)
    ];
  }

  return [
    ...state.ausStates,
    item
  ];
}

/*
 * calculate start and end dates for URL params
 */
const updateStartEndTimes = (state, filter) => {
  const selectedDay = filter;
  const endDate = moment(selectedDay.dateString);
  let startDate = moment(selectedDay.dateString);

  if (selectedDay.id === "today") {
    startDate = startDate.subtract(1, "days");
  }

  const milliSeconds45Mins = process.env.REACT_APP_TIMEZONE_ADJUSTMENT;
  const startDateMinus45Mins = startDate
    .startOf("day")
    .tz(GMTTZ)
    .subtract(milliSeconds45Mins, "milliseconds")
    .format(tzFormat);
  const endDatePlus45Mins = endDate
    .endOf("day")
    .tz(GMTTZ)
    .add(milliSeconds45Mins, "milliseconds")
    .format(tzFormat);

  return ({
    startDate: startDateMinus45Mins,
    endDate: endDatePlus45Mins,
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVENT_FILTER_UPDATE:
      return updateEventFilters(state, action);
    default:
      return state;
  }
};

export default reducer;
