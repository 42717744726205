import moment from 'moment-timezone';

/**
 * Find all markets on the same day and venue as the currently selected market
 */
export default (currentSelection, markets) => {
  if(!currentSelection || !markets) {
    return [];
  }

  const { venue, marketTime, timezone, eventTypeId } = currentSelection;

  const res = markets.filter((race) => {
      const raceTime = moment.utc(race.marketTime).tz(race.timezone);
      const currentRaceTime = moment.utc(marketTime).tz(timezone);
      return raceTime && currentRaceTime && race.venue === venue && raceTime.isSame(currentRaceTime, 'day') && race.eventTypeId === eventTypeId;
    });
  return res;
}
