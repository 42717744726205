import Cookies from "js-cookie";

class Constants {
  static get urlConstants() {
    const devUrlConstants = {
      HOME_URL: process.env.REACT_APP_PUBLIC_URL
    };
    const urlConstants = {
      LOGIN_URL: `https://identitysso.betfair.com.au/view/login?product=xhfghNkNnwMriecV&redirectMethod=GET&url=${
        devUrlConstants.HOME_URL
      }`
    };
    return urlConstants;
  }

  static get defaultApiData() {
    const apiData = {
      token: Cookies.get("ssoid"),
      appKey: process.env.REACT_APP_APP_KEY,
      method: "SportsAPING/v1.0/listEvents",
      filter: {
        eventTypeIds: ["7"],
        marketCountries: ["AU"]
      }
    };
    return apiData;
  }

  static get defaultLoginHeader() {
    const loginHeader = {
      Accept: "application/json",
      "X-Application": process.env.REACT_APP_APP_KEY,
      "Content-Type": "application/x-www-form-urlencoded"
    };
    return loginHeader;
  }

  static get defaultApiAccountHeader() {
    const apiAccountHeader = {
      Accept: "application/json",
      "X-Application": process.env.REACT_APP_APP_KEY,
      "X-Authentication": Cookies.get("ssoid"),
    };
    return apiAccountHeader;
  }

  static get responsiveMobileWidthBreakpoint() { return 829; }

  static get responsiveDesktopWidthBreakpoint() { return 830; }
}
export default Constants;
