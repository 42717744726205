import React from "react";

const LoadingDots = props => {

  const {
    minHeight = undefined,
  } = props;

  const style = {
    minHeight,
  }

  return (
    <div className="loading-dots" style={style} { ...props }>
      <div className="dot dot-0" />
      <div className="dot dot-1" />
      <div className="dot dot-2" />
    </div>
  )
}

export default LoadingDots;
