import querystring from 'querystring';
import Cookies from 'js-cookie';
import axios from 'axios';

const {
  REACT_APP_EVENTS_NEW_URL_ENABLED,
  REACT_APP_USER_NEW_URL_ENABLED,
  REACT_APP_CHARTDATA_NEW_URL_ENABLED,
  REACT_APP_AUGMENT_NEW_URL_ENABLED,
  REACT_APP_ACTIVITY_NEW_URL_ENABLED,
  REACT_APP_MINIGRAPH_NEW_URL_ENABLED,
  REACT_APP_RESULTS_NEW_URL_ENABLED,
  REACT_APP_ERO_NEW_URL_ENABLED,
  REACT_APP_DEFAULT_OLD_API_USER,
  REACT_APP_DEFAULT_OLD_API_CHARTDATA,
  REACT_APP_DEFAULT_OLD_API_AUGMENT,
  REACT_APP_DEFAULT_OLD_API_ACTIVITY,
  REACT_APP_DEFAULT_OLD_API_MINIGRAPH,
  REACT_APP_DEFAULT_OLD_API_RESULTS,
  REACT_APP_DEFAULT_OLD_API_ERO,
  REACT_APP_DEFAULT_OLD_API_EVENTS,
  REACT_APP_DEFAULT_NEW_API,
  REACT_APP_DEFAULT_API,
  REACT_APP_DEFAULT_NEW_ERO_API,
  REACT_APP_DEFAULT_ERO_API,
  REACT_APP_DEFAULT_API_ERO,
  REACT_APP_DEFAULT_API_EVENTS,
  REACT_APP_DEFAULT_API_USER,
  REACT_APP_DEFAULT_API_MARKETS,
  REACT_APP_DEFAULT_API_RUNNERS,
  REACT_APP_DEFAULT_API_CHARTDATA,
  REACT_APP_DEFAULT_API_AUGMENT,
  REACT_APP_DEFAULT_API_ACTIVITY,
  REACT_APP_DEFAULT_API_MINIGRAPH,
  REACT_APP_DEFAULT_API_RESULTS,
  REACT_APP_AUGMENT_KEYS,
  REACT_APP_MARKET_TABLE_API_REFRESH_FILTER,
  REACT_APP_APP_KEY
} = process.env;

export const getEventsRequest = (filters) => {
  const headers = { 
    'x-authentication': Cookies.get('ssoid'),
  }

  const activeEvents = filters.eventTypes.filter(type => type.active);
  const eventList = activeEvents.map(i => i.eventId).join(',');
  const raceCodeList = activeEvents.map(i => i.raceCode).join(',');

  if (REACT_APP_EVENTS_NEW_URL_ENABLED === 'true') {
    const country = filters.selectedCountry.id;
    const { startDate, endDate } = filters;
    const url = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_EVENTS}?country=${country}&startDate=${startDate}&endDate=${endDate}&eventTypes=${eventList}&raceCodes=${raceCodeList}`;
    return axios.get(url, { headers: { ...headers, 'X-Application': REACT_APP_APP_KEY }});
  }

  const apiQuery = querystring.stringify({
    country: filters.selectedCountry.id,
    startDate: filters.startDate,
    endDate: filters.endDate,
  });

  const apiQueryParsed = apiQuery.replace(/&/g, ',');
  const url = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_EVENTS}?filters=${apiQueryParsed}&eventTypes=${eventList}&raceCodes=${raceCodeList}`;
  return axios.get(url, { headers });
}

export const getUserRequest = (ssoid) =>  {
  const headers = {
    "X-Authentication": ssoid,
  };
  if (REACT_APP_USER_NEW_URL_ENABLED === 'true') {
    const url = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_USER}`;
    return axios.get(url, { headers: { ...headers, 'X-Application': REACT_APP_APP_KEY }});
  }
  const url = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_USER}`;
  return axios.get(url, { headers });
}

export const getChartdataRequest = (jumpTimeValue, timeSliceWindow, turnInPlay, marketId, runnerId) => {
  const headers = {
    'X-Authentication': Cookies.get('ssoid'),
  };

  if (REACT_APP_CHARTDATA_NEW_URL_ENABLED === 'true') {
    const baseURL = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_MARKETS}/${marketId}${REACT_APP_DEFAULT_API_RUNNERS}/${runnerId}${REACT_APP_DEFAULT_API_CHARTDATA}`;
    const url = `${baseURL}?timeSliceWindow=${timeSliceWindow}&turnInPlayEnabled=${turnInPlay}&jumpTime=${jumpTimeValue}`;  
    return axios({method: 'get', url, headers:{ ...headers, 'X-Application': REACT_APP_APP_KEY }, timeout: 10000});
  }
  
  const baseURL = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_CHARTDATA}?marketId=${marketId}&runnerId=${runnerId}`;
  const url = `${baseURL}&timeSliceWindow=${timeSliceWindow}&turnInPlayEnabled=${turnInPlay}&jumpTime=${jumpTimeValue}`;
  return axios({method: 'get', url, headers, timeout: 10000});
}

export const getAugmentRequest = (marketId) => {
  const headers = { 
    'X-Authentication': Cookies.get('ssoid'),
  };

  if (REACT_APP_AUGMENT_NEW_URL_ENABLED === 'true') {
    const url = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_MARKETS}/${marketId}${REACT_APP_DEFAULT_API_AUGMENT}`;
    return axios.get(url, { headers: { ...headers, 'X-Application': REACT_APP_APP_KEY } });
  }
  
  const url = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_AUGMENT}/${marketId}?keys=${REACT_APP_AUGMENT_KEYS}`;
  return axios.get(url, { headers });
}

export const getActivityRequest = (marketId, turnInPlayEnabled) => {
  const headers = { 
    'X-Authentication': Cookies.get('ssoid'),
  };

  if (REACT_APP_ACTIVITY_NEW_URL_ENABLED === 'true') {
    const CALCULATED_DATA_URL = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_MARKETS}/${marketId}${REACT_APP_DEFAULT_API_ACTIVITY}`;
    const url = `${CALCULATED_DATA_URL}?turnInPlayEnabled=${turnInPlayEnabled}`;
    return axios.get(url, { headers: { ...headers, 'X-Application': REACT_APP_APP_KEY } });
  }
  const CALCULATED_DATA_URL = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_ACTIVITY}`;
  const url = `${CALCULATED_DATA_URL}?marketId=${marketId}&turnInPlayEnabled=${turnInPlayEnabled}`;
  return axios.get(url, { headers });
}

export const getChartRequest = (marketId) => {
  const headers = { 
    'X-Authentication': Cookies.get('ssoid'),
  };

  if (REACT_APP_MINIGRAPH_NEW_URL_ENABLED === 'true') {
    const url = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_MARKETS}/${marketId}${REACT_APP_DEFAULT_API_MINIGRAPH}`;
    return axios.get(url, { headers: { ...headers, 'X-Application': REACT_APP_APP_KEY } });
  }
  const url = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_MINIGRAPH}?filters=mi=${marketId}`;
  return axios.get(url, { headers });
}

export const getResultsRequest = (marketId) => {
  const headers = { 
    'X-Authentication': Cookies.get('ssoid'),
  };

  if (REACT_APP_RESULTS_NEW_URL_ENABLED === 'true') {
    const url = `${REACT_APP_DEFAULT_NEW_API}${REACT_APP_DEFAULT_API_MARKETS}/${marketId}${REACT_APP_DEFAULT_API_RESULTS}`;
    return axios.get(url, { headers: { ...headers, 'X-Application': REACT_APP_APP_KEY } });
  }

  const url = `${REACT_APP_DEFAULT_API}${REACT_APP_DEFAULT_OLD_API_RESULTS}/${marketId}`;
  return axios.get(url, { headers });
}

export const getEroRequest = (marketIds, filter = REACT_APP_MARKET_TABLE_API_REFRESH_FILTER) => {
  if (REACT_APP_ERO_NEW_URL_ENABLED === 'true') {
    const config = {
      headers: { 
        'X-Authentication': Cookies.get('ssoid'),
        'X-Application': REACT_APP_APP_KEY
      },
      withCredentials: true
    };
    const prefix = `${REACT_APP_DEFAULT_NEW_ERO_API}${REACT_APP_DEFAULT_API_ERO}?marketIds=${marketIds}`;
    const url = `${prefix}&_ak=nzIFcwyWhrlwYMrh&alt=JSON&currencyCode=AUD&locale=en&rollupLimit=5&rollupModel=STAKE&types=${filter}`;
    return axios.get(url, config);
  }
  
  const prefix = `${REACT_APP_DEFAULT_ERO_API}${REACT_APP_DEFAULT_OLD_API_ERO}`;
  const url = `${prefix}/www/sports/exchange/readonly/v1/bymarket?_ak=nzIFcwyWhrlwYMrh&alt=JSON&currencyCode=AUD&locale=en&marketIds=${marketIds}&rollupLimit=5&rollupModel=STAKE&types=${filter}`;
  return axios.get(url);
}