import React from 'react';

const EventIcon = props => {
  switch (props.eventTypeId) {
    case 'harness':
    case 'H':
      return <div className="icon-harness" />;
    case 7:
    case 'R':
    case 'horses':
      return <div className="icon-horse-racing" />;
    case 4339:
    case 'G':
    case 'greyhounds':
      return <div className="icon-greyhounds" />;
    default:
      return <div className="icon-close-thin" />;
  }
};

export default EventIcon;
