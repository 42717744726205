import React from 'react';

export const Priorities = {
  MOBILE_SMALLEST: 1,
  MOBILE_SMALL: 2,
  MOBILE_MEDIUM: 3,
  MOBILE_LARGE: 4,
  DESKTOP: 5,
}

export const MinWidthBreakpoints = {
  MOBILE_SMALLEST: 0,
  MOBILE_SMALL: 450,
  MOBILE_MEDIUM: 600,
  MOBILE_LARGE: 800,
  DESKTOP: 1025,
}

export const getPriorityForWindowWidth = width => {
  if (width >= MinWidthBreakpoints.DESKTOP) return Priorities.DESKTOP;
  if (width > MinWidthBreakpoints.MOBILE_LARGE) return Priorities.MOBILE_LARGE;
  if (width > MinWidthBreakpoints.MOBILE_MEDIUM) return Priorities.MOBILE_MEDIUM;
  if (width > MinWidthBreakpoints.MOBILE_SMALL) return Priorities.MOBILE_SMALL;
  return Priorities.MOBILE_SMALLEST;
}

/*
 * PriorityView component for showing/not showing children based on screen size
 */
export class PriorityView extends React.Component {
  render() {
    const { priority, maximum, currentPriority, children } = this.props;
    if(priority <= currentPriority && (!maximum || maximum >= currentPriority)) {
      return children;
    }
    return null;
  }
}
