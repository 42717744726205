/**
 * @description utility function to return copies of the object ie immutably.
 * @param {object} oldObject 
 * @param {object} updatedValues 
 * @returns {object} returns a copy of the objects
 */
export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues
  };
};

/**
 * @description filter Au and Nz countries so only other countries are returned
 * @param {string} country 
 * @returns {string} country
 */
export const filterAusNZ = (country) => {
  return country !== 'AU' && country !== 'NZ';
} 
export const filterOpenMarkets = markets => {
  return markets.status === 'OPEN' || markets.status === 'SUSPENDED' || markets.status === 'IN-PLAY';
}

export const filterClosedMarkets = markets => {
  return markets.status === 'CLOSED';
}
