import * as actionTypes from './actionTypes';

/**
 * @description action to set the filter in the store
 * @param {Object} filter
 */
export const setEventFilter = filter => ({
  type: actionTypes.SET_EVENT_FILTER,
  filter,
});

/*
 * pass filter toggles to eventFilters reducer
 */
export const updateFilter = filter => ({
  type: actionTypes.EVENT_FILTER_UPDATE,
  filter,
});
