import React from "react";
import PropTypes from "prop-types"

/**
 * @description auth dialog component. has 3 props. background image, show and size.. only small is in the css at the moment
 * @param {object} props
 */
const AuthDialog = props => {
  const { show, size, children, additionalClass } = props;
  const showHideClassName = show ? "display-block" : "display-none";

  return (
    <div className={`auth-dialog ${showHideClassName} ${additionalClass}`}>
      <section className={size}>{children}</section>
    </div>
  );
};

AuthDialog.defaultProps = {
  backgroundImage: null,
  show: true,
  size: null,
  children: null,
  additionalClass: ''
}

AuthDialog.propTypes = {
  backgroundImage: PropTypes.string,
  show: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.node,
  additionalClass: PropTypes.string
}

export default AuthDialog;
