import * as actionTypes from './actionTypes';
import { setEventFilter } from './eventFilterActions';
import { fetchInPlayAndStatusValues } from '../../components/utils/FetchMarketDataERO';

import { filterAusNZ } from '../utility';
import { getEventsRequest } from '../../request';

/**
 * @description action - sets all open markets/races in params
 * @param {Object[]} openMarkets
 * @returns  Object
 */
export const setOpenMarkets = (openMarkets, filter) => ({
  type: actionTypes.SET_OPEN_MARKETS,
  market: openMarkets,
  filter,
});

/**
 * @description makes the API calls to fetch events for each event type in filters
 * then merges them into one array and sorts by market time
 * @returns promise with array of events
 */
const fetchEventList = filters => {
  const request = getEventsRequest(filters);

  return request
    .then(response => response.data);
}

/**
 * @description action - retrieves the list of open markets for today. is used as the default call when the app first loads up
 * @async
 * @returns dispatches setOpenMarkets(openMarkets[]) action creator wtih an array of openMarkets
 */
export const fetchTodaysOpenMarketsStart = () => (dispatch, getState) => {
  const filters = getState().eventFilters;

  fetchEventList(filters)
    .then(data => dispatch(setOpenMarkets(data, filters)))
    .catch(err => console.log('fetchTodaysOpenMarkets error', err))
};

/**
 * @description action to set the horse racing countries, will send the countries to the reducer
 * @param {object} countries
 */
export const setHorseRaceCountries = countries => {
  const filteredCountries = countries.filter(filterAusNZ);
  return {
    type: actionTypes.SET_HORSE_RACE_COUNTRIES,
    filteredCountries,
  };
};

/**
 * @description action to set the greyhoud racing countries, will send the countries to the reducer
 * @param {object} countries
 //  */
export const setGreyhoundCountries = countries => {
  const filteredCountries = countries.filter(filterAusNZ);
  return {
    type: actionTypes.SET_GREYHOUND_COUNTRIES,
    filteredCountries,
  };
};

/**
 * @description action to retrieve list of open markets given the filters in the params
 *  will also set the country list in the store depending on the returned data
 * @async
 * @param {Object.<facets>} facets
 * @returns dispatches setOpenMarkets(openMarkets[])
 *
 */
export const fetchOpenMarkets = () => (dispatch, getState) => {
  const filters = getState().eventFilters;

  const getSnapshot = filters => filters.eventTypes.map(i => i.active).join();
  const initialSnapshot = getSnapshot(filters);

  dispatch(setIsFetchingNewMarketsFlag(true));

  fetchEventList(filters)
    .then(data => {

      if (initialSnapshot !== getSnapshot(getState().eventFilters)) {
        // the filter has been changed since the request was made, so don't update
        return;
      }

      // find the list of countries and place them in an object
      const countries = {};
      if (Array.isArray(data)) {
        data.forEach(market => {
          if (!countries[market.countryCode]) {
            countries[market.countryCode] = '1'
          }
        })
        // extract the keys and place in array
        const countriesArr = Object.keys(countries);
        const horseEventId = '7';
        const greyhoundEventId = '4339';

        // dispatch depending on the race type. only 2 race types either horse or greyhound
        if (filters.eventTypes.some(type => (type.active && type.eventId === horseEventId))) {
          dispatch(setHorseRaceCountries(countriesArr));
        }

        if (filters.eventTypes.some(type => (type.active && type.eventId === greyhoundEventId))) {
          dispatch(setGreyhoundCountries(countriesArr));
        }
      }

      dispatch(setIsFetchingNewMarketsFlag(false));
      dispatch(setEventFilter(filters));
      dispatch(setOpenMarkets(data, filters));
    })
    .catch(error => {
      dispatch(setIsFetchingNewMarketsFlag(false));
      console.log('some error in fetch open markets: ', error)
    });
};

/**
 * @description action to set the market data in the events. is used as the interval to ensure events with status 'open' and 'closed' are updated in the view
 * @param {object} markets
 * @param {object} currentSelection
 */
export const setIntervalOpenMarkets = (fetchedMarkets, currentSelection) => ({
  type: actionTypes.SET_INTERVAL_OPEN_MARKETS,
  fetchedMarkets,
  currentSelection,
});

/**
 * @description fetch the open markets and keeps the current selection in the store
 * @param {*} facets
 * @param {*} currentSelection
 */
export const fetchIntervalOpenMarkets = () => (dispatch, getState) => {
  const filters = getState().eventFilters;
  const currentSelection = getState().market.currentSelection;

  fetchEventList(filters)
    .then(data => {
      const filterSelectedStatus = getState().eventFilters.selectedStatus.id;

      if (filterSelectedStatus !== 'CLOSED') {
        dispatch(setIntervalOpenMarkets(data, currentSelection));
      }
    })
    .catch(error => console.log('some error in fetch open markets: ', error))
};

/**
 * @description get status/inplay for a list of markets
 */
export const fetchUpdatedInPlayStatuses = (marketIds) => dispatch => {
  //call ero
  fetchInPlayAndStatusValues(marketIds)
    //update market values with new inplay and status
    .then(res => {
      const markets = [];

      try {
        res.data.eventTypes.forEach(eventType => {
          eventType.eventNodes.forEach(node => {
            node.marketNodes.forEach(market => {
              if (market)
                markets.push(market);
            });
          });
        })
      }
      catch (e) {
        console.log('error updating inplay values');
      }

      dispatch({
        type: actionTypes.FETCH_UPDATED_INPLAY_STATUSES,
        markets: markets
      });
    });
}

/**
 * @description sets current selection for the market/event in the store
 * @param {object} market
 */
export const setCurrentSelection = (market, venueRaces, autoSelectNewRaceFlag) => dispatch => {
  dispatch({
    type: actionTypes.SET_CURRENT_SELECTION,
    currentSelection: market,
  });

  if (venueRaces) {
    dispatch({
      type: actionTypes.SET_CURRENT_VENUE_RACES,
      currentVenueRaces: venueRaces,
    })
  }

  if (autoSelectNewRaceFlag !== undefined) {
    dispatch({
      type: actionTypes.SET_AUTO_SELECT_NEW_RACE_FLAG,
      autoSelectNewRaceFlag: autoSelectNewRaceFlag,
    })
  }
};

export const setCurrentSelectionMarketInfo = headerInfo => ({
  type: actionTypes.SET_MARKET_HEADER,
  headerInfo,
});

/**
 * @description action to set the filter in the store
 * @param {Object} filter
 */
export const setIsFetchingNewMarketsFlag = isFetchingNewMarkets => ({
  type: actionTypes.SET_FETCHING_NEW_MARKETS,
  isFetchingNewMarkets,
});

export const setEROMarket = eroResponse => ({
  type: actionTypes.SET_ERO_MARKET,
  eroResponse,
});
