import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { map, orderBy, round } from 'lodash';
import * as actionCreators from '../../../store/actions';
import RunnerTableContent from './RunnerTableContent';
import ErrorHandler from '../../utils/ErrorHandler';
import { FetchMarketDataERO } from '../../utils/FetchMarketDataERO';
import { getPriorityForWindowWidth } from '../../utils/ViewPriority';
import getSortFunctionFromType from './runnerUtils/RunnerInfoSort';

import RunnerTableHeadersTooltips from './components/RunnerTableHeadersTooltips';
import RunnerTableHeaders from './components/RunnerTableHeaders';
import { TooltipValues } from './runnerUtils/RunnerTableConstants'

import { getAugmentRequest, getActivityRequest, getChartRequest } from '../../../request';
import LoadingDots from '../../LoadingDots';
import Analytics from '../../utils/Analytics';
import Responsive from '../../utils/Responsive';
import GeneralUtils from '../../utils/GeneralUtils';
import { Modal } from '../../utils/Modal';
import TradeContainer from '../chart/TradeContainer';
import TradeContainerSelector from '../../../store/selectors/TradeContainerSelector';

const { 
  REACT_APP_APP_KEY,
  REACT_APP_MINI_GRAPH_REFRESH_RATE,
  REACT_APP_MARKET_TABLE_CALCULATION_REFRESH_RATE,
  REACT_APP_MARKET_TABLE_REFRESH_RATE,
  REACT_APP_AUGMENT_KEYS
} = process.env;

class RunnerTable extends React.Component {
  constructor(props) {
    super(props);

    // initLoading is used for initial data fetch function
    const initLoading = true;
    const eventType = 0;
    const marketCountry = '';
    const runnerDescription = [];
    const exchange = [];
    const state = [];
    const marketStatus = '';
    const turnInPlayEnabled = false;
    const runnerIds = [];
    const calculated = {};
    const miniGraphArray = [];
    const totalMatched = 0;

    const backOddsSum = 0;
    const layOddsSum = 0;

    //below are three interval values for ERO, Calculation and MINI GRAPH API refreshing function
    const tableInterval = 0;
    const tableCalculatedInterval = 0;
    const tableMiniGraphInterval = 0;
    const augmentInterval = 0;

    this.state = {
      initLoading,
      tableInterval,
      shouldHide: false,
      bspToolTipMsg: TooltipValues.bspDefault,
      isError: false,
      tableCalculatedInterval,
      tableMiniGraphInterval,
      augmentInterval,
      eventType,
      marketCountry,
      runnerIds,
      runnerDescription,
      exchange,
      state,
      marketStatus,
      turnInPlayEnabled,
      calculated,
      miniGraphArray,
      totalMatched,
      backOddsSum,
      layOddsSum,
      sortType: '',
      sortDesc: true,
      runnerInfo: [],
      isMobileAndRotated: GeneralUtils.isMobileAndRotated(),
      showModal: false,
    };
  }

  /**
   *
   * @description check if the market table should update itself. Checking props is for the change
   * of current selected market, aka, new event selection. The change of state will be triggered
   * by market info changes
   */
  shouldComponentUpdate(nextProps, nextState) {
    const nextMarketId = nextProps.marketId;
    const marketId = this.props.marketId;
    const nextStateStr = JSON.stringify(nextState);
    const stateStr = JSON.stringify(this.state);
    return marketId !== nextMarketId || nextStateStr !== stateStr;
  }

  /**
   * @description when component did mount, set the mounted value to true (this is anti-pattern, but had no time to re-do).
   *
   *
   */
  componentDidMount() {
    const { marketId, sortContext } = this.props;
    const { sortType, sortDesc } = sortContext;

    this.mounted = true;
    // This is vital, the market id from redux would be default if user is not logged in. The table
    // should not render or making axios calls
    // And also if there is a valid market id and table is just initLoading, we include resize event listener.
    // This is used for resizing market grid for desktop view
    if (marketId !== 'default') {
      this.fetchInitData(marketId);
      this.refreshTable();

    }

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

    this.setState({ sortType, sortDesc });
  }

  /**
   * @description 1. when component will unmount, all existing refreshing intervals
   * should be removed and set initLoading to true, so that init data fetch can be called again.
   * The desktop resizing function should also be removed.
   */
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.state.tableInterval);
    clearInterval(this.state.tableCalculatedInterval);
    clearInterval(this.state.augmentInterval);
    clearInterval(this.state.tableMiniGraphInterval);

    window.removeEventListener('resize', this.updateDimensions);

    this.setState({
      initLoading: true,
      isError: false,
      tableInterval: 0,
      tableCalculatedInterval: 0,
      tableMiniGraphInterval: 0,
      augmentInterval: 0,
    });
  }

  /**
   * @description this function set shouldHide state so as to
   * toggle css classes on the mini graph dropdown
   *
   * @param selectedGraph it is just a string, WAP or TP,
   * @returns does not return anything, set component state inside
   */
  dropdownOnChange = selectedGraph => {
    if (selectedGraph === 'Weighted Avg. Price (WAP)') {
      this.setState({
        shouldHide: false,
      });
    } else
      this.setState({
        shouldHide: true,
      });

    Analytics.track(`RUNNER_TABLE_MINI_GRAPH_DROPDOWN_${selectedGraph}`);
  };

  /**
   * Update the view priority in state if the window changes size
   */
  updateDimensions = () => {
    const { viewPriority } = this.state;
    const calculatedPriority = getPriorityForWindowWidth(window.innerWidth);

    if (viewPriority !== calculatedPriority) {
      this.setState({
        viewPriority: calculatedPriority,
        isMobileAndRotated: GeneralUtils.isMobileAndRotated()
      });
    }
  };

  /**
   * @description the main ERO api refresh function
   */
  refreshTable = () => {
    let tableInterval = setInterval(() => {
      const { marketId } = this.props;
      this.fetchData(marketId);
    }, REACT_APP_MARKET_TABLE_REFRESH_RATE);
    this.setState({ isError: false, tableInterval });
  };

  fetchAugment = () => {
    const intervalAugment = setInterval(() => {
      const { marketId } = this.props;
      this.fetchAugmentData(marketId);
    }, REACT_APP_MARKET_TABLE_REFRESH_RATE);
    this.setState({ augmentInterval: intervalAugment });
  }

  fetchAugmentData = marketId => {
    const request = getAugmentRequest(marketId);

    request
      .then(res => {
        const augmentData = Object.keys(res.data || {}).reduce((p, c) => {
          const newObj = { ...res.data[c] }
          if (res.data[c] && res.data[c].B_TOTE && res.data[c].B_TOTE.toString().toLowerCase() === "none") {
            newObj.B_TOTE = null;
          }

          if (res.data[c] && res.data[c].GB_Fixed_Corp && res.data[c].GB_Fixed_Corp.toString().toLowerCase() === "none") {
            newObj.GB_Fixed_Corp = null;
          }

          const retObj = {
            ...p
          };
          retObj[c] = newObj;
          return retObj;
        }, {});

        if (this.mounted) {
          this.setState({ augmentData }, () => {
            const {
              runnerInfoCached,
              marketStatus,
              marketInPlay,
              sortType,
              sortDesc
            } = this.state;
            if (runnerInfoCached) {
              this.setState({ ...this.getSortedRunnerInfoData(runnerInfoCached, marketStatus, marketInPlay, sortType, sortDesc) })
            }
          });
        }
      })
      .catch(error => {
        console.log('an error occurred fetching table data', error);
        this.setState({ isError: true });
      });
  }

  /**
   * @description the main calculation api refresh function
   */
  refreshCalculated = () => {
    let intervalCalculated = setInterval(() => {
      const { marketId } = this.props;
      this.fetchCalculatedData(marketId);
    }, REACT_APP_MARKET_TABLE_CALCULATION_REFRESH_RATE);
    this.setState({ tableCalculatedInterval: intervalCalculated });
  };

  /**
   * @description Fetch calculation api
   * @param marketId
   */
  fetchCalculatedData = marketId => {
    const { turnInPlayEnabled } = this.state;
    const request = getActivityRequest(marketId, turnInPlayEnabled);
    request.then(res => {
      const calculated = res.data;

      if (this.mounted) {
        this.setState({ calculated });
      }
    }).catch(error => {
      console.log('an error occurred fetching table data', error);
      this.setState({ isError: true });
    });
  };

  /**
   * @description for closed market, the scratched runners for some reasons coming first, but we need them sitting in the last
   * this function will make a copy of sortPriority, and if it's scratched, the priority will be 99. And then the array will be sorted
   * based on the copy of sortPriority
   *
   * @param data array of runner objects
   *
   * @returns array
   */
  reOrderClosedMarket = data => {
    const tempData = data.map(runnerInfo => {
      const priority =
        runnerInfo.state.status === 'REMOVED'
          ? 99
          : runnerInfo.state.sortPriority;
      return Object.assign(runnerInfo, { sortPriority: priority });
    });
    const orderedData = orderBy(tempData, ['sortPriority'], ['asc']);
    return orderedData;
  };

  toggleSorting = sortType => {
    let sortDesc = true;

    if (this.state.sortType === sortType && this.state.sortDesc === true) {
      sortDesc = false;
    }

    const { runnerInfoCached, marketStatus, marketInPlay } = this.state;
    const { sortContextChanged } = this.props;

    this.setState({
      sortType,
      sortDesc,
      ...this.getSortedRunnerInfoData(runnerInfoCached, marketStatus, marketInPlay, sortType, sortDesc)
    });

    //update sort context on parent
    sortContextChanged(sortType, sortDesc);

    Analytics.track(`RUNNER_TABLE_SORT_${sortType}`);
  }

  /**
   * @description some sorting fields should sort by ascending order first
   * returns !direction for certain sort types
   */
  getCorrectSortDirection = (sortType, sortDesc) => {
    if (sortType === 'lay' || sortType === 'back') return !sortDesc;
    return sortDesc;
  }

  /**
   * @description returns the sorted runner table data
   */
  getSortedRunnerInfoData = (runnerInfo, marketStatus, marketInPlay, sortType, sortDesc) => {
    const { augmentData } = this.state;
    if (!sortType) sortType = this.state.sortType;
    if (sortDesc === null || sortDesc === undefined) sortDesc = this.state.sortDesc;

    runnerInfo = runnerInfo.map(i => {
      let newExchange = i.exchange;
      let impliedChanceToWin;

      if (newExchange.availableToBack && newExchange.availableToBack[0]) {
        impliedChanceToWin = round((1 / newExchange.availableToBack[0].price) * 100, 1);
      }

      newExchange = { ...newExchange, impliedChanceToWin };

      const augmentKeys = REACT_APP_AUGMENT_KEYS.split(',');
      const emptyAugment = augmentKeys.reduce((p, c) => {
        const newPrev = { ...p };
        newPrev[c] = undefined;
        return newPrev;
      }, {})

      return ({
        ...i,
        exchange: newExchange,
        augmentData: (i.state.status !== "REMOVED" && augmentData && augmentData[i.description.metadata.runnerId]) || emptyAugment,
      });
    });

    runnerInfo.sort(getSortFunctionFromType(sortType, this.getCorrectSortDirection(sortType, sortDesc), this.state.calculated));

    const runnerIds = map(runnerInfo, 'selectionId');
    const exchange = map(runnerInfo, 'exchange');
    const state = map(runnerInfo, 'state');
    const startingPrices = map(runnerInfo, 'sp');
    const runnerDescription = map(runnerInfo, 'description');
    const augmentSorted = map(runnerInfo, 'augmentData');
    let sp;

    if ((marketStatus === "OPEN" && marketInPlay) || marketStatus === "CLOSED") {
      sp = map(startingPrices, 'actualStartingPrice') || 'N/A';
    } else {
      sp = map(startingPrices, 'nearPrice') || 'N/A';
    }

    return {
      runnerIds,
      exchange,
      state,
      runnerDescription,
      sp,
      augmentSorted,
      marketInPlay,
      runnerInfoCached: runnerInfo,
    };
  }

  initialiseFetchDataIntervals = (marketId, marketStatus, marketInPlay, marketInfo) => {
    const { tableMiniGraphInterval, augmentInterval, tableCalculatedInterval, tableInterval } = this.state;

    // 1. Table gets total Matched amount from init data call, we only call the calculation and graph data apis
    // when it's larger than 0
    if (marketStatus === 'OPEN' && marketInPlay === false) {
      const { totalMatched } = marketInfo;

      // Fetch the Tote/Corp price
      if (augmentInterval === 0) {
        this.fetchAugmentData(marketId);
        this.fetchAugment();
      }

      if (totalMatched > 0) {
        if (tableMiniGraphInterval === 0) {
          this.fetchGraphData();
          this.refreshGraph();
        }
        if (tableCalculatedInterval === 0) {
          this.fetchCalculatedData(marketId);
          this.refreshCalculated();
        }
      } else {
        clearInterval(tableMiniGraphInterval);
        clearInterval(tableCalculatedInterval);

        this.setState({
          isError: false,
          tableCalculatedInterval: 0,
          tableMiniGraphInterval: 0,
        });
      }
    } else if (marketStatus === 'CLOSED') {
      clearInterval(tableInterval);
      clearInterval(tableCalculatedInterval);
      clearInterval(tableMiniGraphInterval);
      clearInterval(augmentInterval);
      this.fetchGraphData();
      this.fetchCalculatedData(marketId);
      this.fetchAugmentData(marketId);
    }
  }

  /**
   * @description Fetch ERO api, this is the regular call the table uses,
   * filters do not contain runner metadata to decrease data flow
   * @param marketId
   */
  fetchData = marketId => {
    FetchMarketDataERO(marketId, this.props.setCurrentSelectionMarketInfo)
      .then(res => { this.props.cacheEROData(res.data); return res; })
      .then(res => {
        if (this.mounted) {
          const eventType = res.data.eventTypes[0].eventTypeId;

          const eventNode = res.data.eventTypes[0].eventNodes[0];
          const marketNode = eventNode.marketNodes[0];

          const marketCountry = eventNode.event.countryCode;
          const marketInfo = marketNode.state;

          const marketStatus = marketInfo.status;
          const marketInPlay = marketInfo.inplay;

          const isMarketClosed = marketStatus === "CLOSED";
          let runnerInfo = isMarketClosed ?
            this.reOrderClosedMarket(marketNode.runners) : marketNode.runners;

          const sortedRunnerInfoData = this.getSortedRunnerInfoData(runnerInfo, marketStatus, marketInPlay, this.state.sortType, this.state.sortDesc);

          const bspToolTipMsg = 'Betfair Starting Price';

          let updatedState = {
            ...sortedRunnerInfoData,
            bspToolTipMsg,
          };

          // There is nothing fancy about the axios call, but we had to cope with Betfair Exchange API
          // The things need to be aware of
          // 2. When market is pre jump, the start price is nearPrice, while after jump it becomes actual starting price
          // This affects the tooltip for SP as well
          // 3. As the table is maintaining it's own state, but the market header (market container) needs to be synced
          // This function updates the currentSelection in the redux store, so markert container gets updated as well.
          // 4. When market status is suspended or in play, the whole grid becomes greyed out (0.5 opacity) and the pointer event
          // is disabled.
          // 5. When market is closed, all returned runner information is reversed, for example, the scratched runners come in
          // the end of the array and have lowest sort priority, but when it's closed, they come in first and have highest priorty
          // But betfair live needs us to always show scratched runners in the end.
          if (marketStatus === 'OPEN' && marketInPlay === false) {
            const totalMatched = marketInfo.totalMatched;

            updatedState = {
              ...updatedState,
              eventType,
              marketCountry,
              totalMatched,
              marketStatus: 'OPEN',
            };

            delete updatedState.bspToolTipMsg;

          } else if (marketStatus === 'OPEN' && marketInPlay === true) {
            updatedState.marketStatus = 'IN-PLAY';
          } else if (marketStatus === 'SUSPENDED') {
            updatedState.marketStatus = 'SUSPENDED';
          } else if (marketStatus === 'CLOSED') {
            updatedState.marketStatus = 'CLOSED';
          }

          this.setState(updatedState);
        }
      })
      .catch(error => {
        this.setState({ isError: true });

        console.log('an error occurred fetching table data', error);
      });
  };

  /**
   * @description Fetch ERO api, this is the initial call the of market table,
   * filters contain all the data table needs
   * @param marketId
   */
  fetchInitData = marketId => {

    FetchMarketDataERO(marketId, this.props.setCurrentSelectionMarketInfo)
      .then(res => { this.props.cacheEROData(res.data); return res; })
      .then(res => {
        if (this.mounted) {
          const { sortType, sortDesc } = this.state;

          const eventType = res.data.eventTypes[0].eventTypeId;

          const eventNode = res.data.eventTypes[0].eventNodes[0];
          const marketNode = eventNode.marketNodes[0];

          const marketCountry = eventNode.event.countryCode;
          const marketInfo = marketNode.state;

          const { turnInPlayEnabled } = marketNode.description;
          const marketStatus = marketInfo.status;
          const marketInPlay = marketInfo.inplay;

          const { totalMatched } = marketInfo;

          const isMarketClosed = marketStatus === "CLOSED";
          const runnerInfo = isMarketClosed ?
            this.reOrderClosedMarket(marketNode.runners) : marketNode.runners;

          const sortedRunnerInfoData = this.getSortedRunnerInfoData(runnerInfo, marketStatus, marketInPlay, sortType, sortDesc);

          this.initialiseFetchDataIntervals(marketId, marketStatus, marketInPlay, marketInfo);

          this.setState({
            runnerInfoCached: runnerInfo,
            ...sortedRunnerInfoData,
            eventType,
            marketCountry,
            totalMatched,
            turnInPlayEnabled,
            marketStatus,
            initLoading: false,
          });
        }
      })
      .catch(error => {
        this.setState({ isError: true });

        console.log('an error occurred fetching table initial data', error);
      });
  };

  fetchGraphData = () => {
    const request = getChartRequest(this.props.marketId);

    request.then(res => {
        if (this.mounted) {
          const miniGraphArray = res.data;
          this.setState({ miniGraphArray });
        }
      })
      .catch(error => {
        this.setState({ isError: true });
        console.error('an error occurred fetching graph data', error);
      });
  };

  refreshGraph = () => {
    const tableMiniGraphInterval = setInterval(() => {
      this.fetchGraphData();
    }, REACT_APP_MINI_GRAPH_REFRESH_RATE);
    this.setState({ tableMiniGraphInterval: tableMiniGraphInterval });
  };

  /**
   * @description For the back and lay total odds, we have been asked to do the calculation in the front end,
   * but it involves every single back and lay odds. This function is passed down to the runnerTableRow component
   * and then it returns the back and lay total odds.
   */
  setBackNLayMartketPercentage = (backOddsSum, layOddsSum) => {
    this.setState({ backOddsSum, layOddsSum });
  };

  renderMobileGraphModal() {
    const { showModal, modalSelectedRunnerId } = this.state;
    const {
      jumpTime,
      jumpZone,
      marketId,
      marketStatus,
      marketInPlay,
    } = this.props;

    return (
      <Modal
        open={showModal}
        onCancel={(e) => {
          e.stopPropagation();
          this.setState({ showModal: false });
        }}
        slideDirection="up"
      >
        <TradeContainer
          jumpTime={jumpTime}
          jumpZone={jumpZone}
          marketId={marketId}
          marketStatus={marketStatus}
          marketInPlay={marketInPlay}
          defaultRunner={(showModal /* force update on modal state change */) && modalSelectedRunnerId ? modalSelectedRunnerId : null}
        />
      </Modal>
    );
  }

  render() {
    const {
      eventType,
      marketCountry,
      marketStatus,
      shouldHide,
      runnerIds,
      runnerDescription,
      exchange,
      state,
      calculated,
      miniGraphArray,
      backOddsSum,
      layOddsSum,
      totalMatched,
      sp,
      initLoading,
      isError,
      sortType,
      sortDesc,
      augmentSorted,
      bspToolTipMsg,
      viewPriority,
      isMobileAndRotated,
    } = this.state;

    const { marketId } = this.props;
    const hasTote = augmentSorted && augmentSorted.some(v => v && v.B_TOTE);
    const hasFixed = augmentSorted && augmentSorted.some(v => v && v.GB_Fixed_Corp);
    const isMarketClosed = marketStatus === "CLOSED";

    if (initLoading && !isError) {
      return (<LoadingDots minHeight={500} />);
    }

    if (isError) {
      return (
        <div className="error-wrapper">
          <ErrorHandler />
        </div>
      );
    }

    const tableBody = (
      <tbody key={`${marketId}_table_body`} className="rt-body">
        <RunnerTableContent
          key={`${marketId}_runner_row`}
          shouldHide={shouldHide}
          marketCountry={marketCountry}
          eventType={eventType}
          marketId={marketId}
          runnerIds={runnerIds}
          runnerDescription={runnerDescription}
          exchange={exchange}
          miniGraphArray={miniGraphArray}
          sp={sp}
          totalMatched={totalMatched}
          calculated={calculated}
          state={state}
          marketStatus={marketStatus}
          setBackNLayMartketPercentage={
            this.setBackNLayMartketPercentage
          }
          augment={augmentSorted}
          viewPriority={viewPriority}
          isMobileAndRotated={isMobileAndRotated}
          onGraphClick={runnerId => this.setState({
            showModal: true,
            modalSelectedRunnerId: runnerId,
          })}
        />
      </tbody>
    );

    const table = (
      <div className="table-scroll">
        <Responsive desktop>
          <RunnerTableHeadersTooltips
            {...{ bspToolTipMsg, marketId, hasTote, hasFixed }}
          />
        </Responsive>
        <table
          key={`${marketId}_table`}
          className={`runner-table market-${marketStatus}`}
          cellSpacing="0"
          cellPadding="0"
        >
          <RunnerTableHeaders
            {
            ...{
              marketId,
              marketStatus,
              sortType,
              sortDesc,
              backOddsSum,
              layOddsSum,
              hasTote,
              hasFixed,
              bspToolTipMsg,
              isMarketClosed,
              viewPriority
            }
            }
            toggleSorting={type => this.toggleSorting(type)}
            dropdownOnChange={this.dropdownOnChange}
          />
          {tableBody}
        </table>

        {this.renderMobileGraphModal()}
      </div>
    );

    return table;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentSelectionMarketInfo: marketInfo => {
      dispatch(actionCreators.setCurrentSelectionMarketInfo(marketInfo));
    },
    cacheEROData: eroResponse => {
      dispatch(actionCreators.setEROMarket(eroResponse));
    },
  };
};

export default connect(
  state => ({ ...TradeContainerSelector(state) }),
  mapDispatchToProps
)(RunnerTable);
