import React from 'react';
import GeneralUtils from '../../utils/GeneralUtils';
import Analytics from '../../utils/Analytics';

const addDataLayer = (marketId) => {
  // add logout to ga dataLayer object
  const gaObj = {
    label: 'Back and Lay odds',
    value: marketId
  };
  GeneralUtils.addToDataLayer(gaObj);

  Analytics.track('RUNNER_TABLE_BACK_LAY_ODDS_LINK');
}

const RunnerTableFigureCell = props => {
  const { firstRow, secondRow, marketId, className } = props;
  if (marketId && secondRow && firstRow) {
    return (
      <td className={className}>
        <a
          href={`https://www.betfair.com.au/dl/${marketId}`}
          target="_blank"
          className="rt-exchangeDeepLink"
          onClick={() => addDataLayer(marketId)}
          >
          <div className="rt-two-rows-center">
            <span className="rt-text-bold rt-two-rows-item">{firstRow}</span>
            <span className="rt-two-rows-item">{secondRow}</span>
          </div>
        </a>
      </td>
    );
  }
  if (secondRow && firstRow) {
    return (
      <td className={className}>
        <div className="rt-two-rows-center">
          <span className="rt-text-bold rt-two-rows-item">{firstRow}</span>
          <span className="rt-two-rows-item">{secondRow}</span>
        </div>
      </td>
    );
  }
  if (marketId && !secondRow) {
    return (
      <td className={className}>
        <div className="rt-one-row-center" >
          <a href={`https://www.betfair.com.au/dl/${marketId}`} target="_blank" className="rt-exchangeDeepLink">
            {firstRow}
          </a>
        </div>
      </td>
    );
  }
  return (
    <td className={className}>
      <div className="rt-one-row-center">{firstRow}</div>
    </td>
  );
};

export default RunnerTableFigureCell;
