import React from 'react';
import RunnerSortIcon from '../runnerUtils/RunnerSortIcon';
import RunnerTableDropDown from '../RunnerTableDropDown';
import Responsive from '../../../utils/Responsive';
import { Priorities, PriorityView } from '../../../utils/ViewPriority';

import { TooltipValues, RowPriorities } from '../runnerUtils/RunnerTableConstants';
import RunnerTableLayHeader from './RunnerTableLayHeader';
import RunnerTableBackHeader from './RunnerTableBackHeader';

export default props => {
  const {
    marketId,
    marketStatus,
    sortType,
    sortDesc,
    backOddsSum,
    layOddsSum,
    hasTote,
    hasFixed,
    toggleSorting,
    dropdownOnChange,
    bspToolTipMsg:
    bsp,
    isMarketClosed,
    viewPriority,
  } = props;

  const {
    volumeMin,
    betsMin,
    wap,
    matched,
    ltp,
    wom,
    available,
    impliedToWin,
    tote,
    fixedCorp
  } = TooltipValues;

  const HEADER_TITLE = (
    isMarketClosed ? (
      <th><span className="rt-header-title" /></th>
    ) : null
  );

  const RUNNER = (
    <PriorityView priority={RowPriorities.RUNNER} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('runner')}>
        <span className="rt-header-title-left">
          <div className="rt-header-title-text">
            Runner (Barrier)
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'runner'}
              desc={sortDesc} />
          </div>
        </span>
      </th>
    </PriorityView>
  );

  const DROPDOWN = (
    <PriorityView priority={RowPriorities.MINI_GRAPH} currentPriority={viewPriority}>
         <th><RunnerTableDropDown dropdownOnChange={dropdownOnChange} /></th>
    </PriorityView>
  );

  const BACK = (
    viewPriority === Priorities.MOBILE_SMALLEST && isMarketClosed ? null : (
      <PriorityView priority={RowPriorities.BACK} currentPriority={viewPriority}>
        <RunnerTableBackHeader
          marketStatus={marketStatus}
          sortType={sortType}
          sortDesc={sortDesc}
          backOddsSum={backOddsSum}
          toggleSorting={toggleSorting}
          isMarketClosed={isMarketClosed}
          viewPriority={viewPriority}
        />
      </PriorityView>
    )
  );

  const LAY = (
    viewPriority === Priorities.MOBILE_SMALLEST && isMarketClosed ? null : (
      <PriorityView priority={RowPriorities.LAY} currentPriority={viewPriority}>
        <RunnerTableLayHeader
          marketStatus={marketStatus}
          sortType={sortType}
          sortDesc={sortDesc}
          layOddsSum={layOddsSum}
          toggleSorting={toggleSorting}
          isMarketClosed={isMarketClosed}
          viewPriority={viewPriority}
        />
      </PriorityView>
    )
  );

  const WOM = (
    <PriorityView currentPriority={viewPriority} priority={RowPriorities.WOM}>
      <th className="clickable">
        <a
          data-for={`${marketId}_table_wom`}
          data-tip={wom}>
          <span className="rt-header-title">WOM</span>
        </a>
      </th>
    </PriorityView>
  );

  const BSP = (
    !isMarketClosed && viewPriority === Priorities.MOBILE_SMALLEST ? null : (
      <PriorityView priority={RowPriorities.BSP} currentPriority={viewPriority}>
        <th className="clickable">
          <a
            data-for={`${marketId}_table_bsp`}
            data-tip={bsp}>
            <span className="rt-header-title">BSP</span>
          </a>
        </th>
      </PriorityView>
    )
  );

  const IMPLIED = (
    <PriorityView priority={RowPriorities.IMPLIED} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('impliedChanceToWin')}>
        <a
          data-for={`${marketId}_table_impliedToWin`}
          data-tip={impliedToWin}>
          
          <Responsive mobile>
            <div >%W</div>
          </Responsive>

          <Responsive desktop>
            <span className="rt-header-title">
              %W
              <RunnerSortIcon
                display={marketStatus !== 'CLOSED'}
                isSelected={sortType === 'impliedChanceToWin'}
                desc={sortDesc} />
            </span>
          </Responsive>
        </a>
      </th>
    </PriorityView>
  );

  const TOTAL_MATCHED = (
    <PriorityView priority={RowPriorities.TOTAL_MATCHED} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('totalMatched')}>
        <a
          data-for={`${marketId}_table_matched`}
          data-tip={matched}>
          <Responsive mobile>
                <span className="rt-header-title">
                MB
                <RunnerSortIcon
                  display={marketStatus !== 'CLOSED'}
                  isSelected={sortType === 'totalMatched'}
                  desc={sortDesc} />
                </span>
          </Responsive>
          <Responsive desktop>
              <span className="rt-header-title">
                MB
                <RunnerSortIcon
                  display={marketStatus !== 'CLOSED'}
                  isSelected={sortType === 'totalMatched'}
                  desc={sortDesc} />
              </span>
          </Responsive>
        </a>
      </th>
    </PriorityView>
  );

  const AVAILABLE = (
    <PriorityView priority={RowPriorities.AVAILABLE} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('available')}>
        <a
          data-for={`${marketId}_table_available`}
          data-tip={available}>
          <span className="rt-header-title">
            AVL
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'available'}
              desc={sortDesc} />
          </span>
        </a>
      </th>
    </PriorityView>
  );

  const LAST_PRICE_TRADED = (
    <PriorityView priority={RowPriorities.LAST_PRICE_TRADED} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('lastPriceTraded')}>
        <a
          data-for={`${marketId}_table_LTP`}
          data-tip={ltp}>
          <span className="rt-header-title">
            LTP
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'lastPriceTraded'}
              desc={sortDesc} />
          </span>
        </a>
      </th>
    </PriorityView>
  );

  const WAP = (
    <PriorityView priority={RowPriorities.WAP} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('wap')}>
        <a
          data-for={`${marketId}_table_wap`}
          data-tip={wap}>
          <span className="rt-header-title">
            WAP
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'wap'}
              desc={sortDesc} />
          </span>
        </a>
      </th>
    </PriorityView>
  );

  const TOTE = (
    <PriorityView priority={RowPriorities.TOTE} currentPriority={viewPriority}>
      {
        hasTote ? (
          <th className="clickable" onClick={() => toggleSorting('tote')}>
            <a
              data-for={`${marketId}_table_tote_price`}
              data-tip={tote}>
              <span className="rt-header-title">
                BT
                <RunnerSortIcon
                  display={marketStatus !== 'CLOSED'}
                  isSelected={sortType === 'tote'}
                  desc={sortDesc} />
              </span>
            </a>
          </th>
        ) : null
      }
    </PriorityView>
  );

  const FIXED = (
    <PriorityView priority={RowPriorities.FIXED} currentPriority={viewPriority}>
      {
        hasFixed ? (
          <th className="clickable" onClick={() => toggleSorting('fixed')}>
            <a
              data-for={`${marketId}_table_fixed`}
              data-tip={fixedCorp}>
              <span className="rt-header-title">
                Avg. Corp Fixed
                <RunnerSortIcon
                  display={marketStatus !== 'CLOSED'}
                  isSelected={sortType === 'fixed'}
                  desc={sortDesc} />
              </span>
            </a>
          </th>
        ) : null
      }
    </PriorityView>
  );

  const BPM = (
    <PriorityView priority={RowPriorities.BPM} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('bpm')}>
        <a
          data-for={`${marketId}_table_Bets/min`}
          data-tip={betsMin}>
          <span className="rt-header-title">
            Bmin
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'bpm'}
              desc={sortDesc} />
          </span>
        </a>
      </th>
    </PriorityView>
  );

  const VPM = (
    <PriorityView priority={RowPriorities.VPM} currentPriority={viewPriority}>
      <th className="clickable" onClick={() => toggleSorting('vpm')}>
        <a
          data-for={`${marketId}_table_Volume/min`}
          data-tip={volumeMin}>
          <span className="rt-header-title">
            Vmin
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'vpm'}
              desc={sortDesc} />
          </span>
        </a>
      </th>
    </PriorityView>
  );

  return (
    <thead id="rt-thead">
      <tr className="rt-header">
        { HEADER_TITLE }
        { RUNNER }
        { DROPDOWN }
        { WOM }
        { BACK }
        { BSP }
        { LAY }
        { IMPLIED }
        { TOTAL_MATCHED }
        { AVAILABLE }
        { LAST_PRICE_TRADED }
        { WAP }
        { TOTE }
        { FIXED }
        { BPM }
        { VPM }
      </tr>
    </thead>
  )
}
