import React from 'react';
import PropTypes from 'prop-types';
import Countdown from '../utils/Countdown';
import EventIcon from '../utils/EventIcon';

class RaceItem extends React.Component {

  shouldComponentUpdate(nextProps) {
    const { marketId, status, currentSelection, inplay } = this.props;

    if (
      nextProps.marketId !== marketId ||
      nextProps.currentSelection.marketId !== currentSelection.marketId ||
      nextProps.inplay !== inplay ||
      nextProps.status !== status) {
      return true;
    }

    return false;
  }

  render() {
    const {
      marketId,
      eventId,
      eventTypeId,
      marketTime,
      status,
      currentSelection,
      inplay,
      selectRaceCallBack,
      countryCode,
      raceCode,
      raceNumber,
      venue
    } = this.props;

    const currentSelectionId = currentSelection.eventId + currentSelection.marketId;
    const currentMarket = eventId + marketId;
    const isSelectedRace = currentSelectionId === currentMarket;

    // const marketTimeDisplay = `${moment(moment.utc(marketTime)).local().format('HH:mm')}`;

    // check if the currentMarket is currentSelection in the store, if it is, send the currentSelection
    // status and inPlay to the Countdown component,
    if (isSelectedRace) {
      this.countdown = (
        <Countdown
          eventTime={marketTime}
          eventStatus={currentSelection.status}
          eventInPlay={currentSelection.inPlay}
        />
      );
    } else {
      this.countdown = (
        <Countdown
          eventTime={marketTime}
          eventStatus={status}
          eventInPlay={inplay}
        />
      );
    }

    return (
      <div
        className={`race-item${isSelectedRace ? ' selected' : ''}`}
        onClick={() => selectRaceCallBack(this.props)}
      >
          <div className="top-container">
            <div className="race-item-image">
              <EventIcon eventTypeId={raceCode || eventTypeId} />
            </div>
            <div className="race-item-time">
              {this.countdown}
            </div>
          </div>
          <div>
            <div className="race-item-name">
              { `${venue} (${countryCode})` }
            </div>
            <div className="race-item-number">
              { `Race ${raceNumber}` }
            </div>
          </div>
      </div>
    )
  }
}

RaceItem.defaultProps = {
  inplay: undefined,
  raceCode: undefined,
}

RaceItem.propTypes = {
  raceNumber: PropTypes.number.isRequired,
  selectedRace: PropTypes.string.isRequired,
  currentSelection: PropTypes.shape({
    countryCode: PropTypes.string,
    eventId: PropTypes.number,
    eventTypeId: PropTypes.number,
    inPlay: PropTypes.bool,
    inplay: PropTypes.bool,
    marketId: PropTypes.string,
    marketName: PropTypes.string,
    marketTime: PropTypes.string,
    raceNumber: PropTypes.number,
    region: PropTypes.string,
    status: PropTypes.string,
    timezone: PropTypes.string,
    totalMatched: PropTypes.number,
    venue: PropTypes.string,
  }).isRequired,
  inplay: PropTypes.bool,
  raceCode: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  marketId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  selectRaceCallBack: PropTypes.func.isRequired,
  eventTypeId: PropTypes.number.isRequired,
  venue: PropTypes.string.isRequired,
  marketTime: PropTypes.string.isRequired
};

export default (RaceItem);
