import Constants from '../Constants';

/* eslint-disable no-console */
class GeneralUtils {
  /**
   * Generate event key
   * @param eventId
   * @param marketId
   * @returns {string}
   */
  static getEventInfoKey (eventId, marketId) {
    return eventId+marketId;
  };


  /**
   * @description add param to the google dataLayer object
   * @param {object} gaObj 
   */
  static addToDataLayer(gaObj){

    const mergedObj = {
      event: 'ga_event',
      category: 'BetfairLive',
      action: 'Click',
      label: gaObj.label,
      value: gaObj.value
    };
    window.dataLayer.push(mergedObj);
  }

  /**
   * @description pass into an array filter method to filter only the open or suspended markets
   * @function
   * @param {*} markets 
   */
  static filterOpenMarkets(markets) {
    return markets.status === 'OPEN' || markets.status === 'SUSPENDED' || markets.status === 'IN-PLAY';
  }
  
  /**
   * @description pass into an array filter to filter only closed markets
   * @function
   * @param {*} markets 
   */
  static filterClosedMarkets(markets) {
    return markets.status === 'CLOSED';
  }

  /**
   * Sorting of closed races are done by the marketTime
   */
  static closedRaceCompare = ({ marketTime: a }, { marketTime: b }) => {
    if (a > b) return -1;
    if (b > a) return 1;
    return 0;
  }

  /**
   * uses a width breakpoint to determine if the device is mobile or not
   */
  static isMobile = () => {
    const width = window.innerWidth;

    const isMobile = width < Constants.responsiveDesktopWidthBreakpoint;

    return isMobile;
  }

  /**
   * Uses a hacky way of checking whether the device is rotated
   */
  static isMobileAndRotated = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (!GeneralUtils.isMobile()) return false;

    const isWidthGreaterThanHeight = width > height;
    return isWidthGreaterThanHeight;
  }

  /*
   * https://stackoverflow.com/a/9851769
   * return true if the current browser is Internet Explorer 6-11
   */
  static isInternetExplorer() {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    return isIE;
  }
}

export default GeneralUtils;
