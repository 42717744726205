import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RunnerTable from './table/RunnerTable';

/**
 * This component displays the market data for a selected event
 */
class MarketContainer extends React.PureComponent {
  state = {
    sortContext: {
      sortType: '',
      sortDesc: true,
    }
  };

  sortContextChanged = (sortType, sortDesc) => {
    this.setState({
      sortContext: { sortType, sortDesc }
    });
  }

  render() {
    const { sortContext } = this.state;
    const { marketId } = this.props;

    if (!marketId) {
      return <div> Table is loading </div>;
    }

    return (
      <div className="market-container">
        <div className="marketContainer-body">
          <RunnerTable
            key={`market_container_${marketId}_runnerTable`}
            marketId={marketId}
            sortContext={sortContext}
            sortContextChanged={this.sortContextChanged}
          />
        </div>
      </div>
    );
  }
}

MarketContainer.defaultProps = {
  marketId: undefined,
}

MarketContainer.propTypes = {
  marketId: PropTypes.string,
}

const mapStateToProps = state => ({
  marketId: state.market.currentSelection.marketId,
});

export default connect(mapStateToProps)(MarketContainer);
