import React from 'react';
import ReactTooltip from 'react-tooltip';

import { TooltipValues } from '../runnerUtils/RunnerTableConstants';

export default props => {
  const { marketId, bspToolTipMsg: bsp, hasTote, hasFixed } = props;

  const { volumeMin, betsMin, wap, matched, ltp, wom, available, impliedToWin, tote, fixedCorp } = TooltipValues;

  const renderTooltip = i => ( i.display === false ? null :
    <ReactTooltip
      key={`react_tooltip_${i.table}`}
      id={`${marketId}_table_${i.table}`}
      className="rt-toolTip-grey"
      html
    >
      {i.content}
    </ReactTooltip>
  );

  const tooltips = [
    { table: 'bsp', content: bsp },
    { table: 'wom', content: wom },
    { table: 'impliedToWin', content: impliedToWin },
    { table: 'matched', content: matched },
    { table: 'LTP', content: ltp },
    { table: 'wap', content: wap },
    { table: 'tote_price', content: tote, display: hasTote },
    { table: 'fixed', content: fixedCorp, display: hasFixed },
    { table: 'Volume/min', content: volumeMin },
    { table: 'Bets/min', content: betsMin },
    { table: 'available', content: available },
  ]

  return (
    <div className="rt-tooltip">
      {tooltips.map(renderTooltip)}
    </div>
  )
}
