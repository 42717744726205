import React from 'react';


/**
 * @description hightlight the state in the dropdown if it's in facets.ausRegions
 * @param {string[]} propsAusRegions
 * @param {string} regionId
 * @returns {(string|null)} selectedState
 */
export const ausSelectedStates = (propsAusRegions, regionId) => {

  const selectedState = propsAusRegions.filter(state => state === regionId);
  if (Array.isArray(selectedState) && selectedState.length > 0) {
    return selectedState;
  }
}

const itemIsInList = (regions, item) => {
  const index = regions.findIndex(i => i === item.id);
  return index > -1;
}

const AusRegionDropdown = props => {

  return (
    <div>
      <div
        className={'region-dropdown'}
        onClick={props.dropdownClick}
      >
        <div className={'text'}>FILTER BY STATE</div>
        <div className={'region-icon'} />
      </div>
      {props.showRegionList && (
        <div
          className={'region-list'}
        >
          <div className={'region-list-triangle'} />
          <div className={'events-header'}>
            <div className='flex-3'>FILTER BY STATE</div>
            <div
              className='flex-1 icon-close-thin padding-right-10 text-align-right'
              onClick={props.closeStateDropdown}></div></div>
          <div className={'region-sub-head'}>SELECT A STATE </div>

          <div>
            {props.ausRegions.map(region => {
              return (
                <div
                  className={
                    ((props.selectedRegion) && (itemIsInList(props.selectedRegion, region)))
                      ? 'region-selected'
                      : 'region '
                  }
                  key={region.id}
                  onClick={() => props.regionClick(region)}
                >
                  <div className={'region-circle'} />
                  {region.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  )

}

export default AusRegionDropdown;
