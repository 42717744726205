import React from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";

import GeneralUtils from "./utils/GeneralUtils";
import Analytics from "./utils/Analytics";
import LogoImg from '../assets/images/BetfairLogo.svg';

class Header extends React.Component {
  /**
   * logout from betfair live and add the click to the ga dataLayer object
   */
  logout = () => {
    // add logout to ga dataLayer object
    const gaObj = {
      label: "Header",
      value: "Logout"
    };
    GeneralUtils.addToDataLayer(gaObj);
    Analytics.track("HEADER_LINK_LOGOUT");

    Cookies.remove("ssoid", {
      domain: process.env.REACT_APP_LOGOUT_DOMAIN,
      path: "/"
    });
    document.forms.logout.submit();
  };

  render() {
    return (
      <div className="header">
        <img className="header-logo" src={LogoImg} alt="betfair-logo" />
        <div className="header-link-items">
          <a
            onClick={() => Analytics.track("HEADER_LINK_EXCHANGE")}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.betfair.com.au"
          >
            EXCHANGE
          </a>
          <a
            onClick={() => Analytics.track("HEADER_LINK_THE_HUB")}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.betfair.com.au/hub/"
          >
            THE HUB
          </a>
          <a
            onClick={() => Analytics.track("HEADER_LINK_HELP")}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.betfair.com.au/hub/help/"
          >
            HELP
          </a>
          <form id="logout" action={process.env.REACT_APP_LOGOUT_URL}>
            <input type="hidden" name="product" value="betfair-live" />
            <input type="hidden" name="redirectMethod" value="POST" />
            <input
              type="hidden"
              name="url"
              value={process.env.REACT_APP_LOGOUT_REDIRECT_URL}
            />
            <div onClick={this.logout} className="header-logout-button">
              <div className="header-button-text">LOGOUT</div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Header);
