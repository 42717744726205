import React from "react";
import Regs from "./Regs";


const Footer = () => (
  <div className="footer-container">
    <div className="footer-content-container">
      <Regs />
    </div>
  </div>
);

export default Footer;
