import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';

const day = 86400000;
const hour = day / 24;
const minute = hour / 60;
const eightHours = hour * 8;
const tenMins = minute * 10;
const fourMins = minute * 4;

export default class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { remaining: 0, remainingClass: '' };

    momentDurationFormat(moment);
  }

  componentDidMount() {
    this.statusDecision();
  }

  componentDidUpdate(prevProps) {
    const { eventStatus, eventInPlay } = this.props;

    if (
      prevProps.eventStatus !== eventStatus ||
      prevProps.eventInPlay !== eventInPlay
    ) {
      clearInterval(this.interval);
      this.statusDecision();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  statusDecision = () => {
    const { eventStatus, eventInPlay } = this.props;
    // console.log(eventStatus);
    if (eventInPlay && eventStatus.toUpperCase() !== 'CLOSED' && eventStatus.toUpperCase() !== 'SUSPENDED') {
      this.setState({
        remaining: 'IN-PLAY',
        remainingClass: this.calcStatusStyle('IN-PLAY')
      });
    } else if (eventStatus && eventStatus.toUpperCase() !== 'OPEN') {
      this.setState({
        remaining: eventStatus,
        remainingClass: this.calcStatusStyle(eventStatus)
      });
    } else if (eventStatus && eventStatus.toUpperCase() === 'OPEN') {

      const updateTimer = () => {
        const { eventTime } = this.props;

        const gmtDate = moment.utc(eventTime);
        const localEventime = moment(gmtDate).local();
        const diff = localEventime.diff(moment());

        const { remaining, remainingClass } = this.state;
        const newRemaining = this.calcTimeFormat(diff);
        const newRemainingClass = this.calcTimeStyle(diff);

        if (remaining !== newRemaining || remainingClass !== newRemainingClass) {
          this.setState({
            remaining: newRemaining,
            remainingClass: newRemainingClass,
          });
        }
      }

      // update immediately, then execute on an interval
      updateTimer();
      this.interval = setInterval(() => {
        updateTimer();
      }, 1000);
    }
  }

  calcStatusStyle = (eventStatus) => {
    switch (eventStatus.toUpperCase()) {
      case 'CLOSED':
        return 'jump-closed';
      case 'SUSPENDED':
        return 'jump-suspended';
      case 'IN-PLAY':
        return 'jump-in-play';
      default:
        return '';
    }
  }

  calcTimeStyle = (diff) => {
    if (diff > tenMins) {
      return 'jump-time-ok';
    }
    if (diff >= fourMins && diff < tenMins) {
      return 'jump-time-warning';
    }
    if (diff >= 0 && diff < fourMins) {
      return 'jump-time-alert';
    }
    if (diff < 0) {
      return 'jump-time-alert';
    }
    return '';
  }

  calcTimeFormat = (diff) => {
    if (diff >= day && diff < day * 2) {
      return `${moment.duration(diff).format('D')} day`;
    }

    if (diff > day) {
      return `${moment.duration(diff).format('D')} days`;
    }

    if (diff < day && diff > eightHours) {

      const roundedDuration = moment.duration(diff).format('H', 1);

      if (roundedDuration  >= 23.5) {
        return '1 day';
      }
      
      if (roundedDuration > 23 && roundedDuration < 23.5) {
        return '23 h';
      }

      return `${moment.duration(diff).format('H')} h`;
    }

    if (diff <= eightHours && diff > hour) {
      return `${moment.duration(diff).format('H[h] m[m]')}`;
    }

    if (diff <= hour && diff > fourMins) {
      return `${moment.duration(diff).format('m[m]')}`;
    }

    if (diff <= fourMins && diff >= 0) {
      return `${moment.duration(diff).format('m[m] ss[s]')}`;
    }

    if (diff < 0) {
      // the race start is over-running the official start time
      return `${moment.duration(diff).format('H[h] m[m] s[s]')}`;
    }

    return '';
  }

  render() {
    const { remaining, remainingClass } = this.state;
    if (remaining) {
      return (
        <div className={remainingClass}>
          {remaining}
        </div>
      );
    }
    return <div />;
  }
}
Countdown.defaultProps = {
  eventTime: '',
  eventStatus: '',
  eventInPlay: false,
};


Countdown.propTypes = {
  eventTime: PropTypes.string,
  eventStatus: PropTypes.string,
  eventInPlay: PropTypes.bool,
};
