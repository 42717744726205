import React from 'react';

const DaysFilters = props => {
  const { eventDays, currentSelection, selectDay } = props;

  return (
      <div className="day-button-container">
        {
          eventDays.map(day => (
            <div
              key={day.id}
              className={
                currentSelection.id === day.id
                  ? "button-event-day selected"
                  : "button-event-day"
              }
              onClick={() => selectDay(day)}
            >
              {day.label}
            </div>
          ))
        }
      </div>
  );
}

export default DaysFilters;
