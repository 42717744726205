/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

const IntlDropdown = (props) => {
  const {
    greyhoundCountries,
    horseCountries,
    dropdownClick,
    selectedRegion,
    showRegionList,
    regionClick,
  } = props;

  // get distinct values from both horse and greyhound arrays
  const showCountries = [...new Set(horseCountries.concat(greyhoundCountries))];

  return (
    <div>
      <div className="region-dropdown" onClick={dropdownClick}>
        <div className="text">FILTER BY REGION</div>
        <div className="region-icon" />
      </div>

      {showRegionList && (
        <div className="region-list" onClick={dropdownClick}>
          <div className="region-list-triangle" />
          <div className="events-header">
            <div className='flex-3'>FILTER BY REGION</div>
            <div className='flex-1 icon-close-thin padding-right-10 text-align-right' />
          </div>
          <div className="region-sub-head">SELECT A COUNTRY </div>

          {
            showCountries.sort().map(country => (
              <div
                className={(selectedRegion === country) ? 'region-selected' : 'region'}
                key={country}
                onClick={() => regionClick(country)}
              >
                <div className="region-circle" />
                {country}
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  filters: state.eventFilters,
});

IntlDropdown.defaultProps = {
  filters: {},
  greyhoundCountries: null,
  horseCountries: null,
  dropdownClick: () => null,
  selectedRegion: null,
  showRegionList: false,
  regionClick: () => null,
};

IntlDropdown.propTypes = {
  filters: PropTypes.shape({
    ausStates: PropTypes.array,
    country: PropTypes.string,
    endDate: PropTypes.string,
    eventType: PropTypes.number,
    raceCode: PropTypes.string,
    selectedDay: PropTypes.object,
    startDate: PropTypes.string,
    status: PropTypes.object,
  }),
  greyhoundCountries: PropTypes.arrayOf(PropTypes.string),
  horseCountries: PropTypes.arrayOf(PropTypes.string),
  dropdownClick: PropTypes.func,
  selectedRegion: PropTypes.string,
  showRegionList: PropTypes.bool,
  regionClick: PropTypes.func,
};

export default connect(mapStateToProps)(IntlDropdown);
