import { round } from 'lodash';

class RunnerTableRowUtil {

  /**
   * @description for prejump and sp above $10, price to 1 decimal places and rounding to the nearest .10 (cents), rounding down at 0.05
   *   .eg. 17.75 -> 17.7
   * @param value price to round
   * @returns  float
   */
  static getFlooredFixedToOneDecimal = value => {
    const decimal = 1;
    return (
      Math.floor(value * Math.pow(10, decimal)) / Math.pow(10, decimal)
    ).toFixed(decimal);
  };

  /**
   * @description after jump and prejump below 10, up to 2 decimal places, no rounding
   * @param value price to round
   * @returns  string
   */
  static bspRoundDown = value => {
    const decimal = 2;
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimal || -1) + '})?');

    const result = value.toString().match(re);

    if (!result) return null;

    return result[0];
  };

  /**
   * @description Calculating sum back and lay odds percentage based on the betfair
   * 
   */
  static setMarketBackAndLayPercentage = (exchange, state, setBackNLayMartketPercentage) => {
    let laySum = 0;
    let backSum = 0;

    exchange.forEach((item, index) => {
      const runnerState = state[index];

      const { availableToBack, availableToLay } = item;
      if (runnerState.status === 'ACTIVE') {
        if (availableToBack && availableToBack[0].price) {
          let bestBackTemp = 0;
          if (availableToBack[0].price < 1) {
            bestBackTemp = 1;
            backSum += 100 / bestBackTemp;
          } else backSum += 100 / availableToBack[0].price;
        } else {
          backSum += 100 / 1;
        }

        if (availableToLay) {
          let bestLayTemp = 0;
          if (availableToLay[0].price < 1) {
            bestLayTemp = 100;
            laySum += 100 / bestLayTemp;
          } else laySum += 100 / availableToLay[0].price;
        } else {
          laySum += 100 / 100;
        }
      }
    });

    setBackNLayMartketPercentage(
      `${round(backSum, 0)}%`,
      `${round(laySum, 0)}%`,
    );
  };

  /**
   * @description takes a object and key and format according to conditions
   * @param calculatedList the object contains data to retrieve
   * @param key the key to locate data to format
   * @param sign if it is a dollar value
   * @param precision decimal place needs to use
   * @returns  string
   */
  static runnerDataFormatter = (calculatedList, key, sign, precision) => {
    const style = sign === '$' ? 'currency' : 'decimal';
    const formatter = new Intl.NumberFormat('en-AU', {
      style: style,
      currency: 'AUD',
      minimumFractionDigits: 0,
    });

    const rounding = (precision, value) => {
      if (precision === 0) {
        return Math.floor(value);
      } else {
        return round(value, precision);
      }
    };

    if (calculatedList && calculatedList[key]) {
      return `${formatter.format(rounding(precision, calculatedList[key]))}`;
    } else {
      return '-';
    }
  };

  static getRunnerMatchedPercentage = (totalMatched, runnerTotalMatched) => {
    let runnerTotalMatchedPercentage;

    if (totalMatched === 0 || !totalMatched) {
      return '-';
    } else {
      if (runnerTotalMatched > 0) {
        runnerTotalMatchedPercentage = round(
          (runnerTotalMatched / totalMatched) * 100,
          0,
        );
        return `${runnerTotalMatchedPercentage}%`;
      } else {
        return '-';
      }
    }
  };

  static getSumOfLayOrBack = array => {
    let tempSum = 0;
    array.forEach(item => {
      const truncNum = item.size;
      item.size = Math.floor(truncNum);
      tempSum += item.size;
    });

    return tempSum;
  };

  static getRunnerSP = (marketStatus, value) => {

    if (value === 'Infinity') return '-';
    if (!value || value === 'NaN') return 'N/A';
    if (['IN-PLAY', 'SUSPENDED', 'OPEN', 'CLOSED'].indexOf(marketStatus) < 0) return 'N/A';

    if (
      marketStatus === 'IN-PLAY' ||
      marketStatus === 'SUSPENDED' ||
      marketStatus === 'CLOSED' ||
      (marketStatus === 'OPEN' && value < 10))
    {
      return RunnerTableRowUtil.bspRoundDown(value);
    }

    return (value - Math.floor(value) > 0.05)
      ? round(value, 1)
      : RunnerTableRowUtil.getFlooredFixedToOneDecimal(value);
  }

  static convertResultPosition = positionInNumber => {
    switch (positionInNumber) {
      case 1:
        return '1st';
      case 2:
        return '2nd';
      case 3:
        return '3rd';
      default:
        return 99;
    }
  };
}

export default RunnerTableRowUtil;
