import Cookies from 'js-cookie';
import axios from 'axios';
import { isDev } from '../../components/utils/Auth';

import * as actionTypes from './actionTypes';

const accountApiData = {
  token: Cookies.get('ssoid'),
  appKey: process.env.REACT_APP_APP_KEY,
  marketTableApi: process.env.REACT_APP_MARKET_TABLE_API_PREFIX,
};

/** @description send the user details to the store, received data from @tryLogin, will also add the token to the user data in the store
 * @param - user data from betfair account api
 * @returns - auth data sent to reducer
 */
export const setAccountDetails = auth => {
  const authData = {
    ...auth,
    token: accountApiData.token,
  };
  return {
    type: actionTypes.SET_ACCOUNT_DETAILS,
    auth: authData,
  };
};


export const logout = () => {
  return {
    type:actionTypes.LOGOUT
  }
}

/**
 * @description logout the user. if local, remove ssoid cookie, if not, use the logoutUrl
 */
export const tryLogout = () => dispatch => {

   const  headers = {
      Accept: 'application/json',
      'X-Authentication': accountApiData.token,
      'X-Application': accountApiData.appKey 
    }
  if(isDev(process.env.NODE_ENV)){
    Cookies.remove('ssoid');
    dispatch(logout())
    window.location = process.env.REACT_APP_LOGOUT_REDIRECT_URL;
  } else {
    Cookies.remove('ssoid', {domain: process.env.REACT_APP_LOGOUT_DOMAIN, path: '/'}); 
    axios.get(process.env.REACT_APP_LOGOUT_URL, {headers: headers})
      .then( response => window.location = process.env.REACT_APP_LOGOUT_REDIRECT_URL)
      .catch( error => console.log('error occured: ', error))
  }
}

/**
 * @description sets the last time the user clicked on the screen
 */
export const setLastActionTime = () => {
  return {
    type: actionTypes.SET_LAST_ACTION_TIME,
  };
};
