import React from 'react';
import moment from 'moment-timezone';
import { round } from 'lodash';
import { PriorityView, Priorities } from '../../../utils/ViewPriority';
import RunnerTableInfoCell from '../RunnerTableInfoCell';

const RunnerTableScratchedRow = props => {
  const {
    runnerId,
    marketId,
    runnerDesc,
    hasTote,
    hasFixed,
    eventType,
    marketCountry,
    index,
    runnerState,
    closed,
    viewPriority,
  } = props;

  const MARKET_EVENT_TYPE_ID_HORSE = Number(process.env.REACT_APP_EVENT_TYPE_ID_HORSE);

  const removalDate = `${moment(runnerState.removalDate).format(
    'DD/MM/YYYY',
  )} at ${moment(runnerState.removalDate).format('HH:mm A')}`;

  const reductionFactor =
    eventType === MARKET_EVENT_TYPE_ID_HORSE
    ? `${round(runnerState.adjustmentFactor, 1)}%`
    : '';

  return (
    <tr key={`${runnerId}_${marketId}_${runnerDesc.runnerName}`}>

      { closed ? (<td className="no-border"></td>) : null }

      <td className="no-border">
        <RunnerTableInfoCell
          key={`${runnerId}_Info_${runnerDesc.runnerName}`}
          description={runnerDesc}
          eventType={eventType}
          marketCountry={marketCountry}
          index={index}
        />
      </td>
      <PriorityView priority={Priorities.MOBILE_SMALLEST} maximum={Priorities.MOBILE_LARGE} currentPriority={viewPriority}>
        {closed
        ? (
          <td className="no-border">
            <div className="rt-two-rows-scratched">
              <div className="rt-runner-scratched-cell">
                <div className="runner-name rt-text-bold">
                  Scratched
                </div>
                <div className="runner-title">{`${moment(runnerState.removalDate).format('DD/MM/YYYY')}`}</div>
              </div>
            </div>
          </td>
        )
        : (<td colSpan={2} className="no-border">
            <div className="rt-two-rows-scratched">
              <div className="rt-runner-scratched-cell">
                <div className="runner-name rt-text-bold">
                  Scratched {`(RF: ${reductionFactor})`}
                </div>
                <div className="runner-title">{removalDate}</div>
              </div>
            </div>
          </td>
        )}
      </PriorityView>
       <PriorityView priority={Priorities.DESKTOP}  currentPriority={viewPriority}>
        <td className="rt-miniGraph-td no-border">
          <div className="rt-two-rows-scratched">
            <div className="rt-runner-scratched-cell">
              <div className="runner-name rt-text-bold">Scratched {`(RF: ${reductionFactor})`}</div>
              <div className="runner-title">{removalDate}</div>
            </div>
          </div>
        </td>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        {hasTote ? <td />:null}
        {hasFixed ? <td />:null}
      </PriorityView>
    </tr>
  )
}

export default RunnerTableScratchedRow;
