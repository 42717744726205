export const TRY_LOGIN = '[TRY_LOGIN - AUTH] Try Login';
export const SET_ACCOUNT_DETAILS = '[SET_ACCOUNT_DETAILS - AUTH]  user successfully logged in';
export const FETCH_TODAYS_OPEN_MARKETS_START =
  '[FETCH_TODAYS_OPEN_MARKETS_START-AUTH] fetch todays open markets start';
export const SET_FETCHING_NEW_MARKETS = '[SET_FETCHING_NEW_MARKETS] Fetching new markets list';
export const SET_OPEN_MARKETS = '[SET_OPEN_MARKETS - AUTH] Set the open markets';
export const SET_EVENT_FILTER = '[SET_EVENT_FILTER - SIDEBAR] event selected';
export const FETCH_INTERVAL_MARKETS =
  '[FETCH_INTERVAL_MARKETS - SIDEBAR] fetch markets by timed interval';
export const SET_CURRENT_SELECTION = '[SET_CURRENT_SELECTION - SIDEBAR - Event Container] - event/market clicked and set the selection in the store'
export const SET_AUTO_SELECT_NEW_RACE_FLAG = '[AUTO_SELECT_NEW_RACE_FLAG] - setting the automatic race selecting on event list refresh flag'
export const SET_CURRENT_VENUE_RACES = '[SET_CURRENT_VENUE_RACES - SIDEBAR - Event Container] - market clicked and set the current venue races in store.'
export const SET_MARKET_RUNNERS =
  '[SET_MARKET_RUNNERS - SIDEBAR] set market runners for particular market';
export const SET_MARKET_HEADER = '[SET_MARKET_HEADER - TRADE CONTAINER] set market header for current selected market';
// export const SET_MARKET_STATUS =
//   '[SET_MARKET_STATUS - TRADE CONTAINER] set market status for current selected market';
export const SET_INTERVAL_MARKET_RUNNERS =
  '[SET_INTERVAL_MARKET_RUNNERS - RUNNER TABLE] set market runners for particular market';
export const SET_HORSE_RACE_COUNTRIES =
'[SET_HORSE_RACE_COUNTRIES - AUTH] set horse racing countries in the filter';
export const SET_GREYHOUND_COUNTRIES =
'[SET_GREYHOUND_COUNTRIES - AUTH] set greyhound racing countries in the filter';
export const FETCH_MATCHED_PRICE = '[FETCH_MATCHED_PRICE] fetch the matched price for the selected market';
export const SET_INTERVAL_OPEN_MARKETS = '[SET_INTERVAL_OPEN_MARKETS - SIDEBAR] timed interval to retrieve open markets';
export const LOGOUT = '[LOGOUT] logout and redirect';
export const SET_LAST_ACTION_TIME = '[SET_LAST_ACTION_TIME - APP] set time of last click by the user'

export const SET_ERO_MARKET = '[SET_ERO_MARKET - RUNNER TABLE] cache the current ERO market data'


// EVENT FILTERS ACTIONS
export const EVENT_FILTER_UPDATE = '[EVENT_FILTER] - updating filters for event list';

// INPLAY UPDATING ACTIONS
export const FETCH_UPDATED_INPLAY_STATUSES = '[FETCH_UPDATED_INPLAY_STATUSES] - fetching updated inplay status for event list';
