import React from 'react';

const NoRaces = () => (
  <div className='no-races'>
    <div className='no-races-text'>
      <div className='no-races-main-text'>NO RACES FOR THE SELECTED DAY</div>
      <div className='no-races-body-text'>TRY EXPANDING YOUR FILTERS</div>
    </div>
    <div className='no-races-image'>
    </div>
  </div>
  );

export default NoRaces;