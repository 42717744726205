/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RaceItem from './RaceItem';
import GeneralUtils from '../utils/GeneralUtils';
import Responsive from '../utils/Responsive';
import LoadingDots from '../LoadingDots';

class EventList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showOnMobile: true,
      eventListHeight: window.innerHeight / 2,
      zoomLevel: 0,
      numberOfShowedItems: 0,
      zoomForwardDisabled: false,
      zoomBackwardDisabled: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = (event) => {
    const { eventListHeight } = this.state;
    const newListHeight = event.target.innerHeight / 2;

    const heightDiff = Math.abs(eventListHeight - newListHeight);

    //ensures setState doesn't get spammed by height changes
    if (heightDiff > 30) {
      this.setState({ eventListHeight: newListHeight });
    }
  }

  /**
   * @description show on mobile. toggles this.state.showOnMobile to true or false
   */
  showOnMobile = () => {
    const { showOnMobile } = this.state;
    this.setState({ showOnMobile: !showOnMobile });
  };

  adjustZoom = (backward) => {
    const zoomAmount = 80;
    const { zoomLevel, numberOfShowedItems } = this.state;
    const newZoom = zoomLevel + (backward ? zoomAmount : -zoomAmount)

    const itemPercentageOfWidth = 164 / window.innerWidth * 100;
    const maxPercentageWidth = itemPercentageOfWidth * numberOfShowedItems;

    const abs = Math.abs(zoomLevel - 30 /* minus a small buffer */);

    if (newZoom > 0) {
      this.setState({ zoomBackwardDisabled: true });
      return;
    }

    if (abs > maxPercentageWidth && !backward) {
      this.setState({ zoomForwardDisabled: true });
      return;
    }

    this.setState({
      zoomLevel: newZoom,
      zoomForwardDisabled: false,
      zoomBackwardDisabled: false,
    });
  }

  renderList = (list) => {
    const {
      selectRaceCallBack,
      filters,
      currentSelection,
    } = this.props;

    const singleItem = market => (
      <RaceItem
        key={GeneralUtils.getEventInfoKey(
          market.eventId,
          market.marketId,
        )}
        {...market}
        marketId={market.marketId}
        selectRaceCallBack={selectRaceCallBack}
        raceCode={filters.raceCode || market.raceCode}
        currentSelection={currentSelection}
      />
    )

    return list
      .map((market, index) => (
        <React.Fragment key={`market_race_item${market.marketId}`}>
          <div key={market.marketId}>
            {singleItem(market)}
          </div>
        </React.Fragment>
      ));
  }

  WithLoadingSpinner = ({ children }) => {
    const { isFetchingNewMarkets } = this.props;

    if (isFetchingNewMarkets) {
      return (<LoadingDots />);
    }

    return children;
  }

  render() {
    const { currentSelection } = this.props;
    const {
      zoomLevel,
      numberOfShowedItems,
      zoomForwardDisabled,
      zoomBackwardDisabled
    } = this.state;

    const eventList = this.props.getFilteredEventList();
    const eventListViews = this.renderList(eventList);

    if (numberOfShowedItems !== eventList.length) {
      this.setState({ numberOfShowedItems: eventList.length });
    }

    let currentSelectionMarketId = currentSelection.marketId || null;

    return (
      <div className="race-container">
        <this.WithLoadingSpinner>
          {
            eventList.length > 0 && currentSelectionMarketId && (
              <React.Fragment>
                <div className="race-content" style={{ marginLeft: `${zoomLevel}%` }}>
                  {eventListViews}
                </div>
                <Responsive desktop>
                  <div className="race-zoom-shift">
                    <div
                      className={`zoom-item icon-chevron-right ${zoomForwardDisabled ? 'disabled' : ''}`}
                      onClick={() => this.adjustZoom(false)} />
                    <div className={`zoom-item icon-chevron-left ${zoomBackwardDisabled ? 'disabled' : ''}`}
                      onClick={() => this.adjustZoom(true)} />
                  </div>
                </Responsive>
              </React.Fragment>
            )
          }
        </this.WithLoadingSpinner>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  market: state.market.markets,
  filters: state.eventFilters,
  currentSelection: state.market.currentSelection,
  isFetchingNewMarkets: state.market.isFetchingNewMarkets,
});

export default connect(mapStateToProps, null)(EventList);

EventList.propTypes = {
  market: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectRaceCallBack: PropTypes.func.isRequired,
};
