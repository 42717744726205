import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import Responsive from '../../utils/Responsive';
import NewWindow from '../../utils/NewWindow';
import CandlesContainer from './CandlesContainer';
import RunnersControl from './RunnersControl';
import GeneralUtils from '../../utils/GeneralUtils';
import Analytics from '../../utils/Analytics';

const initialStats = {
  open: null,
  close: null,
  high: null,
  low: null,
  volume: null,
};

/**
 * This component is the container for the main chart
 */
class TradeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeSliceWindow: '1800000',
      data: null,
      selectedRunnerId: props.defaultRunner,
      runnerData: null,
      mobileToolTip: initialStats,
      turnInPlayEnabled: false,
      chartWidth: null,
    };

    this.durations = [
      { id: 'a', label: 'ALL', active: true, intervalValue: '1800000' }, // 30 min candles
      {
        id: '30',
        label: '30 Mins',
        active: false,
        intervalValue: '60000',
      }, // 1 min candles
      {
        id: '10',
        label: '10 Mins',
        active: false,
        intervalValue: '30000',
      }, // 30 sec candles
    ];

    this.runnerSelectionCB = this.runnerSelectionCB.bind(this);
    this.tooltipCB = this.tooltipCB.bind(this);
    this.openWindow = this.openWindow.bind(this);

    this.myWindowCB = this.myWindowCB.bind(this);
  }

  componentDidMount() {
    if (this.props.marketId !== 'default') {
      this.calcTimeToJump();
      this.resetChart();
    }
    window.onbeforeunload = () => {
      if (this.childWindow) {
        this.childWindow.window.removeEventListener(
          'resize',
          this.dimensionListener,
        );
        this.childWindow.close();
      }
    };
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    this.calcTimeToJump();
    // handle the case when the event has changed

    if (prevProps.eroMarketData !== this.props.eroMarketData) {
      this.updateRunners();
    }

    if (prevProps.marketId !== this.props.marketId) {
      this.resetChart();
    }

    if (prevProps.defaultRunner !== this.props.defaultRunner) {
      this.setState({ selectedRunnerId: this.props.defaultRunner });
    }
  }

  componentWillUnmount() {
    this.setState({ popout: false });
  }

  updateRunners() {
    const runners = this.getRunners();

    let selectedRunner = runners.find(i => i.id === this.state.selectedRunnerId);

    if (!selectedRunner) {
      this.setState({
        runnerData: runners,
        selectedRunnerId: (runners[0] && runners[0].id) || null,
        turnInPlayEnabled: (runners[0] && runners[0].turnInPlayEnabled) || false,
        selectedRunnerMatchedValue: runners[0] && runners[0].matchedValue,
      });
    } else {
      const selectedRunnerMatchedValue = selectedRunner.matchedValue;

      this.setState({
        runnerData: runners,
        selectedRunnerMatchedValue,
      });
    }
  }

  resetChart() {
    const jumpTime = moment.utc(this.props.jumpTime);

    const diff = jumpTime.diff(moment(), 'm');
    let timeDuration;

    if (diff <= 10) {
      timeDuration = '10';
    } else if (diff <= 30) {
      timeDuration = '30';
    } else {
      timeDuration = 'a';
    }

    this.setState({
      timeSliceWindow: this.durations.find(i => i.id === timeDuration).intervalValue,
      mobileToolTip: initialStats,
    });
  }

  myWindowCB(childWindow) {
    this.childWindow = childWindow;
    this.childWindow.addEventListener(
      'resize',
      this.updateDimensions.bind(this),
    );
  }

  updateDimensions(event) {
    this.setState({
      chartWidth: event.target.innerWidth,
    });
  }

  /**
   * Enable/disable a data duration button based on time to jump
   */
  calcTimeToJump() {
    const jumpTime = moment.utc(this.props.jumpTime);
    const diff = jumpTime.diff(moment(), 'm');

    this.durations.find(item => item.id === '30').active = diff <= 30;
    this.durations.find(item => item.id === '10').active = diff <= 10;
  }

  /**
   * Get list of runners
   */
  getRunners() {
    const { eroMarketData } = this.props;
    if (!eroMarketData.eventTypes || eroMarketData.eventTypes.length === 0) return;

    let data = eroMarketData.eventTypes[0].eventNodes[0].marketNodes[0].runners.map(
      item => {
        const runnerDetails = {
          name: item.description.runnerName,
          id: item.selectionId,
          status: item.state.status,
          matchedValue: item.state.totalMatched,
          turnInPlayEnabled:
            eroMarketData.eventTypes[0].eventNodes[0].marketNodes[0].description.turnInPlayEnabled,
          sortPriority:
            item.state.status === 'REMOVED' ? 99 : item.state.sortPriority,
        };

        return runnerDetails;
      }
    )

    if (data) {
      data.sort((a, b) => a.sortPriority - b.sortPriority);
    }

    return data;
  }

  /**
   * Callback to set runner in state
   */
  runnerSelectionCB(runner) {
    if (runner.id !== this.state.selectedRunnerId) {
      this.setState({
        selectedRunnerId: runner.id,
        selectedRunnerMatchedValue: runner.matchedValue,
        mobileToolTip: initialStats,
      });
    }
  }

  /**
   * Click handler to select the time range displayed in the chart
   */
  selectGraphingDuration(e) {
    const timeValue = e.target.attributes.getNamedItem('durationid').value;

    this.setState({
      timeSliceWindow: timeValue,
      mobileToolTip: initialStats,
    });

    const duration = this.durations.find(i => i.intervalValue === timeValue);
    Analytics.track(`CANDLES_CONTAINER_TIME_WINDOW_${duration && duration.label}`);
  }

  /**
   * opens new window and adds click event to the ga dataLayer object
   */
  openWindow() {
    // add to ga dataLayer object
    const gaObj = {
      label: 'Trade Container',
      value: 'New Window',
    };
    GeneralUtils.addToDataLayer(gaObj);
    Analytics.track(`CANDLES_CONTAINER_OPEN_NEW_WINDOW`);

    // open new window
    this.setState({ popout: true });
  }

  tooltipCB(data) {
    if (data) {
      this.setState({
        mobileToolTip: {
          high: data.high,
          low: data.low,
          open: data.open,
          close: data.close,
          volume: data.volume,
        },
      });
    }
  }

  render() {
    const comp = (
    <div
      className={
        this.props.marketInPlay || this.props.marketStatus === 'SUSPENDED'
          ? 'trade-container trade-container-mask'
          : 'trade-container'
      }>
      <div className="trade-container">
        <div className="graph-header-container">
          <Responsive desktop>
            {this.renderDesktopHeader()}
          </Responsive>
          <Responsive mobile>
            {this.renderMobileHeader()}
          </Responsive>
        </div>

        <CandlesContainer
          jumpTime={this.props.jumpTime}
          jumpZone={this.props.jumpZone}
          marketId={this.props.marketId}
          selectedRunnerId={this.state.selectedRunnerId}
          matchedValue={this.state.selectedRunnerMatchedValue}
          timeSliceWindow={this.state.timeSliceWindow}
          tooltipCallBack={this.tooltipCB}
          turnInPlay={this.state.turnInPlayEnabled}
        />


        <Responsive mobile>
          {this.renderMobileFooter()}
        </Responsive>

      </div>
    </div>
    );

    if (this.state.popout) {
      return (
        <NewWindow
          windowCallBack={this.myWindowCB}
          name="bflive"
          title="Betfair Live Chart"
          center="screen"
          features={{
            width: 768,
            height: 500,
            dependant: true,
            resizable: false,
            location: false,
          }}
          onUnload={() => {
            this.setState({ popout: false });
        }}>
          {comp}
        </NewWindow>
      );
    }

    return comp;
  }


  renderMobileHeader() {
    return (
      <RunnersControl
        runners={this.state.runnerData}
        selectedRunnerId={this.state.selectedRunnerId}
        marketId={this.props.marketId}
        runnerCallback={this.runnerSelectionCB}
        marketStatus={this.props.marketStatus}
      />
    )
  }
  /**
   * Specific mobile layout
   * @return {*}
   */
  renderMobileFooter() {
    return (
      <div className="graph-footer-container">
        {this.renderDurations()}
      </div>
    );
  }

  /**
   * Specific desktop layout
   * @return {*}
   */
  renderDesktopHeader() {
    const { popout } = this.state;
    return (
      <React.Fragment>
      <div className="left-container">
        {this.renderDurations()}
      </div>
      <div className="right-container">
        <div className="runners-control-container shadowed">
          <RunnersControl
            runners={this.state.runnerData}
            selectedRunnerId={this.state.selectedRunnerId}
            marketId={this.props.marketId}
            runnerCallback={this.runnerSelectionCB}
            marketStatus={this.props.marketStatus}
          />
        </div>
        { !popout
            ? (
              <div className="popout-button shadowed spaced" onClick={this.openWindow}>
                Popout Chart <span className="icon-new-window"></span>
              </div>)
            : ''
        }
      </div>
    </React.Fragment>
    );
  }

  renderDurations() {
    const { timeSliceWindow } = this.state;
    return this.durations.map(dur => {
      return (
        <div className={
          "pill " + (
            dur.active
            ? timeSliceWindow === dur.intervalValue
              ? 'selected'
              : ''
            : 'disabled'
        )}>
          <div
            key={dur.intervalKey}
            durationid={dur.intervalValue}
            onClick={e => dur.active ? this.selectGraphingDuration(e) : null}
          >
            {dur.label}
          </div>
        </div>
      )
    });
  }
}

const mapStateToProps = state => ({
  eroMarketData: state.market.eroMarketData,
});

export default connect(mapStateToProps)(TradeContainer);

TradeContainer.defaultProps = {
  marketStatus: null,
  jumpTime: null,
}

TradeContainer.propTypes = {
  jumpTime: PropTypes.string,
  marketId: PropTypes.string.isRequired,
  marketStatus: PropTypes.string,
};
