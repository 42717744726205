import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import GeneralUtils from '../../utils/GeneralUtils';

const options = [
  {
    internalValue: 'Weighted Avg. Price (WAP)',
    label: 'WAP',
  },
  { internalValue: 'traded_price', label: 'Traded Price' },
];

class RunnerTableDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: {...options[0]} };
  }

  handleChange(selectedOption) {
    const { internalValue } = selectedOption;
    const { dropdownOnChange } = this.props;
    this.setState({
      selectedOption,
    });
    dropdownOnChange(internalValue);
    // add to google dataLayer
    const gaObj = { label: 'Mini graph type', value: selectedOption.label };
    GeneralUtils.addToDataLayer(gaObj);
  }


  render() {
    const { selectedOption } = this.state;
    return (
      <Select
        value={selectedOption}
        onChange={(selection) => this.handleChange(selection)}
        options={options}
        classNamePrefix="rt-dropdown"
        isSearchable={false}
      />
    );
  }
}

RunnerTableDropDown.propTypes = {
  dropdownOnChange: PropTypes.func.isRequired,
};

export default RunnerTableDropDown;
