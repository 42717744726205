import { Priorities } from '../../../utils/ViewPriority';
const {
  DESKTOP,
  MOBILE_SMALLEST,
  MOBILE_SMALL,
  MOBILE_MEDIUM,
  MOBILE_LARGE,
} = Priorities;

export const TooltipValues = {
  volumeMin: 'Total money matched on the runner<br>in the previous 60 seconds',
    betsMin: 'Number of bets matched on the runner<br>in the previous 60 seconds',
    wap: 'Weighted Average Price (WAP) is the<br>sum of potential winnings on a runner<br>divided by the total volume<br>matched on that runner',
    matched: 'Total amount traded on runner<br>as a % of the total matched market',
    ltp: 'Last Traded Price',
    wom: 'Weight of Money (WOM) displays the percentage of money,<br>spread over the three best available back and lay prices.<br>Greater than 50% indicates more lay money.<br>Less than 50% indicates more back money ',
    available: 'Total money available across the best<br>8 back and lay prices for the runner',
    impliedToWin: 'Probability of the runner winning the race<br>based off the best available back price',
    tote: 'Best Tote is the highest odds paid of the three main Australian totes',
    fixedCorp: 'Weighted Corporate Bookmaker Fixed Price',
    bspDefault: 'Live projections of the Betfair Starting Price (BSP),<br>based on the BSP bets already placed<br>and the current exchange prices',
}

export const RowPriorities = {
  RUNNER:             MOBILE_SMALLEST,
  BACK:               MOBILE_SMALLEST,
  BACK1:              MOBILE_SMALLEST,
  LAY:                MOBILE_SMALLEST,
  LAY1:               MOBILE_SMALLEST,
  BACK2:              MOBILE_SMALL,
  LAY2:               MOBILE_SMALL,
  BSP:                MOBILE_SMALLEST,
  WOM:                MOBILE_MEDIUM,
  TOTAL_MATCHED:      MOBILE_MEDIUM,
  BACK3:              MOBILE_LARGE,
  LAY3:               MOBILE_LARGE,
  IMPLIED:            MOBILE_LARGE,
  LAST_PRICE_TRADED:  MOBILE_LARGE,
  INFO_CELL:          DESKTOP,
  MINI_GRAPH:         DESKTOP,
  AVAILABLE:          DESKTOP,
  WAP:                DESKTOP,
  TOTE:               DESKTOP,
  FIXED:              DESKTOP,
  BPM:                DESKTOP,
  VPM:                DESKTOP,
}
