export default state => {
  const { currentSelection } = state.market;

  if (!currentSelection) {
    return ({
      jumpZone: "",
      jumpTime: "",
      marketId: "",
      marketStatus: "",
      marketInPlay: "",
    });
  }

  return ({
    jumpZone: currentSelection.timezone,
    jumpTime: currentSelection.marketTime,
    marketId: currentSelection.marketId,
    marketStatus: currentSelection.status,
    marketInPlay: currentSelection.inPlay,
  });
};
