/* eslint-disable no-console */
/**
 * This is a modified version of https://rmariuzzo.github.io/react-new-window/
 * Forked version can be found at https://github.com/rossdack/react-new-window/
 */

/**
 * Component dependencies.
 * @private
 */

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import WindowUtils from './WindowUtils'

/**
 * The NewWindow class object.
 * @public
 */

class NewWindow extends React.PureComponent {
  /**
   * NewWindow default props.
   */
  static defaultProps = {
    url: '',
    name: '',
    title: '',
    features: { width: '600px', height: '640px' },
    onBlock: null,
    onUnload: null,
    windowCallBack: null,
    center: 'parent',
    copyStyles: true,
    children: null,
  };

  /**
   * The NewWindow function constructor.
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.container = document.createElement('div');
    this.window = null;
    this.windowCheckerInterval = null;
    this.released = false;
    this.state = {
      mounted: false
    }
  }

  componentDidMount() {
    this.openChild();
    this.setState({ mounted: true })
  }

  /**
   * Close the opened window (if any) when NewWindow will unmount.
   */
  componentWillUnmount() {
    if (this.window) {
      this.window.close()
    }
  }

  /**
   * Create the new window when NewWindow component mount.
   */
  openChild() {
    const { url, title, name, features, onBlock, center, windowCallBack, copyStyles } = this.props;

    // Prepare position of the new window to be centered against the 'parent' window or 'screen'.
    if (
      typeof center === 'string' &&
      (features.width === undefined || features.height === undefined)
    ) {
      console.warn('width and height window features must be present when a center prop is provided');
    } else if (center === 'parent') {
      features.left =
        window.top.outerWidth / 2 + window.top.screenX - features.width / 2;
      features.top =
        window.top.outerHeight / 2 + window.top.screenY - features.height / 2;
    } else if (center === 'screen') {
      const screenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
      const screenTop =
        window.screenTop !== undefined ? window.screenTop : window.screen.top;

      const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
      const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;

      features.left = width / 2 - features.width / 2 + screenLeft;
      features.top = height / 2 - features.height / 2 + screenTop
    }

    // Open a new window.
    this.window = window.open(url, name, WindowUtils.toWindowFeatures(features));
    if (windowCallBack) {
      windowCallBack(this.window);
    }

    // When a new window use content from a cross-origin there's no way we can attach event
    // to it. Therefore, we need to detect in a interval when the new window was destroyed
    // or was closed.
    this.windowCheckerInterval = setInterval(() => {
      if (!this.window || this.window.closed) {
        this.release()
      }
    }, 50)

    // Check if the new window was succesfully opened.
    if (this.window) {
      this.window.document.title = title
      this.window.document.body.appendChild(this.container)

      // If specified, copy styles from parent window's document.
      if (copyStyles) {
        setTimeout(() => WindowUtils.copyStylesToTarget(document, this.window.document), 0)
      }

      // Release anything bound to this component before the new window unload.
      this.window.addEventListener('beforeunload', () => this.release())

    } else if (typeof onBlock === 'function') {
      // Handle error on opening of new window.
      onBlock.call(null)
    } else {
      console.warn('A new window could not be opened. Maybe it was blocked.')
    }
  }

  /**
   * Release the new window and anything that was bound to it.
   */
  release() {
    // This method can be called once.
    if (this.released) {
      return
    }
    this.released = true

    // Remove checker interval.
    clearInterval(this.windowCheckerInterval);

    // Call any function bound to the `onUnload` prop.
    const { onUnload } = this.props;

    if (typeof onUnload === 'function') {
      onUnload.call(null)
    }
  }

  /**
   * Render the NewWindow component.
   */
  render() {
    const { mounted } = this.state;
    const { children } = this.props;

    if (!mounted) return null;
    return ReactDOM.createPortal(children, this.container)
  }
}

NewWindow.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  features: PropTypes.objectOf({
    width: PropTypes.number,
    height: PropTypes.number,
    dependant: PropTypes.bool,
    resizable: PropTypes.bool,
    location: PropTypes.bool,
  }),
  onUnload: PropTypes.func,
  onBlock: PropTypes.func,
  center: PropTypes.oneOf(['parent', 'screen']),
  copyStyles: PropTypes.bool,
  windowCallBack: PropTypes.func,
};

/**
 * Component export.
 * @private
 */
export default NewWindow
