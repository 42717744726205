import React from "react";

/**
 * @description component to show a fake login form. used if the user is not in AU/NZ
 */
const FakeLogin = () => {
  const redirectPage = () => {
    window.location.assign("https://www.betfair.com.au/exchange/plus/");
  };
  return (
    <div>
      <div className="display-flex flex-align-items-top margin-bottom-10">
        <div className="text-small small-heading">
          <strong>Note:</strong>
          You must be an Australian or New Zealand verified customer to access
          Betfair Live.
        </div>
        <button
          className="flex-1 icon-close-thin text-align-right"
          type="button"
          onClick={() => redirectPage()}
        />
      </div>
    </div>
  );
};

export default FakeLogin;
