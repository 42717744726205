import React from 'react';

export const ModalSection = ({ heading, children }) => (
  <div className="modal-section">
    <div className="modal-section-heading">{ heading }</div>
    <div>
      { children }
    </div>
  </div>
);

export const ModalHeading = ({ children = null }) => (
  <div className="modal-heading">{ children }</div>
);

export class Modal extends React.Component {
  /*
   * we set overflow:hidden on the body tag when the modal is open.
   * This is so the page doesn't scroll when modal is open (noticed on mobiles)
   */
  componentDidUpdate(nextProps) {
    if (this.props.open !== nextProps.open) {
      const isShowing = nextProps.open;

      document.body.style.overflow = isShowing ? '' : 'hidden';
    }
  }

  // in case of a random scenario, make sure body is scrollable
  // if this component unmounts
  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  /**
   * Ensure the body does not fire the backgrounds cancel function
   * @param {*} e 
   */
  handleBodyClick(e) {
    e.stopPropagation();
  }

  render() {
    const {
      open,
      onSubmit,
      children,
      onCancel,
      slideDirection,
      fullscreen,
    } = this.props;

    // Sliding Functionality
    const slideClass = slideDirection ? 'modal-slide-' + slideDirection : '';

    let slideHiddenClass = '';
    if(!open) {
      slideHiddenClass = 'modal-slide-hidden';
    }

    return (
      <div className={`modal-background ${!open ? 'modal-closed' : ''} ${fullscreen ? 'fullscreen' : ''}`} onClick={onCancel}>
        <div className={`modal ${slideClass} ${slideHiddenClass}`} onClick={this.handleBodyClick}>
          <div className="modal-body">
            { children }
          </div>

          {onSubmit ?
            <div className="modal-bottom">
              <div className="modal-button-submit" onClick={onSubmit}>Apply</div>
            </div>
          : ''}
        </div>
      </div>
    );
  }
}
