import React from 'react';

export default () => (
  <React.Fragment>
    <h1>Unsupported Browser</h1>
    <p>
      Sorry, the browser you are using is not supported by Betfair LIVE.
      Please download another browser, such as Google Chrome, to use Betfair LIVE.
    </p>
  </React.Fragment>
)
