import mixpanel from 'mixpanel-browser';

export default class Analytics {

  // setting DEBUG to true will disable the analytics events being sent to mixpanel
  // and instead log those events out to the console
  static DEBUG = false;

  static init() {
    if (Analytics.DEBUG) return console.log('ANALYTICS init');

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }

  static track(event, properties) {
    if (Analytics.DEBUG) return console.log('ANALYTICS track', event, properties);

    if (!event) return;

    if (properties) {
      mixpanel.track(event, properties);
    } else {
      mixpanel.track(event);
    }
  }

  static trackUser(userDetails) {
    mixpanel.people.set({
      //"DAP_TsCs": userDetails.DAP_TsCs,
      //"$country_code": userDetails.countryCode,
      //"$email": userDetails.emailAddress,
      //"$first_name": userDetails.firstName,
      //"integrationLatency": userDetails.integrationLatency,
      //"kycStatus": userDetails.kycStatus,
      //"$last_name": userDetails.lastName,
      "principalId": userDetails.principalId,
      //"ssoLoginStatus": userDetails.ssoLoginStatus,
      //"username": userDetails.username,
    });
    mixpanel.identify(userDetails.principalId);
  }
}
