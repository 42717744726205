import React from 'react';

const LoginForm = () => (
  <div className="login-wrapper">
    <form action={process.env.REACT_APP_LOGIN_URL} method="post" id="loginForm">
      <div className='display-flex flex-align-items-top margin-top-0 margin-bottom-10' />

      <header className='header-0'>
        <div className="flex-3">login</div>
        <div className="flex-1 icon-login text-align-right" />
      </header>
      <div className='form-group'>
        <label htmlFor="username" className='form-element-heading'>Username</label>
        <input type='text' className='input-text' name="username" id="username" />
      </div>
      <div className='form-group'>
        <label htmlFor="password" className='form-element-heading'>Password</label>
        <input type='password' className='input-text' name="password" id="password" />
      </div>

      <input type="hidden" name="redirectMethod" value="GET" />
      <input type="hidden" name="product" value="exchange" /> 
      <input type="hidden" name="url" value={process.env.REACT_APP_PUBLIC_URL} />

      <div className='betfair-flex-container'>
        <button name="login" id="login" type="submit" value="true" className='button-login flex-1 padding-5'>LOG IN</button>
        <div style={{paddingLeft: 5}} />
        <button name="signup" id="signup" type="button" className='button-signup flex-1 padding-5' onClick={() => {  window.location.assign('https://register.betfair.com.au/account/registration'); }}>SIGN UP</button>
        <div className='forgotten-acct'><a href={process.env.REACT_APP_RECOVER_USERNAME_URL} className='text-decoration-none color-black'>Forgotten Account?</a></div>
      </div>
    </form>
  </div>
  );

export default LoginForm;
