import React from 'react';
import PropTypes from 'prop-types';
import { VictoryGroup, VictoryLine, VictoryVoronoiContainer } from 'victory';
import { mapKeys, omit, isEqual } from 'lodash';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// commenting these out to remove compile warnings
// const MINI_GRAPH_API = process.env.REACT_APP_MINIGRAPH_API_URL;
// const MINI_GRAPH_REFRESH_RATE = process.env.REACT_APP_MINI_GRAPH_REFRESH_RATE;

class RunnerTableMiniGraph extends React.Component {
  constructor(props) {
    super(props);
    const { graphType, runnerId, marketStatus, strokeColorUp, strokeColorDown } = props;
    const tradedPriceArray = [];
    const wapArray = [];
    const intervalId = 0;
    const dataForTooltip = '';
    this.state = {
      runnerId,
      graphType,
      tradedPriceArray,
      wapArray,
      dataForTooltip,
      intervalId,
      marketStatus,
      strokeColorUp,
      strokeColorDown,
    };
  }

  componentDidMount() {
    const { graphType } = this.state;
    this.mounted = true;

    if (graphType === 'WAP' || graphType === 'TP') {
      this.formatGraphData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.formatGraphData();

    if (this.state.showModal && this.props.isMobileAndRotated) {
      this.setState({ showModal: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  formatToolTipData = data => {
    const max = _.maxBy(data, 'y');
    const low = _.minBy(data, 'y');

    if (!max && !low) {
      return null;
    }

    const dataForTooltip = `<div><strong>H:</strong> $${max.y} ${
      max.x
    }<br/><strong>L:</strong> $${low.y} ${low.x}<br/></div>`;

    return dataForTooltip;
  };

  formatGraphData = () => {
    let tradedPriceArray = [];
    let wapArray = [];
    const miniGraphData = this.props.miniGraphData;
    // console.log({ miniGraphData });
    if (this.mounted) {
      if (miniGraphData) {
        const tradedPriceMap = { price: 'y', datetime: 'x' };
        const wapMap = { wap: 'y', datetime: 'x' };

        miniGraphData.map(item => {
          let tradedPriceTemp = mapKeys(
            item,
            (value, key) => tradedPriceMap[key],
          );
          let wapTemp = mapKeys(item, (value, key) => wapMap[key]);

          tradedPriceTemp = omit(tradedPriceTemp, 'undefined');

          const tDateNew = moment(tradedPriceTemp.x).format('DD/MM HH:mm');
          const tDateString = tDateNew.toString();
          const tDateArr = tDateString.split(' ');
          tradedPriceTemp.x = `<strong>T:</strong> ${tDateArr[1]}  <strong>D:</strong> ${tDateArr[0]}`;
          tradedPriceArray.push(tradedPriceTemp);

          wapTemp = omit(wapTemp, 'undefined');
          const wDateNew = moment(wapTemp.x).format('DD/MM HH:mm');
          const wDateString = wDateNew.toString();
          const wDateArr = wDateString.split(' ');
          wapTemp.x = `<strong>T:</strong> ${wDateArr[1]} <strong>D: </strong> ${wDateArr[0]}`;
          wapArray.push(wapTemp);

          return 0;
        });
        tradedPriceArray = _.orderBy(tradedPriceArray, ['x'], ['asc']);
        wapArray = _.orderBy(wapArray, ['x'], ['asc']);

        const { graphType } = this.state;

        let dataForTooltip;
        if (graphType === 'TP') {
          dataForTooltip = this.formatToolTipData(tradedPriceArray);
        }

        if (graphType === 'WAP') {
          dataForTooltip = this.formatToolTipData(wapArray);
        }
        this.setState({ tradedPriceArray, wapArray, dataForTooltip });
      }
    }
  };

  render() {
    const {
      tradedPriceArray,
      wapArray,
      graphType,
      dataForTooltip,
      runnerId,
      strokeColorUp,
      strokeColorDown,
    } = this.state;
    const {
      className,
      mobile,
      isMobileAndRotated,
      onClick
    } = this.props;

    const modifiedClassname = `${className || ''} ${mobile ? 'small' : 'large'}`;

    const strokeColor = wapArray.length > 0 && wapArray[0].y > wapArray[wapArray.length - 1].y ? strokeColorUp : strokeColorDown;
    const wapStrokeWidth = mobile ? 20 : 10;
    const disableGraph = mobile && isMobileAndRotated;

    const wapGraph = (
      <VictoryGroup
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            onActivated={(points, props) => {
              if (props.activePoints && props.activePoints.length > 0) {
                const activePoint = props.activePoints[0];
                const toolTipY = activePoint.y;
                const toolTipX = `${moment(activePoint.x).format(
                  'HH:MM',
                )}  ${moment(activePoint.x).format('DD/MM')}`;
                const dataForTooltip = `$${toolTipY} <br/> ${toolTipX}`;
                this.setState({ dataForTooltip });
              }
            }}
          />
        }>
        <VictoryLine
         style={{ data: { stroke: strokeColor, strokeWidth: wapStrokeWidth } }}
         data={wapArray}
        />
      </VictoryGroup>
    );

    if (graphType === 'TP') {
      const strokeColor = tradedPriceArray.length > 0 && tradedPriceArray[0].y > tradedPriceArray[tradedPriceArray.length - 1].y ? strokeColorUp : strokeColorDown;
      return (
        <div className={`rt-mini-graph-container ${modifiedClassname}`}>
          <ReactTooltip id={`TradedPrice ${runnerId}`} className="" html={true}>
            {dataForTooltip}
          </ReactTooltip>

          <a data-for={`TradedPrice ${runnerId}`} data-tip={dataForTooltip}>
            <VictoryGroup
              containerComponent={
                <VictoryVoronoiContainer voronoiDimension="x" />
              }>
              <VictoryLine
                style={{ data: { stroke: strokeColor, strokeWidth: 8 } }}
                data={tradedPriceArray}
              />
            </VictoryGroup>
          </a>
        </div>
      );
    }

    if (graphType === 'WAP') {
      return (
        <div className={`rt-mini-graph-container ${modifiedClassname}`}>
          {!mobile ? ( <React.Fragment>
            <ReactTooltip
              id={`WAP ${runnerId}`}
              className="customeTheme"
              html={true}>
              {dataForTooltip}
            </ReactTooltip>
            <a data-for={`WAP ${runnerId}`} data-tip={dataForTooltip}>
              {wapGraph}
            </a>
      </React.Fragment>) : (
            <div onClick={(e) => {
              if (disableGraph) return;
              e.stopPropagation();
              onClick();
            }}>
              {wapGraph}
            </div>
      )
          }
        </div>
      );
    }
    return <div />;
  }
}

RunnerTableMiniGraph.propTypes = {
  graphType: PropTypes.string.isRequired,
  // runnerId: PropTypes.string.isRequired,
  // marketStatus: PropTypes.string.isRequired,
};

export default RunnerTableMiniGraph;
