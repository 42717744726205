import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Analytics from '../utils/Analytics';

import * as actionCreators from "../../store/actions";
import AusRegionDropdown from "./regiondropdowns/AusRegionDropdown";
import IntlDropdown from "./regiondropdowns/IntlDropdown";
import DaysFilter from './components/DaysFilter';
import FiltersModal from './components/FiltersModal';
import SportsFilter from './components/SportsFilter';
import GeneralUtils from "../utils/GeneralUtils";
import GetSelectionVenueMarkets from "../utils/GetSelectionVenueMarkets";

class EventFilters extends React.Component {

  constructor(props) {
    super(props);

    this.filtersButton = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobileRotate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkMobileRotate);
  }

  state = {
    showRegionList: false,
    modalOpen: false,
    isMobileAndRotated: GeneralUtils.isMobileAndRotated(),
  }

  checkMobileRotate = () => {
    const { isMobileAndRotated } = this.state;
    const currentIsRotatedCheck = GeneralUtils.isMobileAndRotated();

    if (isMobileAndRotated !== currentIsRotatedCheck) {
      this.setState({
        isMobileAndRotated: currentIsRotatedCheck,
        modalOpen: false
      });
    }
  }

  selectDay = day => {
    // add to google dataLayer
    const gaObj = { label: "Select Day", value: day.id };
    GeneralUtils.addToDataLayer(gaObj);
    Analytics.track(`EVENT_FILTER_DAY_${day.id}`);

    this.props.updateFilter({ day });
    this.props.fetchOpenMarkets();
  };

  // select the current race and dispatch to the store. to ensure unique identifier
  // marketId + eventId is used.
  // @input is from race
  selectRace = race => {
    const { setCurrentSelection, markets } = this.props;
    setCurrentSelection(race, GetSelectionVenueMarkets(race, markets));
  };

  /**
   * @description will filter the markets to check the markets and add the marketId to the dataLayer
   */
  filterMarkets = () => {
    const { markets } = this.props;

    const marketArr = markets
      .filter(this.filterOpenOrClosed)
      .shift();

    if (marketArr) {
      const gaObj = {
        label: `Default event after status changed: ${marketArr.status}`,
        value: marketArr.marketId
      };

      GeneralUtils.addToDataLayer(gaObj);
      // this.selectRace(marketArr);
    } else {
      const gaObj = {
        label: "Default event after status changed: ",
        value: "no races returned"
      };
      GeneralUtils.addToDataLayer(gaObj);
    }
  };

  /**
   * @description filter function for the filterMarkets function.
   * @returns {market} returns market depending on the selectedStatus.id
   */
  filterOpenOrClosed = market => {
    const { selectedStatus } = this.props.filter;

    if (selectedStatus.id === "OPEN") {
      return market.status === "OPEN" || market.status === "SUSPENDED";
    }

    return market.status === "CLOSED";
  };

  /**
   * @description select the aus state. if the state isn't in selectedAusStates array. add it, if it is, remove it from the array.
   */
  selectAusState = ausState => {
    this.props.updateFilter({ ausState });
  };

  // close the dropdown if the aus state dropdown is showing
  closeStateDropdown = () => {
    this.setState({ showRegionList: false });
  };

  stateRegionList() {
    const { showRegionList } = this.state;
    const { filter } = this.props;

    // add to google dataLayer
    let gaObj;
    if (filter.country === "AU") {
      gaObj = {
        label: "Track Location – Filter by State",
        value: "Filter by State"
      };

      if (!showRegionList) Analytics.track(`EVENT_FILTER_SHOW_AUS_REGION_LIST`);

      this.setState({ showRegionList: true });
    } else {
      gaObj = {
        label: "Track Location – Filter by Region",
        value: "Filter by Region"
      };

      if (!showRegionList) Analytics.track(`EVENT_FILTER_SHOW_INTL_REGION_LIST`);

      this.setState({ showRegionList: !showRegionList });
    }
    GeneralUtils.addToDataLayer(gaObj);
  }

  selectSport(sport) {
    // add to google dataLayer
    const gaObj = { label: "Select Sport", value: sport.id };
    GeneralUtils.addToDataLayer(gaObj);

    Analytics.track(`EVENT_FILTER_SPORT_${sport.id}`);

    this.setState({ showRegionList: false });
    this.props.updateFilter({ eventType: sport });
    this.props.fetchOpenMarkets();
  }

  /**
   * @description set the region in the state
   * @param {object} region
   */
  selectRegion(region) {
    const { filter } = this.props;

    // add to google dataLayer
    let gaObj;
    if (filter.selectedCountry.id === "AU") {
      gaObj = {
        label: "Track Location>Filter by State>state",
        value: region
      };
    } else {
      gaObj = {
        label: "Track Location>Filter by Region>Region",
        value: region
      };
    }
    GeneralUtils.addToDataLayer(gaObj);

    this.props.updateFilter({ region });
  }


  /**
   * @description sets the location in the state
   * @function
   * @param {object} location
   */
  selectLocation(country) {
    // add to ga dataLayer
    const gaObj = { label: "Track Location", value: country.id };
    GeneralUtils.addToDataLayer(gaObj);

    this.setState({ showRegionList: false });

    Analytics.track(`EVENT_FILTER_LOCATION_${country.id}`);

    this.props.updateFilter({ country, region: [] });
    this.props.fetchOpenMarkets();
  }

  /**
   * @description status button has been clicked and will change the selectedStatus to the status param passed in
   *  also adds to the dataLayer object
   * @param {object} status
   */
  selectStatus(status) {
    const gaObj = { label: "Select Status", value: status.id };
    GeneralUtils.addToDataLayer(gaObj);
    this.setState({ showRegionList: false }, this.filterMarkets);
    Analytics.track(`EVENT_FILTER_${status.id}`);


    this.props.updateFilter({ status });
  }

  render() {
    const { showRegionList, isMobileAndRotated } = this.state;
    const { filter, horseCountries, greyhoundCountries } = this.props;

    let regionDropdown = null;

    if (filter.selectedCountry.id === "AU") {
      regionDropdown = (
        <div className="region-button">
          <AusRegionDropdown
            dropdownClick={() => this.stateRegionList()}
            regionClick={this.selectAusState}
            selectedRegion={filter.ausStates}
            showRegionList={showRegionList}
            ausRegions={filter.allAusStates}
            closeStateDropdown={this.closeStateDropdown}
          />
        </div>
      );
    } else {
      regionDropdown = (
        <div className="region-button">
          <IntlDropdown
            dropdownClick={() => this.stateRegionList()}
            regionClick={region => this.selectRegion(region)}
            horseCountries={horseCountries || []}
            greyhoundCountries={greyhoundCountries || []}
            selectedRegion={filter.region}
            showRegionList={showRegionList}
          />
        </div>
      );
    }

    if (true) {
      return (
        <React.Fragment>
          <div className="event-filter-container">
            <FiltersModal
              open={this.state.modalOpen}
              cancel={() => this.setState({modalOpen: false})}
              targetButton={this.filtersButton}
              filter={filter}
              horseCountries={horseCountries || []}
              greyhoundCountries={greyhoundCountries || []}
              onAusStateChecked={ausState => {
                const { updateFilter } = this.props;
                updateFilter({ ausState })
              }}
              onCountryChecked={country => {
                const { updateFilter } = this.props;
                updateFilter({ region: country })
              }}
              onSubmit={values => {
                const { updateFilter, fetchOpenMarkets, changeTimeout } = this.props;
                const previousCountry = filter.selectedCountry;

                this.setState({ modalOpen: false });

                if (previousCountry.id !== values.country.id) {
                  // we need to fetch new markets if we change the country
                  updateFilter({ country: values.country })
                  fetchOpenMarkets();
                } else {
                  updateFilter(values)
                }

                changeTimeout(values.status);
              }}
            />

            <SportsFilter
              sports={filter.eventTypes}
              selectSport={v => this.selectSport(v)}
            />

          {
            !isMobileAndRotated && (
              <div
                ref={this.filtersButton}
                className="button-filter-modal"
                onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}
              >
                Filters
              </div>
            )
          }
          </div>

          { /* the event list is passed as a child */ }
          { this.props.children }

          <DaysFilter
            eventDays={filter.eventDays}
            currentSelection={filter.selectedDay}
            selectDay={day => this.selectDay(day)}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="events-container">
          <div className="events-header">
            <div className="flex-4">RACE SCHEDULE</div>
          </div>
          <div className="events-content">

            <DaysFilter
              eventDays={filter.eventDays}
              currentSelection={filter.selectedDay}
              selectDay={day => this.selectDay(day)}
            />

            <SportsFilter
              sports={filter.eventTypes}
              selectSport={sport => this.selectSport(sport)}
            />

            <div className="region-button-container">
              {filter.countries.map(loc => (
                <div
                  key={loc.id}
                  className={loc.id === filter.selectedCountry.id
                      ? "button-location--selected race-button-large"
                      : "button-location race-button-large"
                  }
                  onClick={() => this.selectLocation(loc)}
                >
                  {loc.label}
                </div>
              ))}
              {regionDropdown}
            </div>
            <div className="status-button-container">
              {filter.statuses.map(eventState => (
                <div
                  key={eventState.id}
                  className={eventState.id === filter.selectedStatus.id
                      ? "button-event-status--selected race-button-large"
                      : "button-event-status race-button-large"
                  }
                  onClick={() => this.selectStatus(eventState)}
                >
                  {eventState.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        { this.props.children }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.eventFilters,
  horseCountries: state.market.horseCountries,
  greyhoundCountries: state.market.greyhoundCountries,
  currentSelection: state.market.currentSelection,
  markets: state.market.markets
});

const mapDispatchToProps = dispatch => ({
  updateFilter: filter => dispatch(actionCreators.updateFilter(filter)),
  fetchOpenMarkets: () => dispatch(actionCreators.fetchOpenMarkets()),
  setCurrentSelection: (selection, markets) =>
    dispatch(actionCreators.setCurrentSelection(selection, markets))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventFilters);

EventFilters.defaultProps = {
  markets: [],
  horseCountries: null,
  greyhoundCountries: null,
  filter: {},
  setFilter: () => null,
  setCurrentSelection: () => null,
  changeTimeout: () => null,
};

EventFilters.propTypes = {
  markets: PropTypes.arrayOf(PropTypes.object),
  horseCountries: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
  greyhoundCountries: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
  filter: PropTypes.shape({
    country: PropTypes.string,
    endDate: PropTypes.string,
    eventTypes: PropTypes.arrayOf(PropTypes.object),
    region: PropTypes.string,
    selectedDay: PropTypes.object,
    startDate: PropTypes.string,
    status: PropTypes.object,
  }),
  setFilter: PropTypes.func,
  setCurrentSelection: PropTypes.func,
  changeTimeout: PropTypes.func,
};
