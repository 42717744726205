import React from 'react';

const RadioButton = props => {
  const { active, onClick, type } = props;
  let className;

  if (type === 'checkbox') {
    className = `checkbox-button`
  } else {
    className = `radio-button`
  }

  if (active) {
    className += ' active';
  }


  return (
    <div onClick={onClick} className={className} />
  );
}

export default RadioButton;
