import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * @description checks if the env is dev or something else.
 * @param {string} env
 * @returns {boolean}
 */
export const isDev = (env) => env === 'development';

/**
 * @description checks if the country is aus or nz
 * @param {string} country
 * @returns {boolean}
 */
export const isAccountAuNz = country => (country === 'AU') || (country === 'NZ');

/**
 * @description retrieve the login details of the user after the user has been verified
 * @async
 * @param {string} loginUrl
 * @param {object} loginBody
 * @param {object} loginHeader
 */
export const getLogin = (loginUrl, loginBody, loginHeader) => axios
    .post(loginUrl, loginBody, loginHeader)
    .then(response => Cookies.set('ssoid', response.data.token));
