export const countries = [
  { id: "AU", label: "AU", labelLong: "Australia" },
  { id: "NZ", label: "NZ", labelLong: "New Zealand" },
  { id: "INTL", label: "INTL", labelLong: "International" }
];

export const international = [
  {id: "FR", label: "FR"}, // France
  {id: "GB", label: "GB"}, // Great Britain
  {id: "IE", label: "IE"}, // Ireland
  {id: "SG", label: "SG"}, // Singapore
  {id: "US", label: "US"}, // America
  {id: "ZA", label: "ZA"}, // South Africa
]

export const statuses = [
  { id: "OPEN", label: "Upcoming" },
  { id: "CLOSED", label: "Closed" }
];

export const allAusStates = [
  { id: "VIC", label: "VIC" },
  { id: "NSW", label: "NSW" },
  { id: "QLD", label: "QLD" },
  { id: "SA", label: "SA" },
  { id: "TAS", label: "TAS" },
  { id: "ACT", label: "ACT" },
  { id: "NT", label: "NT" },
  { id: "WA", label: "WA" },
  // { id: "NZL", label: "NZ" },
];
