import React from 'react';
import RunnerTableMiniGraph from '../RunnerTableMiniGraph';
import RunnerTableInfoCell from '../RunnerTableInfoCell';
import FigureCell from '../RunnerTableFigureCell';
import Responsive from '../../../utils/Responsive';
import { RowPriorities } from '../runnerUtils/RunnerTableConstants';
import { PriorityView, Priorities } from '../../../utils/ViewPriority';

const minigraphColorTPUp = process.env.REACT_APP_MINIGRAPH_TP_COLOR_UP;
const minigraphColorTPDown = process.env.REACT_APP_MINIGRAPH_TP_COLOR_DOWN;
const minigraphColorWAPUp = process.env.REACT_APP_MINIGRAPH_WAP_COLOR_UP;
const minigraphColorWAPDown = process.env.REACT_APP_MINIGRAPH_WAP_COLOR_DOWN;

/**
 * Simple component to wrap the cells in. This was created to avoid rerendering with a
 * functional component
 */
class RunnerTableFigureCell extends React.Component {
  render() {
    return (
      <PriorityView
        priority={this.props.priority || Priorities.DESKTOP}
        currentPriority={this.props.currentPriority}
      >
        <FigureCell {...this.props} />
      </PriorityView>
    )
  }
}

const RunnerTableSingleRow = props => {

  const {
    closed,
    runnerId,
    marketId,
    runnerDesc,
    eventType,
    marketCountry,
    index,
    shouldHide,
    miniGraphData,
    availableToBack,
    availableToLay,
    runnerSP,
    wap,
    hasTote,
    hasFixed,
    bpm,
    vpm,
    impliedChanceToWin,
    runnerTotalMatchedPercentage,
    runnerTotalMatched,
    toteValue,
    fixedValue,
    lastPriceTraded,
    viewPriority,
    isMobileAndRotated,
    onGraphClick,
  } = props;


  // These props are only for OPEN races
  const {
    womColour,
    wom,
    available,
  } = props;

  // These props are only for CLOSED races
  const {
    placeBackgroundClass,
    resultPosition,
  } = props;

  const marketClosed = closed === true;

  const POSITION = (
    <React.Fragment>
      <Responsive desktop>
        {
          marketClosed ? (
            <td className="runnerPosition no-border">
              <div className={placeBackgroundClass}>
                <div className="winningPosition">{resultPosition}</div>
              </div>
            </td>
          ) : null
        }
      </Responsive>
      <Responsive mobile>
        {(marketClosed) ? (
          <td className="runnerPosition no-border">
            <div className={placeBackgroundClass}>
              <div className="winningPosition">{resultPosition}</div>
            </div>
          </td>
        ) : null}
      </Responsive>
    </React.Fragment>
  );

  const INFO_CELL = (
    <td className="runner-info-cell-container no-border">
      <RunnerTableInfoCell
        key={`${runnerId}_Info_${runnerDesc.runnerName}`}
        description={runnerDesc}
        eventType={eventType}
        marketCountry={marketCountry}
        index={index}
      >
        <PriorityView priority={Priorities.MOBILE_SMALLEST} maximum={Priorities.MOBILE_LARGE} currentPriority={viewPriority}>
          <RunnerTableMiniGraph mobile
            className={shouldHide ? 'hidden' : null}
            runnerId={runnerId}
            graphType="WAP"
            miniGraphData={miniGraphData}
            strokeColorUp={minigraphColorWAPUp}
            strokeColorDown={minigraphColorWAPDown}
            isMobileAndRotated={isMobileAndRotated}
            onClick={() => onGraphClick(runnerId)}
          />
        </PriorityView>
      </RunnerTableInfoCell>
    </td>
  );

  const MINI_GRAPH = (
    <PriorityView priority={RowPriorities.MINI_GRAPH} currentPriority={viewPriority}>
      <td className="rt-miniGraph-td no-border">
        <RunnerTableMiniGraph
          className={shouldHide ? 'hidden' : null}
          runnerId={runnerId}
          graphType="WAP"
          miniGraphData={miniGraphData}
          strokeColorUp={minigraphColorWAPUp}
          strokeColorDown={minigraphColorWAPDown}
        />
        <RunnerTableMiniGraph
          className={shouldHide ? null : 'hidden'}
          runnerId={runnerId}
          graphType="TP"
          miniGraphData={miniGraphData}
          strokeColorUp={minigraphColorTPUp}
          strokeColorDown={minigraphColorTPDown}
        />
      </td>
    </PriorityView>
  );

  const WOM = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      className={marketClosed ? "rt-blue rt-text-bold" : womColour}
      firstRow={wom ? `${wom}%` : '-'}
      priority={RowPriorities.WOM}
    />
  );

  const BACK3 = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={
        availableToBack && availableToBack.length > 2
          ? availableToBack[2].price
          : '-'
      }
      secondRow={
        availableToBack && availableToBack.length > 2
          ? `$${availableToBack[2].size}`
          : '-'
      }
      marketId={!marketClosed ? marketId : undefined}
      priority={RowPriorities.BACK3}
    />
  );

  const BACK2 = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={
        availableToBack && availableToBack.length > 1
          ? availableToBack[1].price
          : '-'
      }
      secondRow={
        availableToBack && availableToBack.length > 1
          ? `$${availableToBack[1].size}`
          : '-'
      }
      marketId={!marketClosed ? marketId : undefined}
      priority={RowPriorities.BACK2}
    />
  );

  // const BLANK_SPACE = (
  //   <Responsive mobile>{marketClosed? <td>{/*Empty row for scratched on mobile*/}</td> : null}</Responsive>
  // );

  const BACK1 = (
    viewPriority === Priorities.MOBILE_SMALLEST && marketClosed ? null : (
      <RunnerTableFigureCell
        currentPriority={viewPriority}
        className="rt-blue"
        firstRow={
          availableToBack && availableToBack.length > 0
            ? availableToBack[0].price
            : '-'
        }
        secondRow={
          availableToBack && availableToBack.length > 0
            ? `$${availableToBack[0].size}`
            : '-'
        }
        marketId={!marketClosed ? marketId : undefined}
        priority={RowPriorities.BACK1}
      />
    )
  );

  const BSP = (
    !marketClosed && viewPriority === Priorities.MOBILE_SMALLEST ? null : (
      <RunnerTableFigureCell
        currentPriority={viewPriority}
        firstRow={runnerSP}
        marketId={!marketClosed ? marketId : undefined}
        priority={RowPriorities.BSP}
      />
    )
  );

  const LAY1 = (
    viewPriority === Priorities.MOBILE_SMALLEST && marketClosed ? null : (
      <RunnerTableFigureCell
        currentPriority={viewPriority}
        className="rt-pink"
        firstRow={
          availableToLay && availableToLay.length > 0
            ? availableToLay[0].price
            : '-'
        }
        secondRow={
          availableToLay && availableToLay.length > 0
            ? `$${availableToLay[0].size}`
            : '-'
        }
        marketId={!marketClosed ? marketId : undefined}
        priority={RowPriorities.LAY1}
      />
    )
  );

  const LAY2 = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={
        availableToLay && availableToLay.length > 1
          ? availableToLay[1].price
          : '-'
      }
      secondRow={
        availableToLay && availableToLay.length > 1
          ? `$${availableToLay[1].size}`
          : '-'
      }
      marketId={!marketClosed ? marketId : undefined}
      priority={RowPriorities.LAY2}
    />
  );

  const LAY3 = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={
        availableToLay && availableToLay.length > 2
          ? availableToLay[2].price
          : '-'
      }
      secondRow={
        availableToLay && availableToLay.length > 2
          ? `$${availableToLay[2].size}`
          : '-'
      }
      marketId={!marketClosed ? marketId : undefined}
      priority={RowPriorities.LAY3}
    />
  );

  const IMPLIED = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={impliedChanceToWin}
      priority={RowPriorities.IMPLIED}
    />
  );

  const TOTAL_MATCHED = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={`${runnerTotalMatched}`}
      secondRow={runnerTotalMatchedPercentage}
      priority={RowPriorities.TOTAL_MATCHED}
    />
  );

  const AVAILABLE = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={available || '-'}
      priority={RowPriorities.AVAILABLE}
    />
  );

  const LAST_PRICE_TRADED = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={lastPriceTraded}
      priority={RowPriorities.LAST_PRICE_TRADED}
    />
  );

  const WAP = (
    <RunnerTableFigureCell
      currentPriority={viewPriority}
      firstRow={wap}
      priority={RowPriorities.WAP}
    />
  );

  const TOTE = (
    hasTote ? (<RunnerTableFigureCell
      currentPriority={viewPriority} firstRow={toteValue} priority={RowPriorities.TOTE} />) : null
  );

  const FIXED = (
    hasFixed ? (<RunnerTableFigureCell
      currentPriority={viewPriority} firstRow={fixedValue} priority={RowPriorities.FIXED} />) : null
  );

  const BPM = (
    <RunnerTableFigureCell
      currentPriority={viewPriority} firstRow={bpm} priority={RowPriorities.BPM} />
  );

  const VPM = (
    <RunnerTableFigureCell
      currentPriority={viewPriority} firstRow={vpm} priority={RowPriorities.VPM} />
  );

  return (
    <tr
      className="rt-runner-row"
      key={`${runnerId}_${marketId}_${runnerDesc.runnerName}`}>

      {POSITION}
      {INFO_CELL}
      {MINI_GRAPH}
      {WOM}
      {BACK3}
      {BACK2}
      { /* BLANK_SPACE */}
      {BACK1}
      {BSP}
      {LAY1}
      {LAY2}
      {LAY3}
      {IMPLIED}
      {TOTAL_MATCHED}
      {AVAILABLE}
      {LAST_PRICE_TRADED}
      {WAP}
      {TOTE}
      {FIXED}
      {BPM}
      {VPM}
    </tr>
  );
}

export default RunnerTableSingleRow;
