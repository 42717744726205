import { now } from 'moment';
import { updateObject } from '../utility';
import * as actionTypes from '../actions/actionTypes';


const initialState = {
  firstName: null,
  lastName: null,
  countryCode: 'AU',
  lastActionTime: now()
}

export const setAccountDetails = (state, action) => {
  return updateObject(state, {
    ...state,
    ...action.auth
  });
};

const logout = (state, action) => {
  return updateObject(state, {
    ...state,
    auth:null
  });
}

export const setLastActionTime = (state, action) => {
  return updateObject(state, {
    ...state,
    lastActionTime: now()
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRY_LOGIN:
      return updateObject(state, action);
    case actionTypes.SET_ACCOUNT_DETAILS:
      return setAccountDetails(state, action);
    case actionTypes.LOGOUT:
      return logout(state, action);
    case actionTypes.SET_LAST_ACTION_TIME:
      return setLastActionTime(state, action);
    default:
      return state;
  }
};

export default reducer;
