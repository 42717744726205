/* eslint-disable no-console */
class WindowUtils {

  /**
   * Copy styles from a source document to a target.
   * @param {Object} source
   * @param {Object} target
   * @private
   */
  static copyStylesToTarget(source, target) {
    Array.from(source.styleSheets).forEach(styleSheet => {
      // For <style> elements
      let rules
      try {
        rules = styleSheet.cssRules
      } catch (err) {
        console.error(err)
      }
      if (rules) {
        const newStyleEl = source.createElement('style')

        // Write the text of each rule into the body of the style element
        Array.from(styleSheet.cssRules).forEach(cssRule => {
          const { cssText, type } = cssRule;
          let returnText = cssText;
          // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5) to handle local imports on a about:blank page
          // '/custom.css' turns to 'http://my-site.com/custom.css'
          if ([3, 5].includes(type)) {
            returnText = cssText
              .split('url(')
              .map(line => {
                if (line[1] === '/') {
                  return `${line.slice(0, 1)}${
                    window.location.origin
                    }${line.slice(1)}`
                }
                return line
              })
              .join('url(')
          }
          newStyleEl.appendChild(source.createTextNode(returnText))
        })

        target.head.appendChild(newStyleEl)
      } else if (styleSheet.href) {
        // for <link> elements loading CSS from a URL
        const newLinkEl = source.createElement('link')

        newLinkEl.rel = 'stylesheet';
        newLinkEl.href = styleSheet.href
        target.head.appendChild(newLinkEl)
      }
    })
  }

  /**
   * Convert features props to window features format (name=value,other=value).
   * @param {Object} obj
   * @return {String}
   * @private
   */

  static toWindowFeatures(obj) {
    return Object.keys(obj)
      .reduce((features, name) => {
        const value = obj[name];
        if (typeof value === 'boolean') {
          features.push(`${name}=${value ? 'yes' : 'no'}`)
        } else {
          features.push(`${name}=${value}`)
        }
        return features
      }, [])
      .join(',')
  }
}

export default WindowUtils;