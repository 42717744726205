import React from 'react';
import RunnerSortIcon from '../runnerUtils/RunnerSortIcon';

import { Priorities } from '../../../utils/ViewPriority';
import Responsive from '../../../utils/Responsive';

const RunnerTableBackHeader = props => {
  const {
    marketStatus,
    sortType,
    sortDesc,
    backOddsSum,
    toggleSorting,
    isMarketClosed,
    viewPriority
  } = props;

  let colSpan;

  if (viewPriority < Priorities.MOBILE_SMALL) {
    colSpan = "1"
  } else if (viewPriority <= Priorities.MOBILE_MEDIUM) {
    colSpan = "2";
  } else {
    colSpan = "3";
  }

  return (
    <React.Fragment>
      <Responsive desktop>
        <th colSpan={colSpan} className="back-header clickable" onClick={() => toggleSorting('back')}>
          <span className="rt-header-title">
            Back &nbsp;&nbsp;&nbsp;
            <strong>{backOddsSum}</strong>
            <RunnerSortIcon
              display={marketStatus !== 'CLOSED'}
              isSelected={sortType === 'back'}
              desc={sortType === 'back' ? !sortDesc : sortDesc}
              style={{marginLeft: 8}}
            />
          </span>
        </th>
      </Responsive>

      <Responsive mobile>
        {
          (isMarketClosed && viewPriority === Priorities.MOBILE_SMALLEST) ? null :
            <th colSpan={colSpan} className="back-header" onClick={() => toggleSorting('back')}>
              <div>Back</div>
              <div>{backOddsSum}</div>
            </th>
        }
      </Responsive>
    </React.Fragment>
  )
}

export default RunnerTableBackHeader;
