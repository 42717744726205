import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import Constants from '../../Constants';
import GeneralUtils from '../../utils/GeneralUtils';
import Analytics from '../../utils/Analytics';

class RunnersControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRunnerList: false,
    };

    this.selectRunner = this.selectRunner.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.marketId !== this.props.marketId) {
      this.setState({ showRunnerList: false });
    }
  }

  selectRunner(runner) {
    const { selectedRunnerId } = this.props;

    // add runner name to ga dataLayer object
    const gaObj = {
      label: 'Trade Container',
      value: runner.name
    };
    GeneralUtils.addToDataLayer(gaObj);

    if (selectedRunnerId !== runner.id && runner.status !== 'REMOVED') {
      this.setState({ showRunnerList: false });

      // perform callback
      this.props.runnerCallback({id: runner.id, matchedValue: runner.matchedValue});
    }
  }

  toggleRunnersList(visible) {
    // add to ga dataLayer object
    const gaObj = {
      label: 'Trade Container',
      value: 'Market Runners Dropdown'
    };
    GeneralUtils.addToDataLayer(gaObj);
    Analytics.track('CANDLES_CONTAINER_SHOW_RUNNER_LIST');

    this.setState({ showRunnerList: !visible });
  }

  getSelectedRunner = () => this.props.runners.find(item => item.id === this.props.selectedRunnerId);


  /**
   * Get the previous runner from the array. Used in the left button on mobile.
   *
   * @memberof RunnersControl
   */
  getPreviousRunner = () => {
    const { selectedRunnerId, runners } = this.props;
    // Get current runner index
    const currentIndex = runners.findIndex(item => item.id === selectedRunnerId);

    let newIndex = currentIndex - 1;
    if(newIndex < 0) {
      newIndex = runners.length - 1;
    }

    return runners[newIndex];
  }

  /**
   * Get the next runner from the array. Used in the right button on mobile.
   *
   * @memberof RunnersControl
   */
  getNextRunner = () => {
    const { selectedRunnerId, runners } = this.props;
    // Get current runner index
    const currentIndex = runners.findIndex(item => item.id === selectedRunnerId);

    let newIndex = currentIndex + 1;
    if(newIndex >= runners.length) {
      newIndex = 0;
    }

    return runners[newIndex];
  }

  renderItems() {
    if (this.state.showRunnerList) {
      return Object.keys(this.props.runners).map(key => {
        return (
          <div
            className={
              this.props.runners[key].status !== 'REMOVED' ?
                this.props.runners[key].id === this.props.selectedRunnerId
                  ? 'row-item row-item-selected'
                  : 'row-item'
                :
                'row-item row-item-scratched'
            }
            key={this.props.runners[key].id}
            onClick={() => this.selectRunner(this.props.runners[key])}
          >
            {this.props.runners[key].name}
          </div>
        );
      });
    }
    return null;
  }

  renderMobile() {
    return (
      <React.Fragment>
        <span className="mobile-runner-select-left icon-chevron-left" onClick={() => this.selectRunner(this.getPreviousRunner())} />
        <span className="mobile-runner-select-name">{this.getSelectedRunner().name || '-'}</span>
        <span className="mobile-runner-select-right icon-chevron-right" onClick={() => this.selectRunner(this.getNextRunner())} />
      </React.Fragment>
    );
  }

  renderDesktop() {
    return (
      <div className={'runners-container'}>
        <div
          className={'runners-header'}
          onClick={() => this.toggleRunnersList(this.state.showRunnerList)}
        >
          <div className={'header-text'}>
            {this.props.selectedRunnerId ? this.getSelectedRunner().name : 'Select Runner'}
          </div>
          <div
            className={
              this.state.showRunnerList
                ? 'header-icon'
                : 'header-icon--expand'
            }
          />
        </div>
        {this.state.showRunnerList && (
          <div className={'item-container'}>{this.renderItems()}</div>
        )}
      </div>
    );
  }

  render() {
    // we need to wait for both runners and selected runner to sync,
    // otherwise we try to get a specific runner from an old list
    if (this.props.runners &&
      this.props.selectedRunnerId &&
      this.props.runners.find(item => item.id === this.props.selectedRunnerId)) {
      return (
        <React.Fragment>
          <MediaQuery maxWidth={Constants.responsiveMobileWidthBreakpoint}>
            { this.renderMobile() }
          </MediaQuery>

          <MediaQuery minWidth={Constants.responsiveDesktopWidthBreakpoint}>
            { this.renderDesktop() }
          </MediaQuery>
      </React.Fragment>
      );
    }
    return <div className="header-text">-</div>;
  }
}

RunnersControl.propTypes = {
  runnerCallback: PropTypes.func.isRequired,
  runners: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RunnersControl;


