import React from 'react';
import MediaQuery from 'react-responsive';
import Constants from '../Constants';

const Responsive = props => {
  const { mobile, desktop, children } = props;

  let breakpointMax = undefined;
  let breakpointMin = undefined;

  if (mobile) {
    breakpointMax = Constants.responsiveMobileWidthBreakpoint;
  }
  else if (desktop) {
    breakpointMin = Constants.responsiveDesktopWidthBreakpoint;
  }
  else {
    return children;
  }

  return (
    <MediaQuery minWidth={breakpointMin} maxWidth={breakpointMax}>
      { children || null }
    </MediaQuery>
  )
}

export default Responsive;
