import React from 'react';
import EventIcon from '../../utils/EventIcon';

const SportFilters = props => {
  const { sports, selectSport } = props;

  return (
      <div className="sport-filter-container">
        {
          sports.map(sport => (
            <div
              key={`sport_filter_${sport.id}`}
              className={"button-sport" + (sport.active ? ' selected' : '')}
              onClick={() => selectSport(sport)}
            >
              <EventIcon eventTypeId={sport.id} />
            </div>
          ))
        }
      </div>
  );
}

export default SportFilters;
