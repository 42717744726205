import React from 'react';
import Select, { components } from 'react-select';

const SingleValue = ({ children, ...props }) => {
  const { labelShort } = props.data;

  return (
    <components.SingleValue {...props}>{ labelShort }</components.SingleValue>
  );
};

const RunnerSelector = props => {
  const { currentVenueRaces, raceNumber, marketId, setCurrentSelection } = props;

  const venueRaces = currentVenueRaces.map(v => ({
    value: v,
    labelShort: 'R' + v.raceNumber,
    label: `Race ${v.raceNumber}`,
    status: v.status,
  }))

  return (
    <Select
      value={venueRaces.find(v => v.value.marketId === marketId)}
      placeholder={'R'+raceNumber}
      options={venueRaces}
      autoFocus={false}
      onChange={(val) => {
        if(val.value.marketId !== marketId) {
          let autoSelectNewRaceFlag;

          // if the user has selected a closed race, we do not want to auto select
          // an open race when we fetch a new list of markets
          autoSelectNewRaceFlag = val.status !== 'CLOSED';

          setCurrentSelection(val.value, null, autoSelectNewRaceFlag);
        }
      }}
      isSearchable={false}
      className="runner-dropdown"
      classNamePrefix="rt"
      components={{ SingleValue }}
    />
  );
}

export default RunnerSelector;
