import React from 'react';
import PropTypes from 'prop-types';

const ErrorHandler = ({ errorText }) => (
  <div className='error-handler'>{errorText}</div>
)

ErrorHandler.defaultProps = {
  errorText: "An error has occurred. Please refresh this page.",
}

ErrorHandler.propTypes = {
  errorText: PropTypes.string,
}

export default ErrorHandler;