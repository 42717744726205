import React from 'react';
import { isEqual, includes } from 'lodash';
import ReactTooltip from 'react-tooltip';

import InfoCellSilkImage from './components/InfoCellSilkImage';

const MARKET_EVENT_TYPE_ID_HORSE = Number(
  process.env.REACT_APP_EVENT_TYPE_ID_HORSE,
);
const MARKET_EVENT_TYPE_ID_GREYHOUND = Number(
  process.env.REACT_APP_EVENT_TYPE_ID_GREYHOUND,
);

const HORSE_COUNTRY_EXCEPTION_ARRAY =
  process.env.REACT_APP_HORSE_COUNTRY_EXCEPTION_ARRAY;

class RunnerTableInfoCell extends React.Component {
  constructor(props) {
    super(props);
    const { description, eventType, marketCountry, marketStatus } = props;
    const dataForTooltip = '';
    this.state = {
      description,
      eventType,
      marketCountry,
      dataForTooltip,
      marketStatus,
    };
  }

  componentDidMount() {
    this.generateTooltip();
  }

  componentDidUpdate() {
    if (this.state.description) {
      const { description, marketStatus } = this.props;

      this.setState({ description, marketStatus });
      this.generateTooltip();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state) || !isEqual(nextProps, this.props);
  }

  generateTooltip = () => {
    const { description } = this.state;
    const { metadata } = description;
    const {
      TRAINER_NAME,
      AGE,
      WEIGHT_VALUE,
      WEIGHT_UNITS,
      FORM,
      DAYS_SINCE_LAST_RUN,
      JOCKEY_CLAIM,
      WEARING,
    } = metadata;
    const dataForTooltip = `<div><b>Trainer: </b>${this.checkNullValue(
      TRAINER_NAME,
    )}</div>
   <div><b>Age: </b><span>${this.checkNullValue(AGE)}/${this.checkNullValue(
      WEIGHT_VALUE,
    )}${this.checkNullValue(WEIGHT_UNITS)}</span></div>
   <div><b>Form: </b><span>${this.checkNullValue(FORM)}</span></div>
   <div><b>Days since last run: </b><span>${this.checkNullValue(
     DAYS_SINCE_LAST_RUN,
   )}</span></div>
   <div><b>Jockey claim: </b><span>${this.checkNullValue(
     JOCKEY_CLAIM,
   )}</span></div>
   <div><b>Wearing: </b><span>${this.checkNullValue(WEARING)}</span></div>`;

    this.setState({ dataForTooltip });
  };
  checkNullValue = value => {
    const tempValue = value;
    if (tempValue && tempValue !== null) {
      return tempValue;
    }
    return 'N/A';
  };

  formatRunnerNames = (eventType, marketCountry, description) => {
    const { metadata } = description;
    let runnerName = description.runnerName;
    let clotherNumber = '';
    if (eventType === MARKET_EVENT_TYPE_ID_HORSE) {
      if (includes(HORSE_COUNTRY_EXCEPTION_ARRAY, marketCountry)) {
        clotherNumber = metadata.CLOTH_NUMBER_ALPHA
          ? metadata.CLOTH_NUMBER_ALPHA || metadata.CLOTH_NUMBER
          : '';
        runnerName = `${clotherNumber}. ${runnerName}`;
        return runnerName;
      } else {
        return runnerName;
      }
    }
    if (eventType === MARKET_EVENT_TYPE_ID_GREYHOUND) {
      return runnerName;
    }
  };

  render() {
    const {
      description,
      dataForTooltip,
      eventType,
      marketCountry,
    } = this.state;
    const { children } = this.props;

    if (!description) {
      return '...';
    }
    const { metadata } = description;
    const runnerName = this.formatRunnerNames(
      eventType,
      marketCountry,
      description,
    );

    let { STALL_DRAW, JOCKEY_NAME } = metadata;
    STALL_DRAW =
      STALL_DRAW === undefined || STALL_DRAW === null ? '' : `(${STALL_DRAW})`;
    JOCKEY_NAME = JOCKEY_NAME === undefined ? '' : JOCKEY_NAME;

    const index = runnerName
      ? runnerName.substr(0, runnerName.indexOf('.'))
      : '';

    const silkImg = metadata.COLOURS_FILENAME;

    const isHorse = eventType === MARKET_EVENT_TYPE_ID_HORSE;
    const clothNumber = metadata.CLOTH_NUMBER || index;

    return (
    <React.Fragment>
      <div className="runner-cell-container">
        <ReactTooltip
          id={`${runnerName}_${STALL_DRAW}`}
          className="rt-toolTip-grey"
          html={true}>
          {dataForTooltip}
        </ReactTooltip>

        <a className="runner-cell" data-for={isHorse ? `${runnerName}_${STALL_DRAW}` : null} data-tip={isHorse? dataForTooltip : null}>
          <div className="flex-middle stall">{ clothNumber }</div>
          <div className="flex-middle silk">
            <InfoCellSilkImage
              eventType={eventType}
              marketCountry={marketCountry}
              silkImg={silkImg}
              index={index}
            />
          </div>
          <div className="flex-middle runner">
            <div>
              <div className="runner-name">
                {this.formatRunnerName(runnerName)} {STALL_DRAW}
              </div>
              <div className="runner-jockey">
                {this.formatJockeyName(JOCKEY_NAME)}
              </div>
            </div>
          </div>
        </a>
        <div className="rt-miniGraph-td">
          {children ? children : null}
        </div>
      </div>
    </React.Fragment>
    )
  }

  formatJockeyName = (name) => {
    if(!name) {
      return '';
    }
    const names = name.split(' ');

    return names[0].substr(0,1).toUpperCase() + ' ' + names.slice(1).join(' ')
  }

  formatRunnerName = (name) => {
    if(!name) {
      return '';
    }

    return name.substr(name.indexOf(' ') + 1);
  }
}

export default RunnerTableInfoCell;
