import { getEroRequest } from '../../request';

/**
 * @description Fetch ERO api, this is the regular call the table uses,
 * filters do not contain runner metadata to decrease data flow
 * @param marketId
 */
export const FetchMarketDataERO = (marketId, setCurrentSelectionMarketInfo) => {
  const request = getEroRequest(marketId, undefined);

  return request
    .then(res => {
      const marketInfo =
        res.data.eventTypes[0].eventNodes[0].marketNodes[0].state;
      const marketStatus = marketInfo.status;
      const marketInPlay = marketInfo.inplay;

      // There is nothing fancy about the axios call, but we had to cope with Betfair Exchange API
      // The things need to be aware of
      // 1. Table gets total Matched amount from init data call, we only call the calculation and graph data apis
      // when it's larger than 0
      // 2. When market is pre jump, the start price is nearPrice, while after jump it becomes actual starting price
      // This affects the tooltip for SP as well
      // 3. As the table is maintaining it's own state, but the market header (market container) needs to be synced
      // This function updates the currentSelection in the redux store, so markert container gets updated as well.
      // 4. When market status is suspended or in play, the whole grid becomes greyed out (0.5 opacity) and the pointer event
      // is disabled.
      // 5. When market is closed, all returned runner information is reversed, for example, the scratched runners come in
      // the end of the array and have lowest sort priority, but when it's closed, they come in first and have highest priorty
      // But betfair live needs us to always show scratched runners in the end.
      let marketHeader;
      if (marketStatus === 'OPEN' && marketInPlay === false) {
        marketHeader = {
          totalMatched: marketInfo.totalMatched,
          eventInPlay: false,
          marketStatus: 'OPEN',
          marketId,
        };
      } else if (marketStatus === 'OPEN' && marketInPlay === true) {
        marketHeader = {
          totalMatched: marketInfo.totalMatched,
          eventInPlay: true,
          marketStatus: 'IN-PLAY',
          marketId,
        };
      } else if (marketStatus === 'SUSPENDED') {
        marketHeader = {
          totalMatched: marketInfo.totalMatched,
          eventInPlay: false,
          marketStatus: 'SUSPENDED',
          marketId,
        };
      } else if (marketStatus === 'CLOSED') {
        marketHeader = {
          totalMatched: 0,
          eventInPlay: false,
          marketStatus: 'CLOSED',
          marketId,
        };
      }

      setCurrentSelectionMarketInfo(marketHeader);

      return res;
    });
};

export const fetchInPlayAndStatusValues = marketIds => {

  if (!Array.isArray(marketIds) || marketIds.length === 0) {
    return Promise.reject();
  }

  const marketIdsString = marketIds.join(',');
  const request = getEroRequest(marketIdsString, 'MARKET_STATE');
  return request;
}
